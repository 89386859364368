<!-- @format -->

<template>
  <div class="about">
    <div class="client-container">
      <div
        class="cbg"
        :style="
          'background: url(https://assets.website-files.com/5c6eb35188c954830ec0365d/5c817444f2a0f7e7f938ae36_yacht-dtp.jpg) center center; background-size: cover;'
        "
      ></div>
      <div class="cbgmenu"></div>
      <div class="ui sixteen column padded grid centered">
        <div class="row">
          <div class="sixteen wide column">
            <div>
              <h2>{{ company.name }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="sixteen wide column">
            <div class="ui secondary pointing menu"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui column padded grid centered">
      <div class="row">
        <div class="sixteen wide column">
          <div class="ui attached segment form">
            <div class="field">
              <label>COMPANY NAME</label>
              <input
                type="text"
                :disabled="isEditScreen == false"
                v-model="company.name"
              />
            </div>
          </div>
          <div class="ui attached message">
            <sui-button
              basic
              positive
              v-if="isEditScreen == false"
              @click.prevent="isEditScreen = true"
              >Edit</sui-button
            >
            <sui-button
              basic
              primary
              v-if="success == false"
              @click.prevent="saveCompany"
              >Save</sui-button
            >
            <div class="ui positive message" v-if="success">
              <i class="close icon" @click.prevent="success = false"></i>
              <div class="header">Success</div>
              <p>
                Click <router-link to="../../companies">here</router-link> to go
                back to all companies
              </p>
            </div>
              <div class="ui negative message" v-if="failed">
                  <i class="close icon" @click.prevent="success = false"></i>
                  <div class="header">Failed</div>
                  <p>
                      Click <router-link to="../../companies">here</router-link> to go
                      back to all companies or try again.
                  </p>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCompany,
  createCompany,
  uploadFile,
  fileDownload,
  BaseUrl
} from "@/gateway";
export default {
  data() {
    return {
      success: false, 
      failed: false,
      isEditScreen: false,
      id: 0,
      company: null,
      tabId: 1
    };
  },
  computed: {},

  methods: {
    async saveCompany() {
        if(this.company.name != null && this.company.name != undefined && this.company.name != "") {
            this.company.dateCompleted = new Date();
            const response = await createCompany({company: this.company});
            if (response) {
                this.success = true;
                await this.onUpload();
            } else {
                this.failed = true;
            }
        }
        else
        {
            confirm("Please enter data before trying to save.");
        }
    },
    async init() {
      const response = await getCompany({ id: this.id });
      if (response) this.company = response.result;
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.id == 0) this.isEditScreen = true;
    this.init();
  }
};
</script>
