<!-- @format -->

<template>
  <div class="about">
    <div class="client-container">
      <div
        class="cbg"
        :style="
          'background: url(https://assets.website-files.com/5c6eb35188c954830ec0365d/5c817444f2a0f7e7f938ae36_yacht-dtp.jpg) center center; background-size: cover;'
        "
      ></div>
      <div class="cbgmenu"></div>
      <div class="ui sixteen column padded grid centered">
        <div class="row">
          <div class="sixteen wide column">
            <div>
              <h2>{{ fund.fundName }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="sixteen wide column"></div>
        </div>
      </div>
    </div>
    <div class="ui column padded grid centered">
      <div class="row">
        <div class="sixteen wide column">
          <div class="ui attached segment form">
            <h3 class="ui attached message">FUND INFORMATION</h3>
            <div class="ui attached segment">
              <div class="field">
                <label>COMPANY</label>
                <sui-dropdown
                  :disabled="isEditScreen == false || id != 0"
                  fluid
                  single
                  search
                  selection
                  :options="companies"
                  v-model="fund.companyId"
                />
              </div>

              <div class="field">
                <label>FUND NAME</label>
                <input
                  type="text"
                  :disabled="isEditScreen == false"
                  v-model="fund.fundName"
                />
              </div>

              <div class="field">
                <label>Is Past Performance Upload required?</label>
                <input
                  type="checkbox"
                  :disabled="isEditScreen == false"
                  v-model="fund.isPastPerformanceUploadRequired"
                />
              </div>
                
                <div class="field">
                    <label>Is Hedge Fund?</label>
                    <input
                            type="checkbox"
                            :disabled="isEditScreen == false"
                            v-model="fund.isHedgeFund"
                    />
                </div>
            </div>
            <div class="ui attached message">
              <sui-button
                basic
                positive
                v-if="isEditScreen == false"
                @click.prevent="isEditScreen = true"
                >Edit</sui-button
              >
              <sui-button
                basic
                primary
                v-if="success == false"
                @click.prevent="saveFund"
                >Save</sui-button
              >
            </div>

            <div class="ui positive message" v-show="success == true">
              <div class="header">Success</div>
              <p>
                Click <router-link to="../../funds">here</router-link> to go
                back to all funds!
              </p>
            </div>
              <div class="ui negative message" v-show="failed == true">
                  <div class="header">Failed</div>
                  <p>
                      Click <router-link to="../../funds">here</router-link> to go
                      back to all funds or try again!
                  </p>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFund,
  createFund,
  getCompanies,
  fileDownload,
  uploadFile
} from "@/gateway";
export default {
  data() {
    return {
      // uploads

      //
      companies: [],

      //
      success: false,
      failed : false,  
      isEditScreen: false,
      id: 0,
      fund: null
    };
  },

  methods: {
      async init() {
          const cr = await getCompanies({pageSize: 10000});
          this.companies = [];
          if (cr) {
              cr.result.forEach(item => {
                  this.companies.push({
                      key: item.id,
                      text: item.name,
                      value: item.id
                  });
              });
          }
          const response = await getFund({id: this.id});

          console.log("fund", response);
          if (response) this.fund = response.fund;
      },
      async saveFund() {
          if (this.fund.fundName != undefined && this.fund.fundName != "" && this.fund.companyId != 0) {
              this.fund.fundDateCompleted = new Date();
              const response = await createFund({fund: this.fund});
              if (response) {
                  console.log(" save fund reponse", response);
                  this.success = true;
              } else {
                  this.failed = true;
              }
          }
          else
          {
              confirm("Please enter data before trying to save.");
          }
          
      }      
  },
  mounted() {
      this.id = this.$route.params.id;
      if (this.id == 0) this.isEditScreen = true;
      this.init();
  }
  };

</script>
