<template>
<div>
		<input type="text" placeholder="Search" v-on:input="filterColumns1"
	       class="flex items-center w-full border-b bg-white px-1 pt-1 text-sm text-gray-500" />
		<div class="h-48 overflow-y-scroll bg-gray-50 px-2 py-2 relative">
			<div class="relative group absolute w-full top-0">
			
			</div>
			<div class="">
			<div class="flex flex-col  gap-y-1 w-full text-sm " v-for="f in Object.keys(fund)">
				<div class="top-8 flex justify-between gap-x-2" >
					<div class="w-[66%] overflow-x-clip">{{f}}</div><div><span :class="component.columns1.filter((u) => { return u === f; }).length>0 ? ' font-bold hover:pointer-none' : 'hover:cursor-pointer '" @click.prevent="showField(f, component.columns1)">show</span> | <span :class="component.columns1.filter((u) => { return u === f; }).length == 0 ? ' font-bold hover:pointer-none' : 'hover:cursor-pointer '" @click.prevent="hideField(f.name, component.columns1)">hide</span></div>
				</div>
			</div>
		</div>	
		</div>

	<div class="h-48 mt-4 overflow-y-scroll bg-gray-50 px-2 py-2 relative">
		<div class="relative group absolute w-full top-0">

		</div>
		<div class="">
			<div class="flex flex-col  gap-y-1 w-full text-sm " v-for="f in component.columns1">
				<div class="top-8 flex justify-between gap-x-2" >
					<div class="w-[66%] overflow-x-clip">{{f.name}}</div>
						</div>
			</div>
		</div>
	</div>
</div>
</template>

<script >
export default {
	name: "KeyFactTableSettings.vue",
	props:['fund','component'],
	methods: {
		filterColumns1(){
			var fund = props.fund;
			
		},
		showField(column, array){
			array.push({name: column, label:column, row: 1, columnSpan: 1});
		},
		hideField(column, array){
		
			for (var i = 0; i< array.length; i++)
			{
				if (column === array[i].name)
					array.splice(i,1);
			}
		
			
		},
	}
}
</script>

<style scoped>

</style>