<!-- @format -->
<template>
  <div class="about">
    <div class="client-container">
      <div
        class="cbg"
        :style="
          'background: url(https://assets.website-files.com/5c6eb35188c954830ec0365d/5c817444f2a0f7e7f938ae36_yacht-dtp.jpg) center center; background-size: cover;'
        "
      ></div>
      <div class="cbgmenu"></div>
      <div class="ui sixteen column padded grid centered">
        <div class="row">
          <div class="sixteen wide column">
            <div>
              <h2>FUNDS</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui column padded grid centered">
      <div class="row">
        <div class="sixteen wide column">
          <div class="ui raised padded segment">
            <div class="ui segment">
              <sui-input
                fluid
                placeholder="Search..."
                type="text"
                v-model="searchText"
                v-on:keyup="getResults(1)"
              />
            </div>
            <table class="ui compact table">
              <thead>
                <tr>
                  <th>COMPANY NAME</th>
                  <th>FUND NAME</th>
                  <th>VIEW FUND</th>
                  <th v-if="isAdminUser(user)">DELETE FUND</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="f in funds" :key="f.id">
                  <td>{{ f.companyName }}</td>
                  <td>{{ f.fundName }}</td>
                  <td>
                    <router-link
                      :to="'/funds/' + f.id"
                      class="ui primary basic button"
                    >
                      View
                    </router-link>
                  </td>
                  <td>
                    <a
                      href="#"
                      @click.prevent="deleteFund(f.id)"
                      v-if="isAdminUser(user)"
                      class="ui red basic button"
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <sui-menu v-if="totalPages > 1" style="float: right" pagination>
              <a is="sui-menu-item" @click.prevent="getResults(1)" icon>
                <sui-icon name="left chevron" />
              </a>
              <a
                is="sui-menu-item"
                v-if="currentPageNr - 4 > 0 && currentPageNr - 4 <= totalPages"
                @click.prevent="getResults(currentPageNr - 4)"
                >{{ currentPageNr - 4 }}</a
              >
              <a
                is="sui-menu-item"
                v-if="currentPageNr - 3 > 0 && currentPageNr - 3 <= totalPages"
                @click.prevent="getResults(currentPageNr - 3)"
                >{{ currentPageNr - 3 }}</a
              >
              <a
                is="sui-menu-item"
                v-if="currentPageNr - 2 > 0 && currentPageNr - 2 <= totalPages"
                @click.prevent="getResults(currentPageNr - 2)"
                >{{ currentPageNr - 2 }}</a
              >
              <a
                is="sui-menu-item"
                v-if="currentPageNr - 1 > 0 && currentPageNr - 1 <= totalPages"
                @click.prevent="getResults(currentPageNr - 1)"
                >{{ currentPageNr - 1 }}</a
              >
              <div is="sui-menu-item">
                <strong>{{ currentPageNr }}</strong>
              </div>

              <a
                is="sui-menu-item"
                v-if="currentPageNr + 1 > 0 && currentPageNr + 1 <= totalPages"
                @click.prevent="getResults(currentPageNr + 1)"
                >{{ currentPageNr + 1 }}</a
              >
              <a
                is="sui-menu-item"
                v-if="currentPageNr + 2 > 0 && currentPageNr + 2 <= totalPages"
                @click.prevent="getResults(currentPageNr + 2)"
                >{{ currentPageNr + 2 }}</a
              >
              <a
                is="sui-menu-item"
                v-if="currentPageNr + 3 > 0 && currentPageNr + 3 <= totalPages"
                @click.prevent="getResults(currentPageNr + 3)"
                >{{ currentPageNr + 3 }}</a
              >
              <a
                is="sui-menu-item"
                v-if="currentPageNr + 4 > 0 && currentPageNr + 4 <= totalPages"
                @click.prevent="getResults(currentPageNr + 4)"
                >{{ currentPageNr + 4 }}</a
              >
              <a
                is="sui-menu-item"
                icon
                @click.prevent="getResults(totalPages)"
              >
                <sui-icon name="right chevron" />
              </a>
            </sui-menu>
            <router-link
              v-if="canCreateFund(user)"
              to="/funds/create/0"
              class="ui primary basic button"
              >Create new Fund</router-link
            >
            |
            <sui-button
              basic
              positive
              :loading="loadingExcel"
              v-if="isEditScreen != false"
              @click.prevent="downloadExcel"
              >Download Excel</sui-button
            >
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFunds, deleteFund, downloadFundsExcel } from "@/gateway";

export default {
  data() {
    return {
      loadingExcel: false,
      loading: false,
      funds: [],
      currentPageNr: 1,
      totalRecords: null,
      totalPages: null,
      fundName: "",
      searchText: "",
      isEditScreen: true
    };
  },
  computed: {
    user() {
      if (this.$store.state.user) return this.$store.state.user;
      else return "";
    }
  },
  methods: {
    async downloadExcel() {
      this.loadingExcel = true;
      const response = await downloadFundsExcel();
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data);
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        this.loadingExcel = false;
        //
        link.click();
      }
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    async deleteFund(id) {     
      if (confirm("Are you sure you want to Delete this?")) {
          const response = await deleteFund({ id: id });
          if (response) {
              await this.getResults(this.currentPageNr);
          }
      }
    },
    isAdminUser(user) {
      if (user.roles) {
        if (user.roles.includes("Admin")) {
          return true;
        } else return false;
      } else {
        return false;
      }
    },
    canCreateFund(user) {
      if (user.roles) {
        if (user.roles.includes("Admin")) {
          return true;
        } else return false;
      } else {
        return false;
      }
    },
    async init() {
      this.getResults(1);
    },
    async getResults(pageNr) {
      this.currentPageNr = pageNr;
      const response = await getFunds({
        searchText: this.searchText,
        pageNr: pageNr
      });

      if (response) {
        this.funds = response.result;
        this.totalRecords = response.totalRecords;
        this.totalPages = response.totalPages;
      }
    }
  },
  mounted() {
    this.init();
  }
};
</script>
