/** @format */

import { JsonServiceClient } from "@servicestack/client";
import { SessionInfo, Authenticate } from "@/authdtos";
import { UploadRequest } from "@/dtos";

export const BaseUrl = process.env.NODE_ENV === 'production' ? "https://api.ac-managerhub.co.za/" : "http://localhost:62001/";

export const client = new JsonServiceClient(BaseUrl);

export const getCompanies = async params => {
  const response = await client.get("/companies", params);
  return response;
};

export const getCompany = async params => {
  const response = await client.get("/companies/" + params.id);
  return response;
};

export const downloadCompaniesExcel = async params => {
  const response = await client.get("/companies/download/excel");
  return response;
};

export const downloadCompaniesExcelLog = async params => {
  const response = await client.get("/companies/download/log");
  return response;
};

export const downloadCompaniesCommentsExcel = async params => {
  const response = await client.get("/companies/comments/download/excel");
  return response;
};

export const getUsers = async params => {
  const response = await client.get("/users", params);
  return response;
};

export const getSessionInfo = async () => {
  try {
    //Converts Session to JWT Token Cookie

    const authResponse = await client.get(new SessionInfo());
    return authResponse;
  } catch (e) {
    return null;
  }
};

export const logout = async () => {
  localStorage.removeItem("ss-tok");
  const request = new Authenticate();
  request.provider = "logout";
  // await getSessionInfo();
  await client.post(request);

  return "/";
};

export const createCompany = async params => {
  const response = await client.postToUrl("/companies", {
    company: params.company
  });
  return response;
};

export const deleteCompany = async params => {
  const response = await client.delete("/companies/" + params.id);
  return response;
};

export const createFund = async params => {
  console.log("posting to funds", params.fund);
  /*  public string Name { get; set; } = "";

        public bool IsEquityOwner { get; set; } = false;
        public string Position { get; set;  } = "";
        public string StartDate { get; set; }
        public string EndDate { get; set; } = "";
  */
  //params.fund.keyDecisionMakers.push({name: "test122", isEquityOwner: false, position: "bla", startDate: "asda",endDate:"asdsadsad" });
  //params.fund.keyDecisionMakers.push({Name: "2test122", IsEquityOwner: false, Position: "bla", StartDate: "asda",EndDate:"asdsadsad" });
  const response = await client.postToUrl("/funds", { fund: params.fund });
  return response;
};

export const deleteFund = async params => {
  const response = await client.delete("/funds/" + params.id);
  return response;
};

export const downloadFundsExcel = async params => {
  const response = await client.get("/funds/download/excel");
  return response;
};

export const downloadFundsExcelLog = async params => {
  const response = await client.get("/funds/download/log");
  return response;
};

export const downloadFundsCommentsExcel = async params => {
  const response = await client.get("/funds/comments/download/excel");
  return response;
};

export const getFunds = async params => {
  const response = await client.get("/funds", params);
  return response;
};

export const getFund = async params => {
  const response = await client.get("/funds/" + params.id);
  return response;
};

export const getReportTemplate = async params => {
  const response = await client.get("/report/template/" + params.id);
  return response;
};

// commentary
export const saveFundCommentary = async params => {
  const response = await client.postToUrl("/funds/commentary", params);
  return response;
};

export const saveCompanyCommentary = async params => {
  const response = await client.postToUrl("/company/commentary", params);
  return response;
};
// users

/*
export const assignRole = async (params) => {
  let request = new AssignRole();
  request.userName = params.userName;
  request.role = params.role;
  request.permission = params.permission;
  const response = await client.post(request);
  return response;
};

export const unAssignRole = async (params) => {
  let request = new UnAssignRole();
  request.userName = params.userName;
  request.role = params.role;
  request.permission = params.permission;
  const response = await client.post(request);
  return response;
};*/

export const unassignAllPermissions = async params => {
  const response = await client.postToUrl("/users/unassignallpermissions", {
    userName: params.userName
  });
  return response;
};

/*
export const updateUser = async params => {
  const response = await client.get("/users/update", params);
  return response;
};
*/
export const updateUserPassword = async params => {
  const response = await client.get("/user/management/password", params);
  return response;
};

export const updateUser = async params => {
  const response = await client.postToUrl("/users/update", {
    email: params.email,
    companyIds: params.companyIds
  });
  return response;
};

export const createUser = async params => {
  const response = await client.postToUrl("/users/create", {
    userName: params.userName,
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    companyId: params.companyId,
    isInternalUser: params.isInternalUser,
    isAdmin: params.isAdmin,
    companyIds: params.companyIds
  });
  return response;
};

export const fileDownload = async params => {
  const response = await client.get("/file/download", params);
  return response;
};

export const fileDelete = async params => {
  const response = await client.delete("/file/delete", params);
  return response;
};

export const uploadFile = async params => {
  let formData = new FormData();
  formData.append("description", params.selectedFile.name);
  formData.append("type", "file");
  formData.append("file", params.selectedFile);

  client.postBody(
      new UploadRequest({
        entityType: params.entityType,
        entityId: params.entityId,
        fileType: params.fileType
      }),
      formData
  );
  /* var formData = new FormData();
  formData.append("description", params.selectedFile.name);
  formData.append("type", "file");
  formData.append("image", params.selectedFile);

  return fetch(BaseUrl + "/upload/file", {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: 'Client-ID c891e34185a353f'
    },
    body: formData,
  }).then((r) => {
    return r;
  });*/
};

export const deleteUser = async params => {
  const response = await client.postToUrl("/users/delete", {
    email: params.email
  });

  return response;
};
