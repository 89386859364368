<template>

	<div class="bg-white flex relative">
		<!-- page settings -->
		 <div v-if="fund && template" class="w-96 block print:hidden bg-amber-100 h-screen px-6 py-6 absolute overflow-y-scroll">
			
			<div>
				<div v-for="c in template.pages[0].components">
					{{c.type}}
				
					<div v-if="c.type=='Key facts table'">	
				
							<key-fact-table-settings :fund="fund" :component="c" />
				
					</div>
				</div>
				
			</div>
			
			
		</div>
		<!-- pages -->
		<div class="left-96 print:left-0 flex flex-col h-screen justify-center items-center print:gap-y-0 bg-gray-200 py-4 px-4 print:p-0  absolute overflow-y-scroll" v-if="fund && template">
			<!-- page 1 -->
			<div class=" print:mt-0" v-if="true">
			{{template}}
			<div v-if="true">
				<main class="w-[29.7cm] h-[21cm] scale-75 shadow-xl print:scale-100 bg-white relative overflow-y-clip" v-for="p in template.pages">
					<div class="flex flex-col justify-between  h-full absolute  relative">
						<div class="absolute w-full px-4 py-4">
							<!-- header -->
							<div class="flex justify-between ">

								<div>
									<div class="flex flex-col">
										<div class="text-xl font-bold text-gray-600">{{ fund.fundName }}</div>
										<div class="text-gray-500 text-left">August 2022</div>
									</div>
								</div>
								<div><img src="https://furu-uploads.s3.af-south-1.amazonaws.com/pl-analytics/pl-brands/Dynasty-Logo.svg"
								          class="h-16"/></div>
							</div>
							<!-- end header -->

							<!-- border -->
							<div class="w-full h-0.5 bg-gray-100 rounded mt-2 mb-2">

							</div>
							<div class="font-bold text-gray-600">Key facts</div>
							<!-- end border -->
							<!-- content -->
							<div class="flex gap-x-2">
								<!-- column 1 -->
								<div class="w-full  px-0 py-2 text-[9pt]">
									<div class="w-full" v-for="component in p.components">
									
										<key-fact-table-component v-if="component.type =='Key facts table'" :component="component" :fund="fund" />
									</div>
								</div>
								<!-- end column 1 -->

							</div>
							<!-- end content -->
						</div>
						<div class="flex justify-between absolute w-full bottom-0">
							<div> bl</div>
							<div> br</div>
						</div>
					</div>

				</main>
			</div>
				</div>
			<!-- end page 1 -->
			<!-- page 1 -->
			<div class="-mt-48 print:mt-0" v-if="false">
			<main class="w-[29.7cm] h-[21cm]  scale-75 print:scale-100 bg-white  relative overflow-y-clip">
				<div class="flex flex-col justify-between  h-full absolute  relative">
					<div class="absolute w-full px-4 py-4">
						<!-- header -->
						<div class="flex justify-between ">
							<div><img src="https://furu-uploads.s3.af-south-1.amazonaws.com/pl-analytics/pl-brands/Dynasty-Logo.svg"
							          class="h-16"/></div>
							<div>
								<div><img src="https://logo.clearbit.com/36one.co.za" class="h-16"/></div>
								<div class="flex flex-col">
									<div class="text-xl font-bold text-gray-600">36ONE BCI SA Equity Fund</div>
									<div class="text-gray-500 text-right">August 2022</div>

								</div>
							</div>
						</div>
						<!-- end header -->

						<!-- border -->
						<div class="w-full h-0.5 bg-gray-100 rounded mt-2 mb-2">

						</div>
						<div class="font-bold text-gray-600">Key facts</div>
						<!-- end border -->
						<!-- content -->
						<div class="flex gap-x-2">
							<!-- column 1 -->
							<div class="w-full  px-0 py-2 text-[9pt]">
								<div class="w-full">
									<div class="grid grid-cols-2 border-b" v-for="d in dummyData">
										<div class="font-bold"> {{ d.title }}</div>
										<div> {{ d.value }}</div>
									</div>
								</div>

							</div>
							<!-- end column 1 -->
							<div class="w-full  px-0 py-2 text-[9pt]">
								<div class="w-full">
									<div class="grid grid-cols-2  border-b" v-for="d in dummyData2">
										<div class="font-bold"> {{ d.title }}</div>
										<div> {{ d.value }}</div>
									</div>
								</div>

							</div>

						</div>
						<!-- end content -->
					</div>
					<div class="flex justify-between absolute w-full bottom-0">
						<div> bl</div>
						<div> br</div>
					</div>
				</div>

			</main>
			</div>
			<!-- end page 1 -->
			<!-- page 2 -->
			<div  v-if="false" class="-mt-48 print:mt-0">
			<main class="w-[29.7cm] h-[20.85cm]  scale-75 print:scale-100 bg-white  relative overflow-y-clip">
				<div class="flex flex-col justify-between h-full absolute  relative">
					<div class="absolute w-full px-4 py-4">
						<!-- header -->
						<div class="flex justify-between ">
							<div><img src="https://furu-uploads.s3.af-south-1.amazonaws.com/pl-analytics/pl-brands/Dynasty-Logo.svg"
							          class="h-16"/></div>
							<div class="flex flex-col">
								<div class="text-xl font-bold text-gray-600">36ONE BCI SA Equity Fund</div>
								<div class="text-gray-500 text-right">August 2022</div>
							</div>
						</div>
						<!-- end header -->

						<!-- border -->
						<div class="w-full h-0.5 bg-gray-100 rounded mt-2 mb-2">

						</div>
						<!-- end border -->
						<!-- content -->
						<div class="flex gap-x-2">
							<!-- column 1 -->
							<div class="w-full  px-0 py-2">
								<div class="grid grid-cols-2">
									<div>
										<div class="font-bold text-sm text-gray-600">Firm Overview</div>
										<div class="text-print">36ONE is wholly owned by Cy Jacobs and Steven Liptz and was founded in 2004.
											They remain responsible for the two key areas of the business under which all business activities
											fall. Cy Jacobs is responsible for investment related matters and Steven Liptz is responsible for
											all non-investment areas. Following the firm's initial success with private client portfolios,
											36ONE launched two unit trusts in September 2005 and its first hedge fund in April 2006. Today the
											firm manages unit trusts, hedge funds and institutional segregated mandates. They focus on the
											management of client portfolios and outsource peripheral functions.
										</div>
									</div>
									<div>
										<div class="font-bold text-sm text-gray-600">Strategy Overview</div>
										<div class="text-print">36One managed the fund according to their philosophy that stock selection,
											through bottom-up fundamental analysis, can outperform over time. They are style agnostic in their
											approach. The fund is benchmarked to the Capped SWIX.
											The fundamental bottom-up research is very detailed and incorporates industry dynamics as well as
											understand a company’s profitability drivers and positioning within the industry. To gain such an
											understanding, they engage with stakeholders (such as competitors, suppliers, customers and the
											company itself). They look for companies that have a sustainable business model, independent board
											and strong management. Despite their bottom-up focus, macroeconomic views play a supporting role
											in portfolio construction. They have a strong focus on risk, which their extensive research to
											avoid poor companies, as well as ongoing monitoring, supports this notion.

										</div>
									</div>

								</div>


								<div class="mt-4">
									<div class="font-bold text-sm text-gray-600">Analytics Consulting Insights</div>
									<div class="text-print">36One are an experienced and well-resourced team with a focus to remain small
										and nimble with top quality investment individuals. From these traits, we expect there to be strong
										active debate within the team. With their nimble approach, it also means they tend to be very active
										traders, willing to adjust their positioning to express their views. The turnover in the portfolio
										may also subsequently be high. They are very explicit in how they build their portfolio, and
										critical of their performance and do not aim for average performance.
										The investment approach, which entails buying shares that are undervalued and selling shares that
										are overvalued, should lead to a fund that beats the market in the long run. This approach generally
										lags the market during periods of strong performance and out-performs during times of weakness (has
										been the case historically).
										Since 2018, they have become more benchmark aware as they recognised that their high conviction bets
										were not always adding the alpha that they wanted. We view this positively as the introspection
										means they saw where their process of managing the fund could be improved.
									</div>
								</div>

							</div>
							<!-- end column 1 -->

						</div>
						<!-- end content -->
					</div>
					<div class="flex justify-between absolute bottom-0 text-[0.5rem] text-gray-500 px-2 py-2 bg-red-100">
						<div>Disclaimer: The information provided is of a general nature only and does not take into account
							investor’s objectives, financial situations or needs. The information does not constitute financial
							product advice and it should not be used, relied upon or treated as a substitute for specific,
							professional advice. It is, therefore, recommended that investors obtain the appropriate legal, tax,
							investment and/or other professional advice and formulate an investment strategy that would suit the
							investor’s risk profile prior to acting on such information and to consider whether any recommendation is
							appropriate considering the investor’s own objectives and particular needs. Although the information
							provided and statements of fact are obtained from sources that Analytics Consulting considers reliable, we
							do not guarantee their accuracy, completeness or currency and any such information may be incomplete or
							condensed. No guarantee of investment performance should be inferred from any of the information contained
							in this document. Collective Investment Schemes in Securities (“CIS”) are generally medium- to long-term
							investments. The value of participatory interests may go down as well as up and past performance is not
							necessarily a guide to future performance. Any opinions, statements and information made available,
							whether written, oral or implied are expressed in good faith. Views are subject to change, without prior
							notice, on the basis of additional or new research, new facts or developments. All data is in base
							currency terms unless otherwise indicated, and sourced as disclosed. Financial Services Providers:
							Portfolio Analytics Consulting (Pty) Ltd; FSP No 18490 and Analytics Consulting 1 (Pty) Ltd; FSP No 47564;
							Tel: (021) 936 9500; Website: www.analyticsconsulting.co.za and DYNASTY ASSET MANAGEMENT (PTY) LTD; FSP No
							20722; Tel: (011)2121900; Website: www.dynasty.co.za
						</div>

					</div>
				</div>

			</main>
			</div>
			<!-- end page 2 -->
		</div>
	</div>
</template>
<script >
import {getFund, getReportTemplate} from "@/gateway";
import KeyFactTableSettings from "@/components/print/KeyFactTableSettings";
import KeyFactTableComponent from "@/components/print/KeyFactTableComponent";

import TextLabel from "@/components/print/TextLabel";
export default {
	name: "ProposalList",
	components: { KeyFactTableSettings, KeyFactTableComponent, TextLabel},
	props: {
		id: String
	},
	data() {
		return {
			template: null,
			components: [{type:"Key facts table",
				show: true,
				page: 1,
				columns1: [{ name: "asisaCategory", row:1, columnSpan:1},{ name:"describeIdeaGeneration", row:2, columnSpan:3}],
				columns2: [{ name: "asisaCategory", row:1, columnSpan:1}]
			}],
			fund: null,
			columns1: ["asisaCategory","describeIdeaGeneration"],
			dummyData: [
				{
					"title": "Firm",
					"value": "36ONE Asset Management"
				},
				{
					"title": "Ownership",
					"value": "Private Company"
				},
				{
					"title": "Year Founded",
					"value": "2004-12-14"
				},
				{
					"title": "Firm Location(s)",
					"value": "Primary office in Johannesburg"
				},
				{
					"title": "Total Employees",
					"value": 22
				},
				{
					"title": "Investment personnel",
					"value": 12,
					isSub: true
				},
				{
					"title": "Non-investment personnel",
					"value": 10,
					isSub: true
				},
				{
					"title": "Firm AUM (millions)",
					"value": "ZAR 31549m as at 2022-03-31"
				},
				{
					"title": "Fund AUM (millions)",
					"value": "ZAR 7489m as at 2022-03-31"
				},
				{
					"title": "Fund client concentration",
					"value": "Top five investors make up 56% of the fund"
				},
				{
					"title": "Does the fund have an explicit ESG focus?",
					"value": "No"
				},
				{
					"title": "Idea Generation",
					"value": "Analysts are responsible for industry and company research. Ideas are generated by applying fundamental analysis to identify securities that we believe are priced incorrectly.\nThe South African market imposes constraints on us as we must be cognisant of the liquidity of the companies we wish to invest in. This reduces the total investable market to around 120 equities. This allows our analysts to cover nearly all the stocks in their sector and flag any interesting opportunities."
				},
				{
					"title": "Use derivatives",
					"value": "No"
				},
				{
					"title": "Does the fund hedge FX",
					"value": "No"
				}
			],
			dummyData2: [
				{
					"title": "Investment Approach",
					"value": "Fundamental Bottom-up"
				},
				{
					"title": "Investment Style",
					"value": "Style Agnostic"
				},
				{
					"title": "Portfolio managers",
					"value": "Cy Jacobs"
				},
				{
					"title": "Strategy inception",
					"value": "2014-09-10"
				},
				{
					"title": "Benchmark",
					"value": "Capped SWIX"
				},
				{
					"title": "Asset Class / ASISA Category",
					"value": "(ASISA) South African EQ General"
				},
				{
					"title": "",
					"value": ""
				},
				{
					"title": "Alpha target (%)",
					"value": 3
				},
				{
					"title": "Tracking error",
					"value": "1% - 3%"
				},
				{
					"title": "Minimum time horizon (years)",
					"value": 5
				},
				{
					"title": "Maximum stock allocation (%)",
					"value": 10
				},
				{
					"title": "Sector or position limits (%)",
					"value": "The funds have no specific sector limitations, however, we are cognisant of concentration risk whether by size or sector. The positions in our portfolios are built on price and liquidity available at the time of investing. We continually assess a company’s price relative to its valuation as well as its liquidity and act accordingly if the circumstances change.\n\nThe 36ONE BCI SA Equity Fund only invests in shares listed on the Johannesburg Stock Exchange. The portfolio has a minimum equity exposure of 95%, with a maximum cash allocation limit of 5%."
				},
				{
					"title": "Number of underlying stocks",
					"value": "40 - 60"
				},
				{
					"title": "Does the fund include Global",
					"value": "No"
				}
			]
		}
	},
	methods: {
			selectElement(element){
				console.log("element", element);
			},
		 async getFund(){
			 const response = await getFund({ id: 16 });
			 if (response){
				  this.fund = response.fund;
			 }
		 },
		async getTemplate(){
			const response = await getReportTemplate({ id: 16 });
			if (response){
				this.template = response.result;
			}
		}
	},
	 mounted() {
		 this.getFund();
		 this.getTemplate();
	}
}
//pages
</script>

<style scoped></style>
