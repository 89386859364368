/** @format */

// @Route("/auth")
// @Route("/auth/{provider}")
// @Route("/authenticate")
// @Route("/authenticate/{provider}")
// @DataContract
var Authenticate = /** @class */ (function() {
  function Authenticate() {}
  Authenticate.prototype.createResponse = function() {
    return new AuthenticateResponse();
  };
  Authenticate.prototype.getTypeName = function() {
    return "Authenticate";
  };
  return Authenticate;
})();
export { Authenticate };

// @Route("/session-to-token")
// @DataContract
var ConvertSessionToToken = /** @class */ (function() {
  function ConvertSessionToToken() {}
  ConvertSessionToToken.prototype.createResponse = function() {
    return new ConvertSessionToTokenResponse();
  };
  ConvertSessionToToken.prototype.getTypeName = function() {
    return "ConvertSessionToToken";
  };
  return ConvertSessionToToken;
})();
export { ConvertSessionToToken };

// @DataContract
var AuthenticateResponse = /** @class */ (function() {
  function AuthenticateResponse() {}
  return AuthenticateResponse;
})();
export { AuthenticateResponse };
// @DataContract
var ConvertSessionToTokenResponse = /** @class */ (function() {
  function ConvertSessionToTokenResponse() {}
  return ConvertSessionToTokenResponse;
})();
export { ConvertSessionToTokenResponse };
// @Route("/access-token")
// @DataContract
var GetAccessToken = /** @class */ (function() {
  function GetAccessToken() {}
  GetAccessToken.prototype.createResponse = function() {
    return new GetAccessTokenResponse();
  };
  GetAccessToken.prototype.getTypeName = function() {
    return "GetAccessToken";
  };
  return GetAccessToken;
})();
export { GetAccessToken };

// @Route("/userinfo/{UserName}")
var GetUserInfo = /** @class */ (function() {
  function GetUserInfo() {}
  GetUserInfo.prototype.createResponse = function() {
    return new GetUserInfoResponse();
  };
  GetUserInfo.prototype.getTypeName = function() {
    return "GetUserInfo";
  };
  return GetUserInfo;
})();
export { GetUserInfo };

var GetUserInfoResponse = /** @class */ (function() {
  function GetUserInfoResponse() {}
  return GetUserInfoResponse;
})();
export { GetUserInfoResponse };

// @Route("/my-session")
var SessionInfo = /** @class */ (function() {
  function SessionInfo() {}
  SessionInfo.prototype.createResponse = function() {
    return new SessionInfoResponse();
  };
  SessionInfo.prototype.getTypeName = function() {
    return "SessionInfo";
  };
  return SessionInfo;
})();
export { SessionInfo };
// @DataContract
var SessionInfoResponse = /** @class */ (function() {
  function SessionInfoResponse() {}
  return SessionInfoResponse;
})();
export { SessionInfoResponse };
// @DataContract
var GetAccessTokenResponse = /** @class */ (function() {
  function GetAccessTokenResponse() {}
  return GetAccessTokenResponse;
})();
export { GetAccessTokenResponse };
