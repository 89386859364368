<!-- @format -->

<template>
  <div class="about">
    <div class="client-container">
      <div
        class="cbg"
        :style="
          'background: url(https://assets.website-files.com/5c6eb35188c954830ec0365d/5c817444f2a0f7e7f938ae36_yacht-dtp.jpg) center center; background-size: cover;'
        "
      ></div>
      <div class="cbgmenu"></div>
      <div class="ui sixteen column padded grid centered">
        <div class="row">
          <div class="sixteen wide column centered">
            <div>
              <h2 class="centered">{{ company.name }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="sixteen wide column">
            <div class="ui secondary pointing menu">
              <a
                class="ui item"
                @click.prevent="tabId = 1"
                v-bind:class="{ active: tabId == 1 }"
              >
                COMPANY DETAILS
              </a>
              <a
                class="ui item"
                @click.prevent="tabId = 2"
                v-bind:class="{ active: tabId == 2 }"
              >
                ESG
              </a>
              <a
                class="ui item"
                @click.prevent="tabId = 3"
                v-bind:class="{ active: tabId == 3 }"
              >
                ACTIVE OWNERSHIP
              </a>
              <a
                class="ui item"
                @click.prevent="tabId = 4"
                v-bind:class="{ active: tabId == 4 }"
              >
                UPLOADS
              </a>
              <a
                class="ui item"
                @click.prevent="tabId = 5"
                v-bind:class="{ active: tabId == 5 }"
                v-if="isAdminUser(user)"
              >
                COMMENTS
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui column padded grid centered">
      <div class="row">
        <div class="sixteen wide column">
          <div>
            <sui-progress progress color="blue" :percent="progressPercentage" />
          </div>
          <div class="ui attached segment">
            <div v-if="tabId == 1" class="ui form">
              <transition name="modelFade">
                <div v-if="company.popiNoticeChecked == false">
                  <sui-modal v-model="open">
                    <sui-modal-content>
                      <sui-modal-description>
                        <sui-header
                          >Analytics Consulting Privacy Policy</sui-header
                        >
                        <p>
                          Analytics Consulting values your right to privacy and
                          we are committed to protecting your personal
                          information. For more information on our processing
                          activities, please view our Privacy Policy which can
                          be found on our website
                          <a
                            href="https://www.analyticsconsulting.co.za/privacy-policy"
                            target="_blank"
                          >
                            https://www.analyticsconsulting.co.za/privacy-policy</a
                          >
                        </p>
                        <p>
                          By proceeding, you hereby acknowledge that you have
                          viewed and accepted our Privacy Statement.
                        </p>
                      </sui-modal-description>
                    </sui-modal-content>
                    <sui-modal-actions>
                      <div>
                        <sui-checkbox
                          label="Agree"
                          toggle
                          v-model="company.popiNoticeChecked"
                        />
                      </div>
                    </sui-modal-actions>
                  </sui-modal>
                </div>
              </transition>
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>COMPANY INFORMATION</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label>Company Name</label>
                      <input
                        type="text"
                        :disabled="isEditScreen == false"
                        v-model="company.name"
                      />
                    </div>
                    <div class="field">
                      <label>Date Founded</label>
                      <input
                        type="date"
                        :disabled="isEditScreen == false"
                        v-model="company.dateFounded"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Company Location(s)</label>
                      <input
                        type="text"
                        :disabled="isEditScreen == false"
                        v-model="company.firmLocation"
                      />
                    </div>
                    <div class="field">
                      <label>Investment Team Location</label>

                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="investmentTeamLocations"
                        selection
                        v-model="company.investmentTeamLocation"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Ownership Structure</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="ownershipStructures"
                        selection
                        v-model="company.ownershipStructure"
                      />
                    </div>
                    <div class="field">
                      <label>Employee Equity Ownership (%)</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="company.employeeEquityOwnership"
                      />
                    </div>
                  </div>
                  <transition name="fade">
                    <div v-if="company.ownershipStructure == 'Private Company'">
                      <label
                        ><strong>
                          <br />
                          If Private Company, please list the top 5 shareholders
                          and their ownership percentage (%)
                        </strong></label
                      >
                      <table
                        class="ui blue celled striped table"
                        v-if="company.ownershipDetailsPercentages.length > 0"
                      >
                        <thead>
                          <tr>
                            <th>Name of shareholder</th>
                            <th>Percentage of Ownership</th>
                            <th v-if="isEditScreen">Manage</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(o,
                            index) in company.ownershipDetailsPercentages"
                            :key="o.ownerName"
                          >
                            <td>{{ o.ownerName }}</td>
                            <td>{{ o.ownershipPercentage }}</td>
                            <td v-if="isEditScreen">
                              <a
                                href="#"
                                @click.prevent="
                                  removeOwnershipDetailsPercentage(index)
                                "
                                >Delete</a
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <span v-else>No private shareholders added</span>
                      <div class="ui attached message" v-if="isEditScreen">
                        <strong> ADD NEW SHAREHOLDER</strong>
                      </div>
                      <div class="ui attached segment" v-if="isEditScreen">
                        <div class="two fields">
                          <div class="field">
                            <label>Shareholder Name</label>
                            <input
                              type="text"
                              v-model="newOwnershipDetails.ownerName"
                            />
                          </div>
                          <div class="field">
                            <label>Ownership Percentage (%)</label>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              v-model="newOwnershipDetails.ownershipPercentage"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="ui attached message" v-if="isEditScreen">
                        <sui-button
                          secondary
                          @click.prevent="
                            addOwnershipDetail(newOwnershipDetails)
                          "
                          >Add</sui-button
                        >
                      </div>
                      <br />
                    </div>
                  </transition>

                  <div class="two fields">
                    <div class="field">
                      <label>Manco</label>
                      <input
                        type="text"
                        :disabled="isEditScreen == false"
                        v-model="company.manco"
                      />
                    </div>
                    <div class="field">
                      <label>BEE Rating</label>
                      <input
                        type="text"
                        :disabled="isEditScreen == false"
                        v-model="company.beeRating"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Employees female (%)</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="company.beeFemale"
                      />
                    </div>
                    <div class="field">
                      <label>Employees people of colour (%)</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="company.beeColour"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Do you have a Houseview or Multi-Strat/Franchises
                        approach within the company?
                      </label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="companyApproaches"
                        selection
                        v-model="company.companyApproach"
                      />
                    </div>
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>PERSONNEL</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label>Number of Key Individuals</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="company.numberOfKeyIndividuals"
                      />
                    </div>
                    <div class="field">
                      <label>Key Individual Names</label>
                      <input
                        type="text"
                        :disabled="isEditScreen == false"
                        v-model="company.keyIndividualName"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Total Number of Employees</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="company.numberOfEmployees"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Investment Personnel</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="company.numberOfInvestmentPersonnel"
                      />
                    </div>
                    <div class="field">
                      <label>Non-Investment Personnel</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="company.numberOfNonInvestmentPersonnel"
                      />
                    </div>
                  </div>
                  <div class="ui attached segment">
                    <label
                      ><strong>
                        List the current Investment Team members
                      </strong></label
                    >
                    <table
                      class="ui blue celled striped table"
                      v-if="company.currentInvestmentTeam.length > 0"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Role</th>
                          <th>Qualification</th>
                          <th>Company Start Date</th>
                          <th>Industry Start Date</th>
                          <th v-if="isEditScreen">Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(i, index) in company.currentInvestmentTeam"
                          :key="i.name"
                        >
                          <td>{{ i.name }}</td>
                          <td>{{ i.role }}</td>
                          <td>{{ i.qualification }}</td>
                          <td>{{ i.companyStartDate }}</td>
                          <td>{{ i.industryStartDate }}</td>
                          <td v-if="isEditScreen">
                            <a
                              href="#"
                              @click.prevent="removeInvestmentTeam(index)"
                              class="ui red basic button"
                              >Delete</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span v-else>No Investment Team members added</span>
                    <div class="ui attached message" v-if="isEditScreen">
                      <strong> ADD NEW INVESTMENT TEAM MEMBER</strong>
                    </div>
                    <div class="ui attached segment" v-if="isEditScreen">
                      <div class="three fields">
                        <div class="field">
                          <label>Name</label>
                          <input
                            type="text"
                            v-model="newCurrentInvestmentTeam.name"
                          />
                        </div>
                        <div class="field">
                          <label>Role</label>
                          <input
                            type="text"
                            v-model="newCurrentInvestmentTeam.role"
                          />
                        </div>
                        <div class="field">
                          <label>Qualification</label>
                          <input
                            type="text"
                            v-model="newCurrentInvestmentTeam.qualification"
                          />
                        </div>
                      </div>
                      <div class="three fields">
                        <div class="field">
                          <label>Company Start Date</label>
                          <input
                            type="date"
                            v-model="newCurrentInvestmentTeam.companyStartDate"
                          />
                        </div>
                        <div class="field">
                          <label>Industry Start Date</label>
                          <input
                            type="date"
                            v-model="newCurrentInvestmentTeam.industryStartDate"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="ui attached message" v-if="isEditScreen">
                      <sui-button
                        secondary
                        @click.prevent="
                          addInvestmentTeams(newCurrentInvestmentTeam)
                        "
                        >Add</sui-button
                      >
                    </div>
                  </div>
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label>Investment Team hires in past 12 months</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="company.numberOfInvestmentPersonnelHires"
                      />
                    </div>
                    <div class="field">
                      <label>Names of new hires</label>
                      <input
                        type="text"
                        :disabled="isEditScreen == false"
                        v-model="company.namesOfInvestmentPersonnelHires"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Investment Team departures in past 12 months</label
                      >
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="company.numberOfInvestmentPersonnelDepartures"
                      />
                    </div>
                    <div class="field">
                      <label>Names of departures</label>
                      <input
                        type="text"
                        :disabled="isEditScreen == false"
                        v-model="company.namesOfInvestmentPersonnelDepartures"
                      />
                    </div>
                  </div>
                  <div class="ui attached segment">
                    <label
                      ><strong>
                        List the current and past Chief Investment Officers
                      </strong></label
                    >
                    <table
                      class="ui blue celled striped table"
                      v-if="company.companyCios.length > 0"
                    >
                      <thead>
                        <tr>
                          <th>Name of CIO</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th v-if="isEditScreen">Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(c, index) in company.companyCios"
                          :key="c.cioName"
                        >
                          <td>{{ c.cioName }}</td>
                          <td>{{ c.cioStartDate }}</td>
                          <td>{{ c.cioEndDate }}</td>
                          <td v-if="isEditScreen">
                            <a
                              href="#"
                              @click.prevent="removeCompanyCio(index)"
                              class="ui red basic button"
                              >Delete</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span v-else>No CIOs added</span>
                    <div class="ui attached message" v-if="isEditScreen">
                      <strong> ADD NEW CIO</strong>
                    </div>
                    <div class="ui attached segment" v-if="isEditScreen">
                      <div class="three fields">
                        <div class="field">
                          <label>CIO Name</label>
                          <input type="text" v-model="newCompanyCios.cioName" />
                        </div>
                        <div class="field">
                          <label>CIO Start Date</label>
                          <input
                            type="date"
                            v-model="newCompanyCios.cioStartDate"
                          />
                        </div>
                        <div class="field">
                          <label>CIO End Date</label>
                          <input
                            type="date"
                            v-model="newCompanyCios.cioEndDate"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="ui attached message" v-if="isEditScreen">
                      <sui-button
                        secondary
                        @click.prevent="addCompanycCio(newCompanyCios)"
                        >Add</sui-button
                      >
                    </div>
                    <br />
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>COMPANY AUM</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label>AUM Currency</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="companyAumCurrencies"
                        selection
                        v-model="company.companyAumCurrency"
                      />
                    </div>
                    <div class="field">
                      <label>Date of latest AUM</label>
                      <input
                        type="date"
                        :disabled="isEditScreen == false"
                        v-model="company.latestAumDate"
                      />
                    </div>
                  </div>
                  <div class="ui attached segment">
                    <label
                      ><strong>
                        Current and prior year's Assets Under Management by
                        strategy (in millions)
                      </strong></label
                    >
                    <table class="ui blue celled striped table">
                      <thead>
                        <tr>
                          <th>Strategy</th>
                          <th>Current AUM</th>
                          <th>1 Year Back</th>
                          <th>2 Years Back</th>
                          <th>3 Years Back</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Bonds</td>
                          <td>
                            <input
                              class="table-control form-control"
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.bondsAumCurrent"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.bondsAum1"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.bondsAum2"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.bondsAum3"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Equity</td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.equityAumCurrent"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.equityAum1"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.equityAum2"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.equityAum3"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Fixed Income</td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.incomeAumCurrent"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.incomeAum1"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.incomeAum2"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.incomeAum3"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Money Market</td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.moneyMarketAumCurrent"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.moneyMarketAum1"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.moneyMarketAum2"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.moneyMarketAum3"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Multi-Asset</td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.multiAssetAumCurrent"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.multiAssetAum1"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.multiAssetAum2"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.multiAssetAum3"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Property</td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.propertyAumCurrent"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.propertyAum1"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.propertyAum2"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.propertyAum3"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Hedge Funds</td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.hedgeAumCurrent"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.hedgeAum1"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.hedgeAum2"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.hedgeAum3"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Other</td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.otherAumCurrent"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.otherAum1"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.otherAum2"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onkeyup="if(this.value<0)this.value=0"
                              onblur="if(this.value<0)this.value=0"
                              min="0"
                              :disabled="isEditScreen == false"
                              v-model="company.otherAum3"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Total</strong></td>
                          <td class="right aligned">
                            <strong>{{
                              formatNr(totalAumCurrent, "0,0.00")
                            }}</strong>
                          </td>
                          <td class="right aligned">
                            <strong>{{ formatNr(totalAum1, "0,0.00") }}</strong>
                          </td>
                          <td class="right aligned">
                            <strong>{{ formatNr(totalAum2, "0,0.00") }}</strong>
                          </td>
                          <td class="right aligned">
                            <strong>{{ formatNr(totalAum3, "0,0.00") }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label
                        >What percentage of AUM are Retail asset? (%)</label
                      >
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="company.retailAssetsPercentage"
                      />
                    </div>
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>REMUNERATION PHILOSOPHY</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label>Salary</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="salaries"
                        selection
                        v-model="company.salary"
                      />
                    </div>
                    <div class="field">
                      <label>Bonus</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="bonuses"
                        selection
                        v-model="company.bonus"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Profit Share</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="profitShares"
                        selection
                        v-model="company.profitShare"
                      />
                    </div>
                    <div class="field">
                      <label>Equity Ownership</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="equityOwnerships"
                        selection
                        v-model="company.equityOwnership"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Proportion of Bonus/Profit Share invested in own
                        funds</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="proportionsInvestedInOwnFunds"
                        selection
                        v-model="company.proportionInvestedInOwnFunds"
                      />
                    </div>
                    <div class="field">
                      <label
                        >Bonus/Profit Share deferred (longest if multiple
                        periods)</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="bonusesDeferred"
                        selection
                        v-model="company.bonusDeferred"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Is there a company PA Trading Policy?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="companyPATradingPolicies"
                        selection
                        v-model="company.companyPATradingPolicy"
                      />
                    </div>
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
            
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>ADDITIONAL COMMENTS</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="field">
                    <label
                      >For any additional comments
                      <i>(Please reference the relevant field)</i></label
                    >
                    <textarea
                      :disabled="isEditScreen == false"
                      v-model="company.userCompanyCommentary"
                    ></textarea>
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
            </div>

            <div v-if="tabId == 2" class="ui form">             
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>ESG</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Are you a signatory of the United Nations "Principles
                        for Responsible Investment"? (UN PRI)</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="unPriSignatories"
                        selection
                        v-model="company.unPriSignatory"
                      />
                    </div>
                    <div class="field">
                      <label
                        >Do you support the principles of the Code for
                        Responsible Investing in South Africa? (CRISA)</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="crisaSignatories"
                        selection
                        v-model="company.crisaSignatory"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <transition name="fade">
                      <div class="field" v-if="company.unPriSignatory == 'Yes'">
                        <label>On what date was the UN PRI signed?</label>
                        <input
                          type="date"
                          :disabled="isEditScreen == false"
                          v-model="company.unPriSignatoryDate"
                        />
                      </div>
                    </transition>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Are you a member of any other responsible investing
                        initiative or organisation?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="riiMembers"
                        selection
                        v-model="company.riiMember"
                      />
                    </div>
                    <transition name="fade">
                      <div class="field" v-if="company.riiMember == 'Yes'">
                        <label>If Yes, please specify</label>
                        <input
                          type="text"
                          :disabled="isEditScreen == false"
                          v-model="company.riiMemberSpecify"
                        />
                      </div>
                    </transition>
                  </div>
                  <div class="field">
                    <label
                      >Does your company and/or investment process incorporate
                      any of the 17 United Nations Sustainable Development
                      Goals? If Yes, which ones specifically</label
                    >
                    <sui-dropdown
                      :disabled="isEditScreen == false"
                      fluid
                      single
                      :options="unSDGs"
                      selection
                      v-model="company.unSDG"
                    />
                  </div>
                  <transition name="fade">
                    <div
                      class="ui attached message"
                      v-if="company.unSDG == 'Yes'"
                    >
                      United Nations Sustainable Development Goals
                    </div>
                  </transition>
                  <transition name="fade">
                    <div
                      class="ui attached segment"
                      v-if="company.unSDG == 'Yes'"
                    >
                      <div class="four fields">
                        <div class="field">
                          <label>1. No poverty</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG1s"
                            selection
                            v-model="company.unSDG1"
                          />
                        </div>
                        <div class="field">
                          <label>2. Zero hunger</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG2s"
                            selection
                            v-model="company.unSDG2"
                          />
                        </div>
                        <div class="field">
                          <label>3. Good health and well-being</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG3s"
                            selection
                            v-model="company.unSDG3"
                          />
                        </div>
                        <div class="field">
                          <label>4. Quality education</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG4s"
                            selection
                            v-model="company.unSDG4"
                          />
                        </div>
                      </div>
                      <div class="four fields">
                        <div class="field">
                          <label>5. Gender equality</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG5s"
                            selection
                            v-model="company.unSDG5"
                          />
                        </div>
                        <div class="field">
                          <label>6. Clean water and sanitation</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG6s"
                            selection
                            v-model="company.unSDG6"
                          />
                        </div>
                        <div class="field">
                          <label>7. Affordable and clean energy</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG7s"
                            selection
                            v-model="company.unSDG7"
                          />
                        </div>
                        <div class="field">
                          <label>8. Decent work and economic growth</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG8s"
                            selection
                            v-model="company.unSDG8"
                          />
                        </div>
                      </div>
                      <div class="four fields">
                        <div class="field">
                          <label
                            >9. Industry, innovation and infrastructure</label
                          >
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG9s"
                            selection
                            v-model="company.unSDG9"
                          />
                        </div>
                        <div class="field">
                          <label>10. Reduced inequalities</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG10s"
                            selection
                            v-model="company.unSDG10"
                          />
                        </div>
                        <div class="field">
                          <label>11. Sustainable cities and communities</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG11s"
                            selection
                            v-model="company.unSDG11"
                          />
                        </div>
                        <div class="field">
                          <label
                            >12. Responsible consumption & production</label
                          >
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG12s"
                            selection
                            v-model="company.unSDG12"
                          />
                        </div>
                      </div>
                      <div class="four fields">
                        <div class="field">
                          <label>13. Climate action</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG13s"
                            selection
                            v-model="company.unSDG13"
                          />
                        </div>
                        <div class="field">
                          <label>14. Life below water</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG14s"
                            selection
                            v-model="company.unSDG14"
                          />
                        </div>
                        <div class="field">
                          <label>15. Life on land</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG15s"
                            selection
                            v-model="company.unSDG15"
                          />
                        </div>
                        <div class="field">
                          <label
                            >16. Peace and justice, strong institutions</label
                          >
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG16s"
                            selection
                            v-model="company.unSDG16"
                          />
                        </div>
                      </div>
                      <div class="four fields">
                        <div class="field">
                          <label>17. Partnerships for the goals</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="unSDG17s"
                            selection
                            v-model="company.unSDG17"
                          />
                        </div>
                      </div>
                    </div>
                  </transition>
                </sui-accordion-content>
              </sui-accordion>
              <br />

              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>EXCLUSIONS</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Do you exclude any companies involved in controversial
                        activities across all investments?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="controversialCompaniesExcludeds"
                        selection
                        v-model="company.controversialCompaniesExcluded"
                      />
                    </div>
                    <transition name="fade">
                      <div
                        class="field"
                        v-if="company.controversialCompaniesExcluded == 'Yes'"
                      >
                        <label>If Yes, what is the percentage of universe excluded?</label>
                        <input
                          type="number"
                          onkeyup="if(this.value<0)this.value=0"
                          onblur="if(this.value<0)this.value=0"
                          min="0"
                          :disabled="isEditScreen == false"
                          v-model="
                            company.controversialCompaniesExcludedPercentage
                          "
                        />
                      </div>
                    </transition>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Do you apply any specific minimum criteria regarding
                        environmental factors?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="environmentalsFactors"
                        selection
                        v-model="company.environmentalFactors"
                      />
                    </div>
                    <div class="field">
                      <label
                        >Do you apply any specific minimum criteria regarding
                        social factors?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="socialsFactors"
                        selection
                        v-model="company.socialFactors"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Do you apply any specific minimum criteria regarding
                        governance factors?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="governancesFactors"
                        selection
                        v-model="company.governanceFactors"
                      />
                    </div>
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />

              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>ESG IMPLEMENTATION</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <h4 class="ui attached message">ESG Scoring</h4>
                  <div class="ui attached segment">
                    <div class="two fields">
                      <div class="field">
                        <label
                          >Do you apply a proprietary ESG scoring system?</label
                        >
                        <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="proprietaryESGScorings"
                          selection
                          v-model="company.proprietaryESGScoring"
                        />
                      </div>
                      <div class="field">
                        <label>Do you use external ESG scoring services?</label>
                        <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="externalESGScorings"
                          selection
                          v-model="company.externalESGScoring"
                        />
                      </div>
                    </div>
                    <div class="two fields">
                      <div class="field">
                        <label>Do you apply a qualitative overlay?</label>
                        <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="qualitativeOverlays"
                          selection
                          v-model="company.qualitativeOverlay"
                        />
                      </div>
                    </div>
                  </div>

                  <h4 class="ui attached message">ESG Resources</h4>
                  <div class="ui attached segment">
                    <div class="two fields">
                      <div class="field">
                        <label>Do you have a dedicated ESG Research team?</label>
                        <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="eSGResearchTeams"
                          selection
                          v-model="company.esgResearchTeam"
                        />
                      </div>
                      <div class="field">
                        <label>Do you have any dedicated ESG products?</label>
                        <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="eSGsProducts"
                          selection
                          v-model="company.esgProducts"
                        />
                      </div>
                    </div>
                    <div class="two fields">
                      <div class="field">
                        <label
                          >Do you have a dedicated corporate governance/proxy
                          voting process?</label
                        >
                        <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="proxyVotings"
                          selection
                          v-model="company.proxyVoting"
                        />
                      </div>
                    </div>
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
            </div>

            <div v-if="tabId == 3" class="ui form">            
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>ACTIVE OWNERSHIP</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Do you have a corporate governance and proxy voting
                        policy?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="cGAndProxyVotings"
                        selection
                        v-model="company.cgAndProxyVoting"
                      />
                    </div>
                    <div class="field">
                      <label>Do you outsource proxy voting?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="proxyVotingsOutsourced"
                        selection
                        v-model="company.proxyVotingOutsourced"
                      />
                    </div>
                  </div>
                  <h4 class="header">If proxy voting is internal:</h4>
                  <div class="two fields">
                    <div class="field">
                      <label>Is your proxy voting process automated?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="proxyVotingsAutomated"
                        selection
                        v-model="company.proxyVotingAutomated"
                      />
                    </div>
                    <div class="field">
                      <label>Do you apply a qualitative overlay?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="proxyVotingsQualitative"
                        selection
                        v-model="company.proxyVotingQualitative"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Are portfolio managers involved in the final
                        decision?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="pMProxyVotingDecisions"
                        selection
                        v-model="company.pmProxyVotingDecision"
                      />
                    </div>
                    <div class="field">
                      <label
                        >If you intend voting against a resolution, do you
                        communicate this to the company?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="preProxyVotingEngagements"
                        selection
                        v-model="company.preProxyVotingEngagement"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >If you did vote against a resolution, do you
                        communicate your views to the company after
                        voting?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="postProxyVotingEngagements"
                        selection
                        v-model="company.postProxyVotingEngagement"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label
                      >What percentage of companies invested in have you
                      executed your voting rights over the past 12
                      months/calendar year? (irrespective of direction of
                      vote)</label
                    >
                    <input
                      type="number"
                      onkeyup="if(this.value<0)this.value=0"
                      onblur="if(this.value<0)this.value=0"
                      min="0"
                      :disabled="isEditScreen == false"
                      v-model="company.proxyVotingUsed"
                    />
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Do you lend securities out?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="securitiesLendings"
                        selection
                        v-model="company.securitiesLending"
                      />
                    </div>
                    <transition name="fade">
                      <div
                        class="field"
                        v-if="company.securitiesLending == 'Yes'"
                      >
                        <label
                          >If any securities are lent, do you recall your stock
                          in order to vote on a resolution?</label
                        >
                        <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="securitiesRecalleds"
                          selection
                          v-model="company.securitiesRecalled"
                        />
                      </div>
                    </transition>
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
            </div>
            <div v-if="tabId == 4" class="ui form">
              <table class="ui celled striped table">
                <thead>
                  <tr>
                    <th>FILE TYPE</th>
                    <th>DOWNLOAD</th>
                    <th v-if="isEditScreen">UPLOAD</th>
                    <th v-if="isEditScreen">DELETE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>BEE Certificate</td>
                    <td>
                      <a
                        href="#"
                        v-if="beeCertificateUrl != ''"
                        @click.prevent="downloadFile('BEE Certificate')"
                        class="ui teal basic button"
                        >Download</a
                      ><span v-else>n/a</span>
                    </td>
                    <td v-if="isEditScreen">
                      <input type="file" @change="onFileChangedBee" />
                    </td>

                    <td v-if="isEditScreen">
                      <a
                        href="#"
                        v-if="beeCertificateUrl != ''"
                        @click.prevent="deleteFile('BEE Certificate')"
                        class="ui red basic button"
                        >Delete</a
                      ><span v-else>n/a</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Team Organogram</td>
                    <td>
                      <a
                        href="#"
                        v-if="teamOrganogramUrl != ''"
                        @click.prevent="downloadFile('Team Organogram')"
                        class="ui teal basic button"
                        >Download </a
                      ><span v-else>n/a</span>
                    </td>
                    <td v-if="isEditScreen">
                      <input type="file" @change="onFileChangedOrganogram" />
                    </td>
                    <td v-if="isEditScreen">
                      <a
                        href="#"
                        v-if="teamOrganogramUrl != ''"
                        @click.prevent="deleteFile('Team Organogram')"
                        class="ui red basic button"
                        >Delete</a
                      ><span v-else>n/a</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Non-Disclosure Agreement</td>
                    <td>
                      <a
                        href="#"
                        v-if="nonDisclosureAgreementUrl != ''"
                        @click.prevent="
                          downloadFile('Non-Disclosure Agreement')
                        "
                        class="ui teal basic button"
                        >Download </a
                      ><span v-else>n/a</span>
                    </td>
                    <td v-if="isEditScreen">
                      <input type="file" @change="onFileChangedNda" />
                    </td>
                    <td v-if="isEditScreen">
                      <a
                        href="#"
                        v-if="nonDisclosureAgreementUrl != ''"
                        @click.prevent="deleteFile('Non-Disclosure Agreement')"
                        class="ui red basic button"
                        >Delete</a
                      ><span v-else>n/a</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="tabId == 5" class="ui form">
              <div class="ui attached segment">
                <span v-if="isAdminUser(user)">
                  <table class="ui celled striped table">
                    <thead>
                      <tr>
                        <th>FILE TYPE</th>
                        <th>DOWNLOAD</th>
                        <th>UPLOAD</th>
                        <th>SAVE</th>
                        <th>DELETE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Admin Company Comments</td>
                        <td>
                          <a
                            href="#"
                            v-if="adminCompanyCommentsUrl != ''"
                            @click.prevent="
                              downloadFile('Admin Company Comments')
                            "
                            class="ui teal basic button"
                            >Download</a
                          ><span v-else>n/a</span>
                        </td>
                        <td>
                          <input
                            type="file"
                            @change="onFileChangedAdminCompanyComments"
                          />
                        </td>
                        <td>
                          <sui-button
                            basic
                            primary
                            class="ui center floated"
                            @click.prevent="saveCompany"
                            >Upload</sui-button
                          >
                        </td>
                        <td>
                          <a
                            href="#"
                            v-if="adminCompanyCommentsUrl != ''"
                            @click.prevent="
                              deleteFile('Admin Company Comments')
                            "
                            class="ui red basic button"
                            >Delete</a
                          ><span v-else>n/a</span>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>Operational Due Diligence Document</td>
                        <td>
                          <a
                            href="#"
                            v-if="dueDiligenceDocumentUrl != ''"
                            @click.prevent="
                              downloadFile('Due Diligence Document')
                            "
                            class="ui teal basic button"
                            >Download</a
                          ><span v-else>n/a</span>
                        </td>
                        <td>
                          <input
                            type="file"
                            @change="onFileChangedDueDiligenceDocument"
                          />
                        </td>
                        <td>
                          <sui-button
                            basic
                            primary
                            class="ui center floated"
                            @click.prevent="saveCompany"
                            >Upload</sui-button
                          >
                        </td>
                        <td>
                          <a
                            href="#"
                            v-if="dueDiligenceDocumentUrl != ''"
                            @click.prevent="
                              deleteFile('Due Diligence Document')
                            "
                            class="ui red basic button"
                            >Delete</a
                          ><span v-else>n/a</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <div class="field" >
                    <label>KID - Firm Overview</label>
                    <textarea 
                      v-model="company.companyCommentary"
                      @keyup="companyCommentaryChanged"
                    ></textarea>
                  </div>
                  <div
                    class="ui blue small button"
                    @click.prevent="saveCompanyCommentary"
                    v-if="saveCompanyCommentaryButton"
                  >
                    Save
                  </div>
                  <div
                    class="ui attached message"
                    v-else-if="company.companyCommentaryUpdatedBy"
                  >
                    Most recently updated by
                    {{ company.companyCommentaryUpdatedBy }} on the
                    {{ friendlyDate(company.companyCommentaryUpdatedAsAt) }}
                  </div>
                </span>
                <br />
                <sui-button
                  basic
                  positive
                  :loading="loadingExcel"
                  @click.prevent="downloadCommentsExcel"
                  >Download Comments Excel</sui-button
                >
                <sui-button
                  basic
                  color="violet"
                  class="ui right floated"
                  :loading="loadingExcelLog"
                  @click.prevent="downloadCompaniesExcelLog"
                  >Excel Downloads Log</sui-button
                >
              </div>
            </div>
            <br />

            <sui-button
              basic
              positive
              class="ui right floated"
              v-if="isEditScreen == false  && success == false && failed == false"
              @click.prevent="isEditScreen = true"
              >Edit</sui-button
            >
            <sui-button
              basic
              primary
              class="ui right floated"
              v-if="isEditScreen != false && success == false && failed == false"
              @click.prevent="saveCompany"
              >Save</sui-button
            >
            <br/>
              <br/>
              <div class="ui positive message" v-if="success">
                  <i class="close icon" @click.prevent="success = false; isEditScreen = false"></i>
                  <div class="header">Success</div>
                  <p>
                      Click <router-link to="../companies">here</router-link> to go
                      back to all companies
                  </p>
              </div>
              <div class="ui negative message" v-if="failed">
                  <i class="close icon" @click.prevent="success = false; isEditScreen = false"></i>
                  <div class="header">Failed</div>
                  <p>
                      Click <router-link to="../../companies">here</router-link> to go
                      back to all companies or try again.
                  </p>
              </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCompany,
  createCompany,
  uploadFile,
  fileDownload,
  fileDelete,
  BaseUrl,
  saveCompanyCommentary,
  downloadCompaniesCommentsExcel,
  downloadCompaniesExcelLog
} from "@/gateway";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import numeral from "numeral";
export default {
  data() {
    return {
      success: false,
      failed : false,
      loadingExcel: false,
      loadingExcelLog: false,
      isEditScreen: false,
      popiNoticeChecked: false,
      open: true,
      id: 0,
      company: null,
      name: "",
      companyCommentary: "",
      userCompanyCommentary: "",
      progressPercent: 0,
      tabId: 1,
      // file uploads
      selectedFile: null,
      selectedFileBee: null,
      selectedFileOrganogram: null,
      selectedFileNda: null,
      selectedFileAdminCompanyComments: null,
      selectedFileDueDiligenceDocument: null,
      newOwnershipDetails: {
        ownerName: "",
        ownershipPercentage: 0.0
      },
      newCompanyCios: {
        cioName: "",
        cioStartDate: "",
        cioEndDate: ""
      },
      newCurrentInvestmentTeam: {
        name: "",
        role: "",
        qualification: "",
        companyStartDate: "",
        industryStartDate: ""
      },
      investmentTeamLocation: [],
      //   //dropdown -- Single location, Multiple locations
      investmentTeamLocations: [
        { value: "Single Location", text: "Single Location" },
        { value: "Multiple Locations", text: "Multiple Locations" }
      ],
      ownershipStructure: [],
      ownershipStructures: [
        { value: "Private Company", text: "Private Company" },
        { value: "Listed Company", text: "Listed Company" },
        { value: "Life Company owned", text: "Life Company owned" },
        { value: "100% Parent-Owned", text: "100% Parent-Owned" }
      ],
      companyApproach: [],
      companyApproaches: [
        { value: "Houseview", text: "Houseview" },
        { value: "Multi-Strat/Franchise", text: "Multi-Strat/Franchise" },
        {
          value: "Combination of Houseview and Multi-Strat",
          text: "Combination of Houseview and Multi-Strat"
        }
      ],
      companyAumCurrency: [],
      companyAumCurrencies: [
        { value: "ZAR", text: "ZAR" },
        { value: "USD", text: "USD" },
        { value: "EUR", text: "EUR" },
        { value: "GBP", text: "GBP" },
        { value: "JPY", text: "JPY" },
        { value: "RMB", text: "RMB" }
      ],
      bondsAumCurrent: 0,
      bondsAum1: 0,
      bondsAum2: 0,
      bondsAum3: 0,
      equityAumCurrent: 0,
      equityAum1: 0,
      equityAum2: 0,
      equityAum3: 0,
      incomeAumCurrent: 0,
      incomeAum1: 0,
      incomeAum2: 0,
      incomeAum3: 0,
      moneyMarketAumCurrent: 0,
      moneyMarketAum1: 0,
      moneyMarketAum2: 0,
      moneyMarketAum3: 0,
      multiAssetAumCurrent: 0,
      multiAssetAum1: 0,
      multiAssetAum2: 0,
      multiAssetAum3: 0,
      propertyAumCurrent: 0,
      propertyAum1: 0,
      propertyAum2: 0,
      propertyAum3: 0,
      hedgeAumCurrent: 0,
      hedgeAum1: 0,
      hedgeAum2: 0,
      hedgeAum3: 0,
      otherAumCurrent: 0,
      otherAum1: 0,
      otherAum2: 0,
      otherAum3: 0,
      salary: [],
      salaries: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      bonus: [],
      bonuses: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      profitShare: [],
      profitShares: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      equityOwnership: [],
      equityOwnerships: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      proportionInvestedInOwnFunds: [],
      proportionsInvestedInOwnFunds: [
        { value: "Not a requirement", text: "Not a requirement" },
        { value: "< 33%", text: "< 33%" },
        { value: "33% - 67%", text: "33% - 67%" },
        { value: "> 67%", text: "> 67%" }
      ],
      bonusDeferred: [],
      bonusesDeferred: [
        { value: "Not a requirement", text: "Not a requirement" },
        { value: "Yes (up to 12 months)", text: "Yes (up to 12 months)" },
        { value: "Yes (up to 3 years)", text: "Yes (up to 3 years)" },
        { value: "Yes (up to 5 years)", text: "Yes (up to 5 years)" },
        { value: "Yes (over 5 years)", text: "Yes (over 5 years)" }
      ],
      companyPATradingPolicy: [],
      companyPATradingPolicies: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unPriSignatory: [],
      unPriSignatories: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      crisaSignatory: [],
      crisaSignatories: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }, 
          { value: "N/A", text: "N/A"}
      ],
      riiMember: [],
      riiMembers: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG: [],
      unSDGs: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG1: [],
      unSDG1s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG2: [],
      unSDG2s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG3: [],
      unSDG3s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG4: [],
      unSDG4s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG5: [],
      unSDG5s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG6: [],
      unSDG6s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG7: [],
      unSDG7s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG8: [],
      unSDG8s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG9: [],
      unSDG9s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG10: [],
      unSDG10s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG11: [],
      unSDG11s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG12: [],
      unSDG12s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG13: [],
      unSDG13s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG14: [],
      unSDG14s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG15: [],
      unSDG15s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG16: [],
      unSDG16s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      unSDG17: [],
      unSDG17s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      controversialCompaniesExcluded: [],
      controversialCompaniesExcludeds: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      environmentalFactors: [],
      environmentalsFactors: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      socialFactors: [],
      socialsFactors: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      governanceFactors: [],
      governancesFactors: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      proprietaryESGScoring: [],
      proprietaryESGScorings: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      externalESGScoring: [],
      externalESGScorings: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      qualitativeOverlay: [],
      qualitativeOverlays: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      esgResearchTeam: [],
      eSGResearchTeams: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      esgProducts: [],
      eSGsProducts: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      proxyVoting: [],
      proxyVotings: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      cGAndProxyVoting: [],
      cGAndProxyVotings: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      proxyVotingOutsourced: [],
      proxyVotingsOutsourced: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      proxyVotingAutomated: [],
      proxyVotingsAutomated: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      proxyVotingQualitative: [],
      proxyVotingsQualitative: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      pMProxyVotingDecision: [],
      pMProxyVotingDecisions: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      preProxyVotingEngagement: [],
      preProxyVotingEngagements: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      postProxyVotingEngagement: [],
      postProxyVotingEngagements: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      securitiesLending: [],
      securitiesLendings: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      securitiesRecalled: [],
      securitiesRecalleds: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      saveCompanyCommentaryButton: false
    };
  },
  computed: {
    progressPercentage() {
      var total = 0;
      if (this.company.companyApproach) {
        total += 10;
      }
      if (this.company.companyCios) {
        total += 10;
      }
      if (this.company.retailAssetsPercentage) {
        total += 10;
      }
      if (this.company.companyPATradingPolicy) {
        total += 10;
      }
      if (this.company.sellDiscipline) {
        total += 10;
      }
      if (this.company.unSDG) {
        total += 10;
      }
      if (this.company.governanceFactors) {
        total += 10;
      }
      if (this.company.proxyVoting) {
        total += 10;
      }
      if (this.company.securitiesLending) {
        total += 10;
      }
      if (this.company.documentUploads.length > 0) {
        total += 10;
      }
      return total;
    },
    beeCertificateUrl() {
      var url = "";

      if (this.company.documentUploads) {
        for (var i = 0; i < this.company.documentUploads.length; i++) {
          if (this.company.documentUploads[i].fileType == "BEE Certificate") {
            return this.company.documentUploads[i].fileName;
          }
        }
      }
      return url;
    },
    teamOrganogramUrl() {
      var url = "";
      if (this.company.documentUploads) {
        for (var i = 0; i < this.company.documentUploads.length; i++) {
          if (this.company.documentUploads[i].fileType == "Team Organogram") {
            return this.company.documentUploads[i].fileName;
          }
        }
      }
      return url;
    },
    nonDisclosureAgreementUrl() {
      var url = "";
      if (this.company.documentUploads) {
        for (var i = 0; i < this.company.documentUploads.length; i++) {
          if (
            this.company.documentUploads[i].fileType ==
            "Non-Disclosure Agreement"
          ) {
            return this.company.documentUploads[i].fileName;
          }
        }
      }
      return url;
    },
    adminCompanyCommentsUrl() {
      var url = "";
      if (this.company.documentUploads) {
        for (var i = 0; i < this.company.documentUploads.length; i++) {
          if (
            this.company.documentUploads[i].fileType == "Admin Company Comments"
          ) {
            return this.company.documentUploads[i].fileName;
          }
        }
      }
      return url;
    },
    dueDiligenceDocumentUrl() {
      var url = "";
      if (this.company.documentUploads) {
        for (var i = 0; i < this.company.documentUploads.length; i++) {
          if (
            this.company.documentUploads[i].fileType == "Due Diligence Document"
          ) {
            return this.company.documentUploads[i].fileName;
          }
        }
      }
      return url;
    },
    totalAumCurrent() {
      let currentTotal =
        parseFloat(this.company.bondsAumCurrent) +
        parseFloat(this.company.equityAumCurrent) +
        parseFloat(this.company.incomeAumCurrent) +
        parseFloat(this.company.moneyMarketAumCurrent) +
        parseFloat(this.company.multiAssetAumCurrent) +
        parseFloat(this.company.propertyAumCurrent) +
        parseFloat(this.company.hedgeAumCurrent) +
        parseFloat(this.company.otherAumCurrent);

      return currentTotal;
    },
    totalAum1() {
      let year1Total =
        parseFloat(this.company.bondsAum1) +
        parseFloat(this.company.equityAum1) +
        parseFloat(this.company.incomeAum1) +
        parseFloat(this.company.moneyMarketAum1) +
        parseFloat(this.company.multiAssetAum1) +
        parseFloat(this.company.propertyAum1) +
        parseFloat(this.company.hedgeAum1) +
        parseFloat(this.company.otherAum1);

      return year1Total;
    },
    totalAum2() {
      let year2Total =
        parseFloat(this.company.bondsAum2) +
        parseFloat(this.company.equityAum2) +
        parseFloat(this.company.incomeAum2) +
        parseFloat(this.company.moneyMarketAum2) +
        parseFloat(this.company.multiAssetAum2) +
        parseFloat(this.company.propertyAum2) +
        parseFloat(this.company.hedgeAum2) +
        parseFloat(this.company.otherAum2);

      return year2Total;
    },
    totalAum3() {
      let year3Total =
        parseFloat(this.company.bondsAum3) +
        parseFloat(this.company.equityAum3) +
        parseFloat(this.company.incomeAum3) +
        parseFloat(this.company.moneyMarketAum3) +
        parseFloat(this.company.multiAssetAum3) +
        parseFloat(this.company.propertyAum3) +
        parseFloat(this.company.hedgeAum3) +
        parseFloat(this.company.otherAum3);

      return year3Total;
    },
    user() {
      if (this.$store.state.user) return this.$store.state.user;
      else return "";
    },
    ...mapState,
    ...mapGetters(["isAuthenticated"])
  },

  methods: {
    friendlyDate(val) {
      return moment(val).format("Do [of] MMMM yyyy");
    },
    companyCommentaryChanged() {
      this.saveCompanyCommentaryButton = true;
    },
    async saveCompanyCommentary() {
      const response = await saveCompanyCommentary({
        type: "companyCommentary",
        value: this.company.companyCommentary,
        id: this.company.id
      });
      if (response) {
        if (response.result == true) {
          this.saveCompanyCommentaryButton = false;
          this.company.companyCommentary = response.company.companyCommentary;
        }
      }
    },
    isAdminUser(user) {
      if (user.roles) {
        if (user.roles.includes("Admin")) {
          return true;
        } else return false;
      } else {
        return false;
      }
    },
    toggle() {
      this.open = !this.open;
    },
    async downloadCommentsExcel() {
      this.loadingExcel = true;
      const response = await downloadCompaniesCommentsExcel();
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data);
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        this.loadingExcel = false;
        link.click();
      }
    },
    async downloadCompaniesExcelLog() {
      this.loadingExcelLog = true;
      const response = await downloadCompaniesExcelLog();
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data);
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        this.loadingExcelLog = false;
        link.click();
      }
    },
    formatNr(val, fmt) {
      if (fmt) return numeral(val).format(fmt);
      else return numeral(val).format("0,0.0a");
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    async downloadFile(fileType) {
      const response = await fileDownload({
        fileType: fileType,
        entityType: "Company",
        entityId: this.company.id
      });
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data);
        console.log("response", new Blob([byteArray]));
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: "application/octet-stream" })
        );
        console.log("url", url);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    addOwnershipDetail(newOwner) {
      this.company.ownershipDetailsPercentages.push(newOwner);
      this.newOwnershipDetails = {
        ownerName: "",
        ownershipPercentage: ""
      };
    },
    removeOwnershipDetailsPercentage(index) {
      this.company.ownershipDetailsPercentages.splice(index, 1);
    },
    addCompanycCio(newCio) {
      this.company.companyCios.push(newCio);
      this.newCompanyCios = {
        cioName: "",
        cioStartDate: "",
        cioEndDate: ""
      };
    },
    removeCompanyCio(index) {
      this.company.companyCios.splice(index, 1);
    },
    addInvestmentTeams(newTeam) {
      this.company.currentInvestmentTeam.push(newTeam);
      this.newCurrentInvestmentTeam = {
        name: "",
        role: "",
        qualification: "",
        companyStartDate: "",
        industryStartDate: ""
      };
    },
    removeInvestmentTeam(index) {
      this.company.currentInvestmentTeam.splice(index, 1);
    },
    onFileChanged(event) {
      this.selectedFile = event.target.files[0];
    },
    onFileChangedBee(event) {
      this.selectedFileBee = event.target.files[0];
    },
    onFileChangedOrganogram(event) {
      this.selectedFileOrganogram = event.target.files[0];
    },
    onFileChangedNda(event) {
      this.selectedFileNda = event.target.files[0];
    },
    onFileChangedAdminCompanyComments(event) {
      this.selectedFileAdminCompanyComments = event.target.files[0];
    },
    onFileChangedDueDiligenceDocument(event) {
      this.selectedFileDueDiligenceDocument = event.target.files[0];
    },
    async onUpload() {
      if (this.selectedFileBee) {
        const r = await uploadFile({
          selectedFile: this.selectedFileBee,
          entityType: "Company",
          entityId: this.company.id,
          fileType: "BEE Certificate"
        });
      }
      if (this.selectedFileOrganogram) {
        const r = await uploadFile({
          selectedFile: this.selectedFileOrganogram,
          entityType: "Company",
          entityId: this.company.id,
          fileType: "Team Organogram"
        });
      }
      if (this.selectedFileNda) {
        const r = await uploadFile({
          selectedFile: this.selectedFileNda,
          entityType: "Company",
          entityId: this.company.id,
          fileType: "Non-Disclosure Agreement"
        });
      }
      if (this.selectedFileAdminCompanyComments) {
        const r = await uploadFile({
          selectedFile: this.selectedFileAdminCompanyComments,
          entityType: "Company",
          entityId: this.company.id,
          fileType: "Admin Company Comments"
        });
      }
      if (this.selectedFileDueDiligenceDocument) {
        const r = await uploadFile({
          selectedFile: this.selectedFileDueDiligenceDocument,
          entityType: "Company",
          entityId: this.company.id,
          fileType: "Due Diligence Document"
        });
      }
    },
    async deleteFile(fileType) {
      const response = await fileDelete({
        fileType: fileType,
        entityType: "Company",
        entityId: this.company.id
      });
      if (response) this.init();
    },
    async saveCompany() {
      const response = await createCompany({ company: this.company });
      if (response) {
        this.success = true;
        this.open = !this.open;
          this.isEditScreen = false;
        await this.onUpload();
      }
      else
      {
          this.failed = true;
      }
    },
    async init() {
      const response = await getCompany({ id: this.id });
      if (response) this.company = response.result;
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.id == 0) this.isEditScreen = true;
    this.init();
  }
};
</script>
