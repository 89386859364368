/* Options:
Date: 2023-06-07 12:38:44
Version: 5.60
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:62001

//GlobalNamespace:
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion:
//AddDescriptionAsComments: True
//IncludeTypes:
//ExcludeTypes:
//DefaultImports:
*/
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// @DataContract
var ResponseError = /** @class */ (function () {
    function ResponseError(init) {
        Object.assign(this, init);
    }
    return ResponseError;
}());
export { ResponseError };
// @DataContract
var ResponseStatus = /** @class */ (function () {
    function ResponseStatus(init) {
        Object.assign(this, init);
    }
    return ResponseStatus;
}());
export { ResponseStatus };
var Auth0User = /** @class */ (function () {
    function Auth0User(init) {
        Object.assign(this, init);
    }
    return Auth0User;
}());
export { Auth0User };
var OwnershipDetails = /** @class */ (function () {
    function OwnershipDetails(init) {
        Object.assign(this, init);
    }
    return OwnershipDetails;
}());
export { OwnershipDetails };
var InvestmentTeam = /** @class */ (function () {
    function InvestmentTeam(init) {
        Object.assign(this, init);
    }
    return InvestmentTeam;
}());
export { InvestmentTeam };
var CompanyCio = /** @class */ (function () {
    function CompanyCio(init) {
        Object.assign(this, init);
    }
    return CompanyCio;
}());
export { CompanyCio };
var DocumentUpload = /** @class */ (function () {
    function DocumentUpload(init) {
        Object.assign(this, init);
    }
    return DocumentUpload;
}());
export { DocumentUpload };
var Company = /** @class */ (function () {
    function Company(init) {
        Object.assign(this, init);
    }
    return Company;
}());
export { Company };
var DecisionMaker = /** @class */ (function () {
    function DecisionMaker(init) {
        Object.assign(this, init);
    }
    return DecisionMaker;
}());
export { DecisionMaker };
var FundShareClass = /** @class */ (function () {
    function FundShareClass(init) {
        Object.assign(this, init);
    }
    return FundShareClass;
}());
export { FundShareClass };
var FundCommonProperties = /** @class */ (function () {
    function FundCommonProperties(init) {
        Object.assign(this, init);
    }
    return FundCommonProperties;
}());
export { FundCommonProperties };
var FundView = /** @class */ (function (_super) {
    __extends(FundView, _super);
    function FundView(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return FundView;
}(FundCommonProperties));
export { FundView };
var ManagerReportComponent = /** @class */ (function () {
    function ManagerReportComponent(init) {
        Object.assign(this, init);
    }
    return ManagerReportComponent;
}());
export { ManagerReportComponent };
var ManagerPage = /** @class */ (function () {
    function ManagerPage(init) {
        Object.assign(this, init);
    }
    return ManagerPage;
}());
export { ManagerPage };
var ManagerReportTemplate = /** @class */ (function () {
    function ManagerReportTemplate(init) {
        Object.assign(this, init);
    }
    return ManagerReportTemplate;
}());
export { ManagerReportTemplate };
var ReportColumn = /** @class */ (function () {
    function ReportColumn(init) {
        Object.assign(this, init);
    }
    return ReportColumn;
}());
export { ReportColumn };
var KeyFactSettingsComponent = /** @class */ (function (_super) {
    __extends(KeyFactSettingsComponent, _super);
    function KeyFactSettingsComponent(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return KeyFactSettingsComponent;
}(ManagerReportComponent));
export { KeyFactSettingsComponent };
var UserAuth = /** @class */ (function () {
    function UserAuth(init) {
        Object.assign(this, init);
    }
    return UserAuth;
}());
export { UserAuth };
var CustomUserAuth = /** @class */ (function (_super) {
    __extends(CustomUserAuth, _super);
    function CustomUserAuth(init) {
        var _this = _super.call(this, init) || this;
        Object.assign(_this, init);
        return _this;
    }
    return CustomUserAuth;
}(UserAuth));
export { CustomUserAuth };
var UpdateUserPasswordResponse = /** @class */ (function () {
    function UpdateUserPasswordResponse(init) {
        Object.assign(this, init);
    }
    return UpdateUserPasswordResponse;
}());
export { UpdateUserPasswordResponse };
var SearchAuth0UsersResponse = /** @class */ (function () {
    function SearchAuth0UsersResponse(init) {
        Object.assign(this, init);
    }
    return SearchAuth0UsersResponse;
}());
export { SearchAuth0UsersResponse };
var DeleteAuth0UserResponse = /** @class */ (function () {
    function DeleteAuth0UserResponse(init) {
        Object.assign(this, init);
    }
    return DeleteAuth0UserResponse;
}());
export { DeleteAuth0UserResponse };
var AddUserResponse = /** @class */ (function () {
    function AddUserResponse(init) {
        Object.assign(this, init);
    }
    return AddUserResponse;
}());
export { AddUserResponse };
var DownloadCompaniesToExcelResponse = /** @class */ (function () {
    function DownloadCompaniesToExcelResponse(init) {
        Object.assign(this, init);
    }
    return DownloadCompaniesToExcelResponse;
}());
export { DownloadCompaniesToExcelResponse };
var DownloadFundsToExcelResponse = /** @class */ (function () {
    function DownloadFundsToExcelResponse(init) {
        Object.assign(this, init);
    }
    return DownloadFundsToExcelResponse;
}());
export { DownloadFundsToExcelResponse };
var DownloadCompaniesCommentsToExcelResponse = /** @class */ (function () {
    function DownloadCompaniesCommentsToExcelResponse(init) {
        Object.assign(this, init);
    }
    return DownloadCompaniesCommentsToExcelResponse;
}());
export { DownloadCompaniesCommentsToExcelResponse };
var DownloadFundsCommentsToExcelResponse = /** @class */ (function () {
    function DownloadFundsCommentsToExcelResponse(init) {
        Object.assign(this, init);
    }
    return DownloadFundsCommentsToExcelResponse;
}());
export { DownloadFundsCommentsToExcelResponse };
var DownloadCompaniesExcelLogResponse = /** @class */ (function () {
    function DownloadCompaniesExcelLogResponse(init) {
        Object.assign(this, init);
    }
    return DownloadCompaniesExcelLogResponse;
}());
export { DownloadCompaniesExcelLogResponse };
var DownloadFundsExcelLogResponse = /** @class */ (function () {
    function DownloadFundsExcelLogResponse(init) {
        Object.assign(this, init);
    }
    return DownloadFundsExcelLogResponse;
}());
export { DownloadFundsExcelLogResponse };
var GetCompaniesResponse = /** @class */ (function () {
    function GetCompaniesResponse(init) {
        Object.assign(this, init);
    }
    return GetCompaniesResponse;
}());
export { GetCompaniesResponse };
var GetCompanyResponse = /** @class */ (function () {
    function GetCompanyResponse(init) {
        Object.assign(this, init);
    }
    return GetCompanyResponse;
}());
export { GetCompanyResponse };
var CreateOrUpdateCompanyResponse = /** @class */ (function () {
    function CreateOrUpdateCompanyResponse(init) {
        Object.assign(this, init);
    }
    return CreateOrUpdateCompanyResponse;
}());
export { CreateOrUpdateCompanyResponse };
var DeleteCompanyResponse = /** @class */ (function () {
    function DeleteCompanyResponse(init) {
        Object.assign(this, init);
    }
    return DeleteCompanyResponse;
}());
export { DeleteCompanyResponse };
var UpdateCompanyCommentaryResponse = /** @class */ (function () {
    function UpdateCompanyCommentaryResponse(init) {
        Object.assign(this, init);
    }
    return UpdateCompanyCommentaryResponse;
}());
export { UpdateCompanyCommentaryResponse };
var SendMailTemplateResponse = /** @class */ (function () {
    function SendMailTemplateResponse(init) {
        Object.assign(this, init);
    }
    return SendMailTemplateResponse;
}());
export { SendMailTemplateResponse };
var GetFundsResponse = /** @class */ (function () {
    function GetFundsResponse(init) {
        Object.assign(this, init);
    }
    return GetFundsResponse;
}());
export { GetFundsResponse };
var GetFundResponse = /** @class */ (function () {
    function GetFundResponse(init) {
        Object.assign(this, init);
    }
    return GetFundResponse;
}());
export { GetFundResponse };
var CreateOrUpdateFundResponse = /** @class */ (function () {
    function CreateOrUpdateFundResponse(init) {
        Object.assign(this, init);
    }
    return CreateOrUpdateFundResponse;
}());
export { CreateOrUpdateFundResponse };
var DeleteFundResponse = /** @class */ (function () {
    function DeleteFundResponse(init) {
        Object.assign(this, init);
    }
    return DeleteFundResponse;
}());
export { DeleteFundResponse };
var UpdateFundCommentaryResponse = /** @class */ (function () {
    function UpdateFundCommentaryResponse(init) {
        Object.assign(this, init);
    }
    return UpdateFundCommentaryResponse;
}());
export { UpdateFundCommentaryResponse };
var GetReportTemplateResponse = /** @class */ (function () {
    function GetReportTemplateResponse(init) {
        Object.assign(this, init);
    }
    return GetReportTemplateResponse;
}());
export { GetReportTemplateResponse };
var GetFundPdfResponse = /** @class */ (function () {
    function GetFundPdfResponse(init) {
        Object.assign(this, init);
    }
    return GetFundPdfResponse;
}());
export { GetFundPdfResponse };
var GetDummyTypesResponse = /** @class */ (function () {
    function GetDummyTypesResponse(init) {
        Object.assign(this, init);
    }
    return GetDummyTypesResponse;
}());
export { GetDummyTypesResponse };
var SessionInfoResponse = /** @class */ (function () {
    function SessionInfoResponse(init) {
        Object.assign(this, init);
    }
    return SessionInfoResponse;
}());
export { SessionInfoResponse };
var DeleteResponse = /** @class */ (function () {
    function DeleteResponse(init) {
        Object.assign(this, init);
    }
    return DeleteResponse;
}());
export { DeleteResponse };
var UploadResponse = /** @class */ (function () {
    function UploadResponse(init) {
        Object.assign(this, init);
    }
    return UploadResponse;
}());
export { UploadResponse };
var DownloadResponse = /** @class */ (function () {
    function DownloadResponse(init) {
        Object.assign(this, init);
    }
    return DownloadResponse;
}());
export { DownloadResponse };
var GetUserInfoResponse = /** @class */ (function () {
    function GetUserInfoResponse(init) {
        Object.assign(this, init);
    }
    return GetUserInfoResponse;
}());
export { GetUserInfoResponse };
var GetUsersResponse = /** @class */ (function () {
    function GetUsersResponse(init) {
        Object.assign(this, init);
    }
    return GetUsersResponse;
}());
export { GetUsersResponse };
var CreateUserResponse = /** @class */ (function () {
    function CreateUserResponse(init) {
        Object.assign(this, init);
    }
    return CreateUserResponse;
}());
export { CreateUserResponse };
var UpdateUserResponse = /** @class */ (function () {
    function UpdateUserResponse(init) {
        Object.assign(this, init);
    }
    return UpdateUserResponse;
}());
export { UpdateUserResponse };
var DeleteUserResponse = /** @class */ (function () {
    function DeleteUserResponse(init) {
        Object.assign(this, init);
    }
    return DeleteUserResponse;
}());
export { DeleteUserResponse };
var AssignRoleResponse = /** @class */ (function () {
    function AssignRoleResponse(init) {
        Object.assign(this, init);
    }
    return AssignRoleResponse;
}());
export { AssignRoleResponse };
var UnAssignAllPermissionsResponse = /** @class */ (function () {
    function UnAssignAllPermissionsResponse(init) {
        Object.assign(this, init);
    }
    return UnAssignAllPermissionsResponse;
}());
export { UnAssignAllPermissionsResponse };
var UnAssignRoleResponse = /** @class */ (function () {
    function UnAssignRoleResponse(init) {
        Object.assign(this, init);
    }
    return UnAssignRoleResponse;
}());
export { UnAssignRoleResponse };
// @DataContract
var AuthenticateResponse = /** @class */ (function () {
    function AuthenticateResponse(init) {
        Object.assign(this, init);
    }
    return AuthenticateResponse;
}());
export { AuthenticateResponse };
// @DataContract
var AssignRolesResponse = /** @class */ (function () {
    function AssignRolesResponse(init) {
        Object.assign(this, init);
    }
    return AssignRolesResponse;
}());
export { AssignRolesResponse };
// @DataContract
var UnAssignRolesResponse = /** @class */ (function () {
    function UnAssignRolesResponse(init) {
        Object.assign(this, init);
    }
    return UnAssignRolesResponse;
}());
export { UnAssignRolesResponse };
// @DataContract
var ConvertSessionToTokenResponse = /** @class */ (function () {
    function ConvertSessionToTokenResponse(init) {
        Object.assign(this, init);
    }
    return ConvertSessionToTokenResponse;
}());
export { ConvertSessionToTokenResponse };
// @DataContract
var GetAccessTokenResponse = /** @class */ (function () {
    function GetAccessTokenResponse(init) {
        Object.assign(this, init);
    }
    return GetAccessTokenResponse;
}());
export { GetAccessTokenResponse };
// @Route("/user/management/password")
var UpdateUserPassword = /** @class */ (function () {
    function UpdateUserPassword(init) {
        Object.assign(this, init);
    }
    UpdateUserPassword.prototype.createResponse = function () { return new UpdateUserPasswordResponse(); };
    UpdateUserPassword.prototype.getTypeName = function () { return 'UpdateUserPassword'; };
    return UpdateUserPassword;
}());
export { UpdateUserPassword };
// @Route("/user/management/search")
var SearchAuth0Users = /** @class */ (function () {
    function SearchAuth0Users(init) {
        Object.assign(this, init);
    }
    SearchAuth0Users.prototype.createResponse = function () { return new SearchAuth0UsersResponse(); };
    SearchAuth0Users.prototype.getTypeName = function () { return 'SearchAuth0Users'; };
    return SearchAuth0Users;
}());
export { SearchAuth0Users };
// @Route("/user/management/search")
var DeleteAuth0User = /** @class */ (function () {
    function DeleteAuth0User(init) {
        Object.assign(this, init);
    }
    DeleteAuth0User.prototype.createResponse = function () { return new DeleteAuth0UserResponse(); };
    DeleteAuth0User.prototype.getTypeName = function () { return 'DeleteAuth0User'; };
    return DeleteAuth0User;
}());
export { DeleteAuth0User };
// @Route("/user/management/create")
var AddUser = /** @class */ (function () {
    function AddUser(init) {
        Object.assign(this, init);
    }
    AddUser.prototype.createResponse = function () { return new AddUserResponse(); };
    AddUser.prototype.getTypeName = function () { return 'AddUser'; };
    return AddUser;
}());
export { AddUser };
// @Route("/companies/download/excel")
var DownloadCompaniesToExcel = /** @class */ (function () {
    function DownloadCompaniesToExcel(init) {
        Object.assign(this, init);
    }
    DownloadCompaniesToExcel.prototype.createResponse = function () { return new DownloadCompaniesToExcelResponse(); };
    DownloadCompaniesToExcel.prototype.getTypeName = function () { return 'DownloadCompaniesToExcel'; };
    return DownloadCompaniesToExcel;
}());
export { DownloadCompaniesToExcel };
// @Route("/funds/download/excel")
var DownloadFundsToExcel = /** @class */ (function () {
    function DownloadFundsToExcel(init) {
        Object.assign(this, init);
    }
    DownloadFundsToExcel.prototype.createResponse = function () { return new DownloadFundsToExcelResponse(); };
    DownloadFundsToExcel.prototype.getTypeName = function () { return 'DownloadFundsToExcel'; };
    return DownloadFundsToExcel;
}());
export { DownloadFundsToExcel };
// @Route("/companies/comments/download/excel")
var DownloadCompaniesCommentsToExcel = /** @class */ (function () {
    function DownloadCompaniesCommentsToExcel(init) {
        Object.assign(this, init);
    }
    DownloadCompaniesCommentsToExcel.prototype.createResponse = function () { return new DownloadCompaniesCommentsToExcelResponse(); };
    DownloadCompaniesCommentsToExcel.prototype.getTypeName = function () { return 'DownloadCompaniesCommentsToExcel'; };
    return DownloadCompaniesCommentsToExcel;
}());
export { DownloadCompaniesCommentsToExcel };
// @Route("/funds/comments/download/excel")
var DownloadFundsCommentsToExcel = /** @class */ (function () {
    function DownloadFundsCommentsToExcel(init) {
        Object.assign(this, init);
    }
    DownloadFundsCommentsToExcel.prototype.createResponse = function () { return new DownloadFundsCommentsToExcelResponse(); };
    DownloadFundsCommentsToExcel.prototype.getTypeName = function () { return 'DownloadFundsCommentsToExcel'; };
    return DownloadFundsCommentsToExcel;
}());
export { DownloadFundsCommentsToExcel };
// @Route("/companies/download/log")
var DownloadCompaniesExcelLog = /** @class */ (function () {
    function DownloadCompaniesExcelLog(init) {
        Object.assign(this, init);
    }
    DownloadCompaniesExcelLog.prototype.createResponse = function () { return new DownloadCompaniesExcelLogResponse(); };
    DownloadCompaniesExcelLog.prototype.getTypeName = function () { return 'DownloadCompaniesExcelLog'; };
    return DownloadCompaniesExcelLog;
}());
export { DownloadCompaniesExcelLog };
// @Route("/funds/download/log")
var DownloadFundsExcelLog = /** @class */ (function () {
    function DownloadFundsExcelLog(init) {
        Object.assign(this, init);
    }
    DownloadFundsExcelLog.prototype.createResponse = function () { return new DownloadFundsExcelLogResponse(); };
    DownloadFundsExcelLog.prototype.getTypeName = function () { return 'DownloadFundsExcelLog'; };
    return DownloadFundsExcelLog;
}());
export { DownloadFundsExcelLog };
// @Route("/companies", "GET")
var GetCompanies = /** @class */ (function () {
    function GetCompanies(init) {
        Object.assign(this, init);
    }
    GetCompanies.prototype.createResponse = function () { return new GetCompaniesResponse(); };
    GetCompanies.prototype.getTypeName = function () { return 'GetCompanies'; };
    return GetCompanies;
}());
export { GetCompanies };
// @Route("/companies/{Id}", "GET")
var GetCompany = /** @class */ (function () {
    function GetCompany(init) {
        Object.assign(this, init);
    }
    GetCompany.prototype.createResponse = function () { return new GetCompanyResponse(); };
    GetCompany.prototype.getTypeName = function () { return 'GetCompany'; };
    return GetCompany;
}());
export { GetCompany };
// @Route("/companies", "POST")
var CreateOrUpdateCompany = /** @class */ (function () {
    function CreateOrUpdateCompany(init) {
        Object.assign(this, init);
    }
    CreateOrUpdateCompany.prototype.createResponse = function () { return new CreateOrUpdateCompanyResponse(); };
    CreateOrUpdateCompany.prototype.getTypeName = function () { return 'CreateOrUpdateCompany'; };
    return CreateOrUpdateCompany;
}());
export { CreateOrUpdateCompany };
// @Route("/companies/{Id}", "DELETE")
var DeleteCompany = /** @class */ (function () {
    function DeleteCompany(init) {
        Object.assign(this, init);
    }
    DeleteCompany.prototype.createResponse = function () { return new DeleteCompanyResponse(); };
    DeleteCompany.prototype.getTypeName = function () { return 'DeleteCompany'; };
    return DeleteCompany;
}());
export { DeleteCompany };
// @Route("/company/commentary", "POST")
var UpdateCompanyCommentary = /** @class */ (function () {
    function UpdateCompanyCommentary(init) {
        Object.assign(this, init);
    }
    UpdateCompanyCommentary.prototype.createResponse = function () { return new UpdateCompanyCommentaryResponse(); };
    UpdateCompanyCommentary.prototype.getTypeName = function () { return 'UpdateCompanyCommentary'; };
    return UpdateCompanyCommentary;
}());
export { UpdateCompanyCommentary };
// @Route("/mail/template")
var SendMailTemplate = /** @class */ (function () {
    function SendMailTemplate(init) {
        Object.assign(this, init);
    }
    SendMailTemplate.prototype.createResponse = function () { return new SendMailTemplateResponse(); };
    SendMailTemplate.prototype.getTypeName = function () { return 'SendMailTemplate'; };
    return SendMailTemplate;
}());
export { SendMailTemplate };
// @Route("/funds")
var GetFunds = /** @class */ (function () {
    function GetFunds(init) {
        Object.assign(this, init);
    }
    GetFunds.prototype.createResponse = function () { return new GetFundsResponse(); };
    GetFunds.prototype.getTypeName = function () { return 'GetFunds'; };
    return GetFunds;
}());
export { GetFunds };
// @Route("/funds/{Id}", "GET")
var GetFund = /** @class */ (function () {
    function GetFund(init) {
        Object.assign(this, init);
    }
    GetFund.prototype.createResponse = function () { return new GetFundResponse(); };
    GetFund.prototype.getTypeName = function () { return 'GetFund'; };
    return GetFund;
}());
export { GetFund };
// @Route("/funds", "POST")
var CreateOrUpdateFund = /** @class */ (function () {
    function CreateOrUpdateFund(init) {
        Object.assign(this, init);
    }
    CreateOrUpdateFund.prototype.createResponse = function () { return new CreateOrUpdateFundResponse(); };
    CreateOrUpdateFund.prototype.getTypeName = function () { return 'CreateOrUpdateFund'; };
    return CreateOrUpdateFund;
}());
export { CreateOrUpdateFund };
// @Route("/funds/{Id}", "DELETE")
var DeleteFund = /** @class */ (function () {
    function DeleteFund(init) {
        Object.assign(this, init);
    }
    DeleteFund.prototype.createResponse = function () { return new DeleteFundResponse(); };
    DeleteFund.prototype.getTypeName = function () { return 'DeleteFund'; };
    return DeleteFund;
}());
export { DeleteFund };
// @Route("/funds/commentary", "POST")
var UpdateFundCommentary = /** @class */ (function () {
    function UpdateFundCommentary(init) {
        Object.assign(this, init);
    }
    UpdateFundCommentary.prototype.createResponse = function () { return new UpdateFundCommentaryResponse(); };
    UpdateFundCommentary.prototype.getTypeName = function () { return 'UpdateFundCommentary'; };
    return UpdateFundCommentary;
}());
export { UpdateFundCommentary };
// @Route("/report/template/{Id}")
var GetReportTemplates = /** @class */ (function () {
    function GetReportTemplates(init) {
        Object.assign(this, init);
    }
    GetReportTemplates.prototype.createResponse = function () { return new GetReportTemplateResponse(); };
    GetReportTemplates.prototype.getTypeName = function () { return 'GetReportTemplates'; };
    return GetReportTemplates;
}());
export { GetReportTemplates };
// @Route("/report/pdf/{Id}")
var GetFundPdf = /** @class */ (function () {
    function GetFundPdf(init) {
        Object.assign(this, init);
    }
    GetFundPdf.prototype.createResponse = function () { return new GetFundPdfResponse(); };
    GetFundPdf.prototype.getTypeName = function () { return 'GetFundPdf'; };
    return GetFundPdf;
}());
export { GetFundPdf };
// @Route("/report/dummytypes")
var GetDummyTypes = /** @class */ (function () {
    function GetDummyTypes(init) {
        Object.assign(this, init);
    }
    GetDummyTypes.prototype.createResponse = function () { return new GetDummyTypesResponse(); };
    GetDummyTypes.prototype.getTypeName = function () { return 'GetDummyTypes'; };
    return GetDummyTypes;
}());
export { GetDummyTypes };
// @Route("/my-session")
var SessionInfo = /** @class */ (function () {
    function SessionInfo(init) {
        Object.assign(this, init);
    }
    SessionInfo.prototype.createResponse = function () { return new SessionInfoResponse(); };
    SessionInfo.prototype.getTypeName = function () { return 'SessionInfo'; };
    return SessionInfo;
}());
export { SessionInfo };
// @Route("/file/delete")
var DeleteRequest = /** @class */ (function () {
    function DeleteRequest(init) {
        Object.assign(this, init);
    }
    DeleteRequest.prototype.createResponse = function () { return new DeleteResponse(); };
    DeleteRequest.prototype.getTypeName = function () { return 'DeleteRequest'; };
    return DeleteRequest;
}());
export { DeleteRequest };
// @Route("/file/upload", "POST")
var UploadRequest = /** @class */ (function () {
    function UploadRequest(init) {
        Object.assign(this, init);
    }
    UploadRequest.prototype.createResponse = function () { return new UploadResponse(); };
    UploadRequest.prototype.getTypeName = function () { return 'UploadRequest'; };
    return UploadRequest;
}());
export { UploadRequest };
// @Route("/file/download")
var DownloadRequest = /** @class */ (function () {
    function DownloadRequest(init) {
        Object.assign(this, init);
    }
    DownloadRequest.prototype.createResponse = function () { return new DownloadResponse(); };
    DownloadRequest.prototype.getTypeName = function () { return 'DownloadRequest'; };
    return DownloadRequest;
}());
export { DownloadRequest };
// @Route("/userinfo")
var GetUserInfo = /** @class */ (function () {
    function GetUserInfo(init) {
        Object.assign(this, init);
    }
    GetUserInfo.prototype.createResponse = function () { return new GetUserInfoResponse(); };
    GetUserInfo.prototype.getTypeName = function () { return 'GetUserInfo'; };
    return GetUserInfo;
}());
export { GetUserInfo };
// @Route("/users")
var GetUsers = /** @class */ (function () {
    function GetUsers(init) {
        Object.assign(this, init);
    }
    GetUsers.prototype.createResponse = function () { return new GetUsersResponse(); };
    GetUsers.prototype.getTypeName = function () { return 'GetUsers'; };
    return GetUsers;
}());
export { GetUsers };
// @Route("/users/create", "POST")
var CreateUser = /** @class */ (function () {
    function CreateUser(init) {
        Object.assign(this, init);
    }
    CreateUser.prototype.createResponse = function () { return new CreateUserResponse(); };
    CreateUser.prototype.getTypeName = function () { return 'CreateUser'; };
    return CreateUser;
}());
export { CreateUser };
// @Route("/users/update", "POST")
var UpdateUser = /** @class */ (function () {
    function UpdateUser(init) {
        Object.assign(this, init);
    }
    UpdateUser.prototype.createResponse = function () { return new UpdateUserResponse(); };
    UpdateUser.prototype.getTypeName = function () { return 'UpdateUser'; };
    return UpdateUser;
}());
export { UpdateUser };
// @Route("/users/delete", "POST")
var DeleteUser = /** @class */ (function () {
    function DeleteUser(init) {
        Object.assign(this, init);
    }
    DeleteUser.prototype.createResponse = function () { return new DeleteUserResponse(); };
    DeleteUser.prototype.getTypeName = function () { return 'DeleteUser'; };
    return DeleteUser;
}());
export { DeleteUser };
// @Route("/users/assignRole", "POST")
var AssignRole = /** @class */ (function () {
    function AssignRole(init) {
        Object.assign(this, init);
    }
    AssignRole.prototype.createResponse = function () { return new AssignRoleResponse(); };
    AssignRole.prototype.getTypeName = function () { return 'AssignRole'; };
    return AssignRole;
}());
export { AssignRole };
// @Route("/users/unassignallpermissions")
var UnAssignAllPermissions = /** @class */ (function () {
    function UnAssignAllPermissions(init) {
        Object.assign(this, init);
    }
    UnAssignAllPermissions.prototype.createResponse = function () { return new UnAssignAllPermissionsResponse(); };
    UnAssignAllPermissions.prototype.getTypeName = function () { return 'UnAssignAllPermissions'; };
    return UnAssignAllPermissions;
}());
export { UnAssignAllPermissions };
// @Route("/users/unAssignRole", "POST")
var UnAssignRole = /** @class */ (function () {
    function UnAssignRole(init) {
        Object.assign(this, init);
    }
    UnAssignRole.prototype.createResponse = function () { return new UnAssignRoleResponse(); };
    UnAssignRole.prototype.getTypeName = function () { return 'UnAssignRole'; };
    return UnAssignRole;
}());
export { UnAssignRole };
// @Route("/auth")
// @Route("/auth/{provider}")
// @Route("/authenticate")
// @Route("/authenticate/{provider}")
// @DataContract
var Authenticate = /** @class */ (function () {
    function Authenticate(init) {
        Object.assign(this, init);
    }
    Authenticate.prototype.createResponse = function () { return new AuthenticateResponse(); };
    Authenticate.prototype.getTypeName = function () { return 'Authenticate'; };
    return Authenticate;
}());
export { Authenticate };
// @Route("/assignroles")
// @DataContract
var AssignRoles = /** @class */ (function () {
    function AssignRoles(init) {
        Object.assign(this, init);
    }
    AssignRoles.prototype.createResponse = function () { return new AssignRolesResponse(); };
    AssignRoles.prototype.getTypeName = function () { return 'AssignRoles'; };
    return AssignRoles;
}());
export { AssignRoles };
// @Route("/unassignroles")
// @DataContract
var UnAssignRoles = /** @class */ (function () {
    function UnAssignRoles(init) {
        Object.assign(this, init);
    }
    UnAssignRoles.prototype.createResponse = function () { return new UnAssignRolesResponse(); };
    UnAssignRoles.prototype.getTypeName = function () { return 'UnAssignRoles'; };
    return UnAssignRoles;
}());
export { UnAssignRoles };
// @Route("/session-to-token")
// @DataContract
var ConvertSessionToToken = /** @class */ (function () {
    function ConvertSessionToToken(init) {
        Object.assign(this, init);
    }
    ConvertSessionToToken.prototype.createResponse = function () { return new ConvertSessionToTokenResponse(); };
    ConvertSessionToToken.prototype.getTypeName = function () { return 'ConvertSessionToToken'; };
    return ConvertSessionToToken;
}());
export { ConvertSessionToToken };
// @Route("/access-token")
// @DataContract
var GetAccessToken = /** @class */ (function () {
    function GetAccessToken(init) {
        Object.assign(this, init);
    }
    GetAccessToken.prototype.createResponse = function () { return new GetAccessTokenResponse(); };
    GetAccessToken.prototype.getTypeName = function () { return 'GetAccessToken'; };
    return GetAccessToken;
}());
export { GetAccessToken };
