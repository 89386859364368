<template>
<div>
	
	--- start --- 
	{{component}}
	===end====

	<table v-if="component && fund" class=" w-full hover:ring-offset-2 hover:ring hover:ring-red-300 hover:cursor-pointer">
		<tr v-for="r in component.columns1">
			<td>
			{{r.label}}	
			</td>
			<td>
				{{fund[r.name]}}
			</td>
		</tr>
	</table>
	
	<table v-if="false" class=" w-full hover:ring-offset-2 hover:ring hover:ring-red-300 hover:cursor-pointer">
		<tr v-for="d in Math.max(c.columns1.length, c.columns2.length)" class="border-b">
			<td :data-id="columns1[d -1]" class="pl-1 font-semibold w-1/4 bg-gray-100 hover:border-2 hover:border-red-300 hover:cursor-pointer" @click.prevent="selectElement">
				{{ c.columns1[d -1].name }}
			</td>
			<td class="pl-1 w-1/4 bg-gray-50 hover:cursor-pointer"  :colspan="c.columns1[d -1].columnSpan">
				{{ fund[c.columns1[d-1].name] }}</td>

			<td class="pl-1 font-semibold w-1/4 bg-gray-100"  v-if="c.columns1[d -1].columnSpan == 1">
			</td>
			<td class="pl-1  w-1/4 bg-gray-50 "  v-if="c.columns1[d -1].columnSpan == 1">
			</td>
		</tr>
		<tr v-if="false" v-for="d in Math.max(dummyData.length, dummyData2.length)" class="border-b">
			<td class="pl-1 font-semibold w-1/4 bg-gray-100"
			    :class="dummyData[d -1].isSub ? ' pl-4 italic' : ''">
				{{ dummyData.length >= d ? dummyData[d - 1].title : '' }}
			</td>
			<td class="pl-1 w-1/4 bg-gray-50"> {{ dummyData.length >= d ? dummyData[d - 1].value : '' }}</td>
			<td class="pl-1 w-4 border-b border-b-white"></td>
			<td class="pl-1 font-semibold w-1/4 bg-gray-100">
				{{ (dummyData2.length >= d) ? dummyData2[d - 1].title : '' }}
			</td>
			<td class="pl-1  w-1/4 bg-gray-50">{{
					(dummyData2.length >= d) ? dummyData2[d - 1].value : ''
				}}
			</td>
		</tr>
	</table>
</div>
</template>

<script>
import {FundView, KeyFactSettingsComponent} from "../../dtos";

export default {
	name: "KeyFactTableComponent",
	props: { component: KeyFactSettingsComponent, fund: FundView }
}
</script>

<style scoped>

</style>