<!-- @format -->

<template>
  <div class="about">
    <div class="client-container">
      <div
        class="cbg"
        :style="
          'background: url(https://assets.website-files.com/5c6eb35188c954830ec0365d/5c817444f2a0f7e7f938ae36_yacht-dtp.jpg) center center; background-size: cover;'
        "
      ></div>
      <div class="cbgmenu"></div>
      <div class="ui twelve column padded grid centered">
        <div class="row"></div>
        <div class="row"></div>
      </div>
    </div>
    <br />
    <sui-container text-align="center">
      <h1 is="sui-header">ANALYTICS CONSULTING</h1>
      <h1 is="sui-header">MANAGER HUB</h1>
      <br />
      <p>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
        ligula eget dolor. Aenean massa strong. Cum sociis natoque penatibus et
        magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,
        ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa
        quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget,
        arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
        Nullam dictum felis eu pede
      </p>
      <br /><br />
      <div class="row">
        <router-link to="/companies/0" class="ui large basic positive button"
          >Create New Company</router-link
        >
        <router-link to="/companies" class="ui large basic primary button"
          >View Companies</router-link
        >
      </div>
    </sui-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {}
};
</script>
