/** @format */

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Companies from "../views/Companies.vue";

import Research from "../views/Research.vue";
import Funds from "../views/Funds.vue";
import Users from "../views/Users.vue";
import CompanyView from "../views/CompanyView.vue";
import FundView from "../views/FundView.vue";
import QuickFundCreate from "../views/QuickFundCreate.vue";
import QuickCompanyCreate from "../views/QuickCompanyCreate.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Companies
  },
  {
    path: "/research",
    name: "Research",
    component: Research
  },
  {
    path: "/companies",
    name: "Companies",
    component: Companies
  },
  {
    path: "/companies/:id",
    name: "CompanyView",
    component: CompanyView
  },
  {
    path: "/funds",
    name: "Funds",
    component: Funds
  },
  {
    path: "/funds/:id",
    name: "FundView",
    component: FundView
  },
  {
    path: "/funds/create/:id",
    name: "QuickFundCreate",
    component: QuickFundCreate
  },
  {
    path: "/companies/create/:id",
    name: "QuickCompanyCreate",
    component: QuickCompanyCreate
  },
  {
    path: "/users",
    name: "Users",
    component: Users
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
