/** @format */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "semantic-ui-css/semantic.min.css";
import SuiVue from "semantic-ui-vue";
import vSelect from "vue-select";
import store from "./store";
import Notifications from "vue-notification";
import CompositionApi from "@vue/composition-api";
import "./assets/main.css";
Vue.config.productionTip = false;
Vue.use(SuiVue);
Vue.use(Notifications);
Vue.use(CompositionApi);
Vue.component("v-select", vSelect);
new Vue({
  router,
  store,
  // eslint-disable-next-line prettier/prettier
  render: (h) => h(App),
}).$mount("#app");
