<!-- @format -->

<template>
  <div class="about">
    <div class="client-container">
      <div
        class="cbg"
        :style="
          'background: url(https://assets.website-files.com/5c6eb35188c954830ec0365d/5c817444f2a0f7e7f938ae36_yacht-dtp.jpg) center center; background-size: cover;'
        "
      ></div>
      <div class="cbgmenu"></div>
      <div class="ui sixteen column padded grid centered">
        <div class="row">
          <div class="sixteen wide column">
            <div>
              <h2>{{ fund.fundName }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="sixteen wide column">
            <div class="ui secondary pointing menu">
              <a
                class="ui item"
                @click.prevent="tabId = 1"
                v-bind:class="{ active: tabId == 1 }"
              >
                KEY DETAILS
              </a>
              <a
                class="ui item"
                @click.prevent="tabId = 2"
                v-bind:class="{ active: tabId == 2 }"
              >
                ESG
              </a>
                <a 
                        class="ui item"
                        @click.prevent="tabId = 5"
                        v-if="fund.isHedgeFund"
                        v-bind:class="{ active: tabId == 5 }"                      
                >
                    HEDGE FUND DETAILS
                </a>
              <a
                class="ui item"
                @click.prevent="tabId = 3"
                v-bind:class="{ active: tabId == 3 }"
              >
                UPLOADS
              </a>
              <a
                class="ui item"
                @click.prevent="tabId = 4"
                v-bind:class="{ active: tabId == 4 }"
                v-if="isAdminUser(user)"
              >
                COMMENTS
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui column padded grid centered">
      <div class="row">
        <div class="sixteen wide column">
          <div>
            <sui-progress progress color="blue" :percent="percent" />
          </div>
          <div class="ui attached segment">
            <div v-if="tabId == 1" class="ui form">
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>FUND INFORMATION</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="field">
                    <label>Company</label>
                    <sui-dropdown
                      :disabled="isEditScreen == false || id != 0"
                      fluid
                      single
                      selection
                      :options="companies"
                      v-model="fund.companyId"
                    />
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Fund name</label>
                      <input
                        type="text"
                        :disabled="isEditScreen == false"
                        v-model="fund.fundName"
                      />
                    </div>
                    
                    <div class="field">
                      <label>Fund inception date</label>
                      <input
                        type="date"
                        :disabled="isEditScreen == false"
                        v-model="fund.fundInceptionDate"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field" v-if="!fund.isHedgeFund">
                      <label>Investment Universe</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="fundTypes"
                        selection
                        v-model="fund.fundType"
                      />
                    </div>
                      <div class="field" v-if="fund.isHedgeFund">
                          <label>Investment Universe</label>
                          <sui-dropdown
                                  :disabled="isEditScreen == false"
                                  fluid
                                  single
                                  :options="investmentUniversesHedged"
                                  selection
                                  v-model="fund.fundType"
                          />
                      </div>
                    
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Is the fund domiciled in South Africa?</label>
                      <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="yesNoOptions"
                          selection
                          v-model="fund.isFundDomiciledInSa"
                      />
                    </div>
                    
                  </div>
                  <hr />
                  <br/> 
                  <div class="two fields">
                    <div class="field" v-if="fund.isFundDomiciledInSa =='No'" >
                      <label>Is the fund Section 65 approved?</label>
                      <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="section65s"
                          selection
                          v-model="fund.section65"
                      />
                    </div>
                    <div class="field" v-if="fund.isFundDomiciledInSa =='Yes'">
                      <label>Is the fund Reg 28 compliant?</label>
                      <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="reg28s"
                          selection
                          v-model="fund.reg28"
                      />
                    </div>
                  </div>
                  <div v-if="fund.isFundDomiciledInSa == 'Yes'" class="">
                    <div class="two fields">
                      <div class="field" >
                        <label>Does the fund include global holdings?</label>
                        <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="yesNoOptions"
                            selection
                            v-model="fund.includesGlobal"
                        />
                      </div>
                    </div>

                    <div class="two fields"  v-if="fund.includesGlobal =='Yes'">
                      <div class="field">
                        <label>What is the fund's Global exposure source?</label>
                        <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="globalExposureSources"
                            placeholder="Search Category"
                            search
                            selection
                            v-model="fund.globalExposureSource"
                        />
                      </div>
                      <div class="field" >
                        <label>How are global investments accessed?</label>
                        <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="globalExposureAccesses"
                            selection
                            v-model="fund.globalExposureAccess"
                        />
                      </div>
                    </div>
                    <div class="two fields"  v-if="fund.includesGlobal =='Yes'">
                      <div class="field">
                        <label>If global fund, is this at a zero fee class?</label>
                        <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="globalZeroFeeClasses"
                            selection
                            v-model="fund.globalZeroFeeClass"
                        />
                      </div>
                    </div>
                    <div class="two fields"  v-if="fund.includesGlobal =='Yes'">
                      <div class="field">
                        <label>Can the fund invest in emerging markets?</label>
                        <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="yesNoOptions"
                            selection
                            v-model="fund.canFundInvestInEm"
                        />
                      </div>
                      <div class="field"  v-if="fund.canFundInvestInEm =='Yes'">
                        <label>What is the maximum allowable emerging market exposure?</label>
                        <input type="text" v-model="fund.maxAllowableEmExposure" />
                      </div>
                    </div>
                    <h4 class="ui dividing header"></h4>
                  </div>
                  <br/>
                  <div class="two fields">
                    <div class="field" v-if="!fund.isHedgeFund">
                      <label>ASISA category</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="asisaCategories"
                        placeholder="Search Category"
                        search
                        selection
                        v-model="fund.asisaCategory"
                      />
                    </div>
                      <div class="field" v-if="fund.isHedgeFund">
                          <label>ASISA category</label>
                          <sui-dropdown
                                  :disabled="isEditScreen == false"
                                  fluid
                                  single
                                  :options="asisaHedgeFundClassifications"
                                  placeholder="Search Category"
                                  search
                                  selection
                                  v-model="fund.asisaCategory"
                          />
                      </div>
                    <div class="field">
                      <label>What is the fund's Investment Approach?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="investmentApproaches"
                        selection
                        v-model="fund.investmentApproach"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>What is the fund's Investment style?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="investmentStyles"
                        selection
                        v-model="fund.investmentStyle"
                      />
                    </div>
                    <div class="field" v-if="fund.investmentStyle == 'Passive'">
                      <label>How often is the fund rebalanced?</label>
                      <input type="text" v-model="fund.fundRebalanceFrequency" />
                    </div>
                  </div>
                  <hr /><br/>
                  <h4 class="ui dividing header">
                    Fund Share Classes
                  </h4>
                  <div v-if="isEditScreen">
                    <table
                      class="ui blue celled striped table"
                      v-if="fund.fundShareClasses.length > 0"
                    >
                      <thead>
                        <tr>
                          <th>Class Name</th>
                          <th>ISIN</th>
                          <th>JSE Code</th>
                          <th>Platform Availability</th>
                          <th v-if="isEditScreen">Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(s, index) in fund.fundShareClasses"
                          :key="index"
                        >
                          <td>
                            <input type="text" v-model="s.fundShareClassName" />
                          </td>
                          <td><input type="text" v-model="s.isin" /></td>
                          <td><input type="text" v-model="s.jseCode" /></td>
                          <td>
                            <sui-dropdown
                              fluid
                              multiple
                              :options="platforms"
                              placeholder="Please select all relevant platforms"
                              selection
                              v-model="s.platformAvailability"
                            />
                          </td>
                          <td v-if="isEditScreen">
                            <a
                              href="#"
                              @click.prevent="removeFundShareClass(index)"
                              class="ui red basic button"
                              >Delete</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span v-else
                      ><h4 style="margin-top: 1rem!important">
                        No Fund Share Classes Added
                      </h4></span
                    >
                  </div>
                  <div v-else-if="!isEditScreen">
                    <table
                      class="ui blue celled striped table"
                      v-if="fund.fundShareClasses.length > 0"
                    >
                      <thead>
                        <tr>
                          <th>Class Name</th>
                          <th>ISIN</th>
                          <th>JSE Code</th>
                          <th>Platform Availability</th>
                          <th v-if="isEditScreen">Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(s, index) in fund.fundShareClasses"
                          :key="index"
                        >
                          <td>
                            <input
                              type="text"
                              disabled
                              v-model="s.fundShareClassName"
                              style="color: #D3D3D3 "
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              disabled
                              v-model="s.isin"
                              style="color: #D3D3D3 "
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              disabled
                              v-model="s.jseCode"
                              style="color: #D3D3D3 "
                            />
                          </td>
                          <td>
                            <sui-dropdown
                              fluid
                              multiple
                              disabled
                              :options="platforms"
                              placeholder="Please select all relevant platforms"
                              selection
                              v-model="s.platformAvailability"
                            />
                          </td>
                          <td v-if="isEditScreen">
                            <a
                              href="#"
                              @click.prevent="removeFundShareClass(index)"
                              class="ui red basic button"
                              >Delete</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span v-else
                      ><h4 style="margin-top: 1rem!important">
                        No Fund Share Classes Added
                      </h4></span
                    >
                  </div>
                  <div class="ui attached message" v-if="isEditScreen">
                    <strong> ADD NEW FUND SHARE CLASS </strong>
                  </div>
                  <div class="ui attached segment" v-if="isEditScreen">
                    <div class="four fields">
                      <div class="field">
                        <label>Class Name</label>
                        <input
                          type="text"
                          v-model="newFundShareClass.fundShareClassName"
                        />
                      </div>
                      <div class="field">
                        <label>ISIN</label>
                        <input type="text" v-model="newFundShareClass.isin" />
                      </div>
                      <div class="field">
                        <label>JSE Code</label>
                        <input
                          type="text"
                          v-model="newFundShareClass.jseCode"
                        />
                      </div>
                      <div class="field">
                        <label>Platform Availability</label>
                        <sui-dropdown
                          fluid
                          multiple
                          :options="platforms"
                          placeholder="Please select all relevant platforms"
                          selection
                          v-model="newFundShareClass.platformAvailability"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="ui attached message" v-if="isEditScreen">
                    <sui-button
                      secondary
                      @click.prevent="addFundShareClass(newFundShareClass)"
                      >Add</sui-button
                    >
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>FUND AUM</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="three fields">
                    <div class="field">
                      <label>AUM currency</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="fundAumCurrencies"
                        selection
                        v-model="fund.fundAumCurrency"
                      />
                    </div>
                    <div class="field">
                      <label>Fund AUM (millions) - CIS and Seggies</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="fund.fundAum"
                      />
                    </div>

                    <div class="field">
                      <label>Date of latest AUM</label>
                      <input
                        type="date"
                        :disabled="isEditScreen == false"
                        v-model="fund.fundLatestAumDate"
                      />
                    </div>
                  </div>
                  <div class="three fields">
                    <div class="field">
                      <label>Fund AUM (millions) - 1 year back</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="fund.fundAumMinusOneYear"
                      />
                    </div>
                    <div class="field">
                      <label>Fund AUM (millions) - 2 years back</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="fund.fundAumMinusTwoYears"
                      />
                    </div>
                    <div class="field">
                      <label>Fund AUM (millions) - 3 years back</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="fund.fundAumMinusThreeYears"
                      />
                    </div>
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>CLIENT CONCENTRATION</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label>How large is the fund's single biggest investor? (%)</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0.0"
                        step=".1"
                        :disabled="isEditScreen == false"
                        v-model="fund.largestClientInFund"
                      />
                    </div>
                    <div class="field">
                      <label
                        >What is the cumulative percentage of the five largest clients in the strategy? (%)</label
                      >
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0.0"
                        step=".1"
                        :disabled="isEditScreen == false"
                        v-model="fund.largestFiveClientsInFund"
                      />
                    </div>
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>FUND PERFORMANCE, STRATEGY AND OBJECTIVES</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Fund benchmark (if the fund benchmark is not in the
                        list please select "Other")</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="fundBenchmarks"
                        placeholder="Search Benchmark"
                        search
                        selection
                        v-model="fund.fundBenchmark"
                      />
                    </div>
                    <transition name="fade">
                      <div class="field" v-if="fund.fundBenchmark == 'Other'">
                        <label>Please specify or explain the Fund Benchmark. (if other was selected)</label>
                        <input
                          type="text"
                          :disabled="isEditScreen == false"
                          v-model="fund.fundBenchmarkSpecify"
                        />
                      </div>
                    </transition>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>What is the fund objective? (if different to Benchmark)</label>
                      <input
                        type="text"
                        :disabled="isEditScreen == false"
                        v-model="fund.fundObjective"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >What is the fund’s target alpha over benchmark listed above? (%)</label
                      >
                      <input
                          type="text"
                          :disabled="isEditScreen == false"
                          v-model="fund.targetAlphaDescription"
                      />
                    </div>
                    <div class="field">
                      <label
                        >What is the typical tracking error? (only applicable to equity
                        strategies)</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="trackingErrors"
                        selection
                        v-model="fund.trackingError"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>What is the fund’s targeted investment time horizon in years?</label>
                      <input
                          type="text"
                          :disabled="isEditScreen == false"
                          v-model="fund.investmentTimeHorizonInYearsDescription"
                      />
                      
                    </div>
                  </div>
                  <transition name="fade">
                    <div v-if="fund.fundType != 'Multi-Asset' && !fund.isHedgeFund">
                      <h4 class="ui dividing header">
                          What is the fund's typical number of holdings?
                      </h4>
                      <br />
                      <div class="two fields">
                        <div class="field">
                          <label>Lower bound</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.lowerBoundOfHoldings"
                          />
                        </div>
                        <div class="field">
                          <label>Upper bound</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.upperBoundOfHoldings"
                          />
                        </div>
                      </div>
                    </div>
                  </transition>
                  <h4 class="ui dividing header"></h4>
                 
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Average portfolio turnover over past 3 years ([buys +
                        sells]/ 2) (%)</label
                      >
                      <input type="text" v-model="fund.averagePortfolioTurnoverDescription"/>
                    
                    </div>
                  </div>
                  <h4 class="ui dividing header"></h4>
                  <br />
                  <div class="field">
                    <label
                      >Please explain your idea generation and how you determine
                      your investable universe?</label
                    >
                    <textarea
                      :disabled="isEditScreen == false"
                      v-model="fund.describeIdeaGeneration"
                    ></textarea>
                  </div>
                  <br />
                  <div class="field">
                    <label
                    >Please describe your sell discipline?</label
                    >
                    <textarea
                        :disabled="isEditScreen == false"
                        v-model="fund.sellDiscipline"
                    ></textarea>
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>INVESTMENT PERSONNEL</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <h4 class="ui dividing header">
                    Number of team members for fund.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total
                    :
                    {{
                      Number(fund.fundPortfolioManagers) +
                        Number(fund.fundMacroStrategists) +                       
                        Number(this.fund.fundGraduates) +
                        Number(this.fund.fundTraders) + 
                      Number(this.fund.fundAnalysts)
                    }}
                  </h4>
                  <br />
                  <div>
                    <div class="three fields">
                      <div class="field">
                        <label>Portfolio managers</label>
                        <input
                          type="number"
                          onkeyup="if(this.value<0)this.value=0"
                          onblur="if(this.value<0)this.value=0"
                          min="0"
                          :disabled="isEditScreen == false"
                          v-model="fund.fundPortfolioManagers"
                        />
                      </div>
                      <div class="field">
                        <label>Macro strategists</label>
                        <input
                          type="number"
                          onkeyup="if(this.value<0)this.value=0"
                          onblur="if(this.value<0)this.value=0"
                          min="0"
                          :disabled="isEditScreen == false"
                          v-model="fund.fundMacroStrategists"
                        />
                      </div>

                      <div class="field">
                        <label>Analysts</label>
                        <input
                          type="number"
                          onkeyup="if(this.value<0)this.value=0"
                          onblur="if(this.value<0)this.value=0"
                          min="0"
                          :disabled="isEditScreen == false"
                          v-model="fund.fundAnalysts"
                        />
                      </div>
                    </div>
                    <div class="two fields">
                      <div class="field">
                        <label>Graduates</label>
                        <input
                          type="number"
                          onkeyup="if(this.value<0)this.value=0"
                          onblur="if(this.value<0)this.value=0"
                          min="0"
                          :disabled="isEditScreen == false"
                          v-model="fund.fundGraduates"
                        />
                      </div>
                      <div class="field">
                        <label>Traders</label>
                        <input
                          type="number"
                          onkeyup="if(this.value<0)this.value=0"
                          onblur="if(this.value<0)this.value=0"
                          min="0"
                          :disabled="isEditScreen == false"
                          v-model="fund.fundTraders"
                        />
                      </div>
                    </div>
                  </div>                
                    <div class="field">
                      <label>What are the Portfolio manager names?</label>
                      <input
                        type="text"
                        :disabled="isEditScreen == false"
                        v-model="fund.portfolioManagerNames"
                      />
                    </div>
                      <div class="field">
                          <label>Please comment if there have been any changes to portfolio management since inception.</label>
                          <input
                                  type="text"
                                  :disabled="isEditScreen == false"
                                  v-model="fund.portfolioManagementChangesSinceInception"
                          />
                      </div>               
                  <h4 class="ui dividing header">
                    <br />
                    Current and previous Key Decision Makers (since fund launch)
                  </h4>

                  <table
                    class="ui blue celled striped table"
                    v-if="fund.keyDecisionMakers.length > 0"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Is Equity Owner?</th>
                        <th>Start</th>
                        <th>End</th>
                        <th v-if="isEditScreen">Manage</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(p, index) in fund.keyDecisionMakers"
                        :key="p.name"
                      >
                        <td><input type="text" v-model="p.name" /></td>
                        <td>
                          <input v-model="p.isEquityOwner" type="checkbox" />
                        </td>
                        <td><input type="text" v-model="p.startDate" /></td>
                        <td><input type="text" v-model="p.endDate" /></td>
                        <td v-if="isEditScreen">
                          <a
                            href="#"
                            @click.prevent="removeKeyDecisionMaker(index)"
                            class="ui red basic button"
                            >Delete</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span v-else>No key decision makers added</span>
                  <div class="ui attached message" v-if="isEditScreen">
                    <strong> ADD NEW KEY DECISION MAKER </strong>
                  </div>
                  <div class="ui attached segment" v-if="isEditScreen">
                    <div class="two fields">
                      <div class="field">
                        <label>Name</label>
                        <input type="text" v-model="newDecisionMaker.name" />
                      </div>
                      <div class="field">
                        <label>Current or former equity owner?</label>
                        <sui-checkbox
                          label="Is an equity owner?"
                          toggle
                          v-model="newDecisionMaker.isEquityOwner"
                        />
                      </div>
                    </div>
                    <div class="two fields">
                      <div class="field">
                        <label>Start date</label>
                        <input
                          type="date"
                          v-model="newDecisionMaker.startDate"
                        />
                      </div>
                      <div class="field">
                        <label>End date (leave open if current)</label>
                        <input type="date" v-model="newDecisionMaker.endDate" />
                      </div>
                    </div>
                  </div>
                  <div class="ui attached message" v-if="isEditScreen">
                    <sui-button
                      secondary
                      @click.prevent="addDecisionMaker(newDecisionMaker)"
                      >Add</sui-button
                    >
                  </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />

              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>ASSET ALLOCATION</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="field" v-if="!fund.isHedgeFund">
                    <label>What is the maximum allocation to a stock/instrument? (%)</label>
                    <input
                      type="number"
                      onkeyup="if(this.value<0)this.value=0"
                      onblur="if(this.value<0)this.value=0"
                      min="0"
                      :disabled="isEditScreen == false"
                      v-model="fund.maxInstrumentAllocation"
                    />
                  </div>
                  <div class="field" v-if="!fund.isHedgeFund">
                    <label
                      >Please specify any other limits that are applicable
                      (sector, asset class etc)</label
                    >
                    <textarea
                      :disabled="isEditScreen == false"
                      v-model="fund.sectorLimits"
                    ></textarea>
                  </div>
                    <div class="field" v-if="fund.isHedgeFund">
                        <label
                        >Please specify any limits that are applicable
                            (sector, asset class etc)</label
                        >
                        <textarea
                                :disabled="isEditScreen == false"
                                v-model="fund.sectorLimits"
                        ></textarea>
                    </div>
                    <div class="field">
                  <label
                  >Please specify any hard exclusions of the fund (sectors, regions etc)</label
                  >
                  <textarea
                      :disabled="isEditScreen == false"
                      v-model="fund.hardExclusions"
                  ></textarea>
                </div>
                  <h4 class="ui dividing header"></h4>
                  <br />
                  <transition name="fade">
                    <div
                      class="two fields"
                      v-if="fund.fundType == 'Multi-Asset'"
                    >
                      <div class="field">
                        <label
                          >If MA Fund, is the equity allocation the houseview or
                          own stock selection?</label
                        >
                        <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="multiAssetAllocations"
                          selection
                          v-model="fund.multiAssetAllocation"
                        />
                      </div>
                    </div>
                  </transition>
                  <div class="row">
                    <div class="sixteen wide column">
                      <div class="two fields">
                        <div class="field">
                        <label
                        >Does the fund have a fixed income component?</label
                        >
                        <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="yesNoOptionsBoolean"
                            selection
                            v-model="fund.hasFixedIncome"
                        />
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                  <transition name="fade">
                    <div v-if="fund.hasFixedIncome == true">
                      <div class="two fields">
                        <div class="field">
                          <label
                            >Typical duration for the fixed income component</label
                          >
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0.0"
                            onblur="if(this.value<0)this.value=0.0"
                            min="0.0"
                            step=".1"
                            :disabled="isEditScreen == false"
                            v-model="fund.targetDuration"
                          />
                        </div>
                        <div class="field">
                          <label>Typical duration range - Lower band</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0.0"
                            onblur="if(this.value<0)this.value=0.0"
                            min="0.0"
                            step=".1"
                            placeholder="as opposed to limits - which can be wide and never used"
                            :disabled="isEditScreen == false"
                            v-model="fund.typicalDurationLower"
                          />
                        </div>
                      </div>
                      <div class="two fields">
                        <div class="field">
                          <label>Typical duration range - Upper band</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0.0"
                            onblur="if(this.value<0)this.value=0.0"
                            min="0.0"
                            step=".1"
                            placeholder="as opposed to limits - which can be wide and never used"
                            :disabled="isEditScreen == false"
                            v-model="fund.typicalDurationUpper"
                          />
                        </div>
                        <div class="field">
                          <label>Typical average maturity</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0.0"
                            onblur="if(this.value<0)this.value=0.0"
                            min="0.0"
                            step=".1"
                            :disabled="isEditScreen == false"
                            v-model="fund.targetAverageMaturity"
                          />
                        </div>
                      </div>
                      <div class="two fields">
                        <div class="field">
                          <label>Typical maturity range</label>
                          <input
                            type="text"
                            :disabled="isEditScreen == false"
                            v-model="fund.typicalMaturityRange"
                          />
                        </div>
                        <div class="field">
                          <label>Do you assign internal credit ratings?</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="internalCreditsRatings"
                            search
                            selection
                            v-model="fund.internalCreditRatings"
                          />
                        </div>
                      </div>
             

                      <div class="two fields">
                        <div class="field">
                          <label>Do you invest in Credit Linked Notes?</label>
                          <sui-dropdown
                            :disabled="isEditScreen == false"
                            fluid
                            single
                            :options="creditsLinkedNotes"
                            selection
                            v-model="fund.creditLinkedNotes"
                          />
                        </div>
                        <div class="field">
                          <label>Maximum term per instrument</label>
                          <input
                            type="text"
                            :disabled="isEditScreen == false"
                            v-model="fund.maxInstrumentTerm"
                          />
                        </div>
                      </div>
                      <div class="two fields">
                        <div class="field">
                          <label>Maximum weight per instrument (%)</label>
                          <input
                            type="number"
                            :disabled="isEditScreen == false"
                            v-model="fund.maxInstrumentWeight"
                          />
                        </div>
                        <div class="field">
                          <label
                            >Maximum exposure to Credit Linked Notes (%)</label
                          >
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.maxCLNExposure"
                          />
                        </div>
                      </div>
                      <div class="two fields">
                        <div class="field">
                          <label
                            >Maximum exposure to Preference shares (%)</label
                          >
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.maxPrefShareExposure"
                          />
                        </div>
                        <div class="field">
                          <label>Maximum exposure to Property (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.maxPropertyExposure"
                          />
                        </div>
                      </div>
                      <div class="two fields">
                        <div class="field">
                          <label>Maximum exposure to an issuer (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.maxIssuerExposure"
                          />
                        </div>
                      </div>
                      <div class="two fields">
                        <div class="field">
                          <label>Can the fund invest in sub investment grade?</label>
                          <sui-dropdown :options="yesNoOptions"
                                        :disabled="isEditScreen == false"
                                        v-model="fund.doesFundInvestInSubInvestmentGrade"
                                        fluid
                                        single
                                        selection
                          />                        
                        </div>
                        <div class="field" v-if="fund.doesFundInvestInSubInvestmentGrade == 'Yes'">
                          <label>What is maximum allowable exposure to sub investment grade?</label>
                          <input type="text" 
                                 v-model="fund.maxAllowableToSubInvestmentGrade"
                                 :disabled="isEditScreen == false"      
                          />
                        </div>
                      </div>
                      <div class="two fields">
                        <div class="field">
                          <label>Does the credit team include any legal personnel/experts?</label>
                          <sui-dropdown :options="yesNoOptions"
                                        :disabled="isEditScreen == false"
                                        v-model="fund.doesCreditTeamIncludeLegalPersonnel"
                                        fluid
                                        single
                                        selection
                          />
                        </div>
                       
                      </div>
                    </div>
                  </transition>
                  <h4 class="ui dividing header"></h4>
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label>Do you have an asset allocation committee?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="aACommittees"
                        selection
                        v-model="fund.aaCommittee"
                      />
                    </div>
                    <transition name="fade">
                      <div class="field" v-if="fund.aaCommittee == 'Yes'">
                        <label>If yes, number of AA committee members</label>
                        <input
                          type="number"
                          onkeyup="if(this.value<0)this.value=0"
                          onblur="if(this.value<0)this.value=0"
                          min="0"
                          :disabled="isEditScreen == false"
                          v-model="fund.numberOfAACommittee"
                        />
                      </div>
                    </transition>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Does the fund have strategic asset allocation?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="sAAsUsed"
                        selection
                        v-model="fund.saaUsed"
                      />
                    </div>
                  </div>
                  <transition name="fade">
                    <div v-if="fund.saaUsed == 'Yes'">
                      <div class="three fields">
                        <div class="field">
                          <label>SA Equity (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.saEquity"
                          />
                        </div>
                        <div class="field">
                          <label>SA Property (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.saProperty"
                          />
                        </div>
                        <div class="field">
                          <label>SA Bonds (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.saBonds"
                          />
                        </div>
                      </div>
                      <div class="three fields">
                        <div class="field">
                          <label>SA ILBs (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.saILBs"
                          />
                        </div>
                        <div class="field">
                          <label>SA Money Market (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.saMoneyMarket"
                          />
                        </div>
                        <div class="field">
                          <label>Global Equity (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.globalEquity"
                          />
                        </div>
                      </div>
                      <div class="three fields">
                        <div class="field">
                          <label>Global Property (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.globalProperty"
                          />
                        </div>
                        <div class="field">
                          <label>Global Bonds (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.globalBonds"
                          />
                        </div>
                        <div class="field">
                          <label>Global Money Market (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.globalMoneyMarket"
                          />
                        </div>
                      </div>
                      <div class="three fields">
                        <div class="field">
                          <label>Africa (%)</label>
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.africa"
                          />
                        </div>
                        <div class="field">
                          <label
                            >Alternatives (PE/Infrastructure/Direct Property
                            etc.) (%)</label
                          >
                          <input
                            type="number"
                            onkeyup="if(this.value<0)this.value=0"
                            onblur="if(this.value<0)this.value=0"
                            min="0"
                            :disabled="isEditScreen == false"
                            v-model="fund.alternatives"
                          />
                        </div>
                        <div class="field">
                          <label>Total SAA (must = 100%)</label>
                          <strong
                            >{{
                              Number(fund.saEquity) +
                                Number(fund.saProperty) +
                                Number(fund.saBonds) +
                                Number(fund.saILBs) +
                                Number(fund.saMoneyMarket) +
                                Number(fund.globalEquity) +
                                Number(fund.globalProperty) +
                                Number(fund.globalBonds) +
                                Number(fund.globalMoneyMarket) +
                                Number(fund.africa) +
                                Number(fund.alternatives)
                            }}
                            %</strong
                          >
                        </div>
                      </div>
                    </div>
                  </transition>
                  <h4 class="ui dividing header"></h4>
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Does the Fund use Tactical Asset Allocation?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="doesFundUseTaas"
                        selection
                        v-model="fund.doesFundUseTaa"
                      />
                    </div>
                    <br />
                    <transition name="fade">
                      <div class="field" v-if="fund.doesFundUseTaa == 'Yes'">
                        <label
                          >Tactical asset allocation (TAA)
                          bands/restrictions</label
                        >
                        <sui-dropdown
                          :disabled="isEditScreen == false"
                          fluid
                          single
                          :options="taasBands"
                          selection
                          v-model="fund.taaBands"
                        />
                      </div>
                    </transition>
                  </div>
                  <h4 class="ui dividing header"></h4>
                  <br />
                  <div class="field">
                    <label
                      >Broadly describe your Asset Allocation process</label
                    >
                    <textarea
                      :disabled="isEditScreen == false"
                      v-model="fund.aaProcess"
                    ></textarea>
                  </div>
                  <br />
                  <div class="field">
                    <label
                    >What is the typical cash allocation and maximum allowable allocation?</label
                    >
                    <textarea
                        :disabled="isEditScreen == false"
                        v-model="fund.typicalAndMaxCashAllowableAllocation"
                    ></textarea>
                  </div>  <div class="field">
                  <label
                  >What is the typical use of cash?</label
                  >
                  <textarea
                      :disabled="isEditScreen == false"
                      v-model="fund.typicalUseOfCash"
                  ></textarea>
                </div><br/>
                </sui-accordion-content>
              </sui-accordion>
              <br />
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>TRADING AND BROKERAGE</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label>Trading execution</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="tradeExecutions"
                        selection
                        v-model="fund.tradeExecution"
                      />
                    </div>
                    <div class="field focus">
                      <label
                        >Average brokerage paid in basis points for execution
                        (equity)</label
                      >
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="fund.averageBrokerage"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Names of Stockbrokers/Bondbrokers used for this
                        strategy</label
                      >
                      <input
                        type="text"
                        :disabled="isEditScreen == false"
                        v-model="fund.brokersUsed"
                      />
                    </div>
                    <transition name="fade">
                      <div
                        class="field"
                        v-if="fund.tradeExecution != 'Outsourced to brokers'"
                      >
                        <label
                          >What is the percentage of trades through internal
                          broker? (%)</label
                        >
                        <input
                          type="number"
                          onkeyup="if(this.value<0)this.value=0"
                          onblur="if(this.value<0)this.value=0"
                          min="0"
                          :disabled="isEditScreen == false"
                          v-model="fund.internalTradesPercentage"
                        />
                      </div>
                    </transition>
                  </div>
                  <h4>
                    Expected number of trading days (assuming 20% of last 3
                    months daily average volume) to
                  </h4>
                  <div class="three fields">
                    <div class="field">
                      <label>Liquidate 10% of fund</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="fund.numberOfDaysToLiquidateTenPercent"
                      />
                    </div>
                    <div class="field">
                      <label>Liquidate 25% of fund</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="fund.numberOfDaysToLiquidateTwentyPercent"
                      />
                    </div>
                    <div class="field">
                      <label>Liquidate 50% of fund</label>
                      <input
                        type="number"
                        onkeyup="if(this.value<0)this.value=0"
                        onblur="if(this.value<0)this.value=0"
                        min="0"
                        :disabled="isEditScreen == false"
                        v-model="fund.numberOfDaysToLiquidateFiftyPercent"
                      />
                    </div>
                  </div>
                  <div class="two fields" v-if="!fund.isHedgeFund">
                    <div class="field">
                      <label>Do you make use of derivatives?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="derivativesUseds"
                        selection
                        v-model="fund.derivativesUsed"
                      />
                    </div>
                    <transition name="fade">
                      <div class="field" v-if="fund.derivativesUsed == 'Yes'">
                        <label>Maximum exposure to derivatives (%)</label>
                        <input
                          type="number"
                          onkeyup="if(this.value<0)this.value=0"
                          onblur="if(this.value<0)this.value=0"
                          min="0"
                          :disabled="isEditScreen == false"
                          v-model="fund.maxDerivatives"
                        />
                      </div>
                    </transition>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Do you do currency hedging?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="currencyHedgings"
                        selection
                        v-model="fund.currencyHedging"
                      />
                    </div>
                    <transition name="fade">
                      <div class="field" v-if="fund.derivativesUsed == 'Yes'">
                        
                        <label>What do you use derivatives for?</label>
                        <sui-dropdown
                            fluid
                            multiple
                            :options="derivativesUsedFors"
                            placeholder="Please select all relevant cases"
                            selection
                            v-model="fund.derivativesUsedForArray"
                        />
                       
                      </div>
                    </transition>
                  </div>
                </sui-accordion-content>
              </sui-accordion>
                <br />
                <sui-accordion class="ui attached message">
                    <a is="sui-accordion-title">
                        <sui-icon name="dropdown" />
                        <strong>COMPETITIVE STRENGTHS AND WEAKNESSES</strong>
                    </a>
                    <sui-accordion-content class="ui attached segment">
                        <br />
                        <div class="field">
                            <label>Please explain your competitive strengths versus peers.</label>
                            <textarea
                                    v-model="fund.competitiveStrengths"
                                    :disabled="isEditScreen == false"
                            ></textarea>
                        </div>
                        <div class="field">
                            <label>Please explain your competitive weaknesses versus peers.</label>
                            <textarea
                                    v-model="fund.competitiveWeaknesses"
                                    :disabled="isEditScreen == false"
                            ></textarea>
                        </div>
                    </sui-accordion-content>
                </sui-accordion>
              <br />
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>ADDITIONAL COMMENTS</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="field">
                    <label
                      >For any additional comments
                      <i>(Please reference the relevant field)</i></label
                    >
                    <textarea
                      :disabled="isEditScreen == false"
                      v-model="fund.userFundCommentary"
                    ></textarea>
                  </div>
                </sui-accordion-content>
              </sui-accordion>               
              <br />
            </div>
            <br />

            <div v-if="tabId == 2" class="ui form">
              <sui-accordion class="ui attached message">
                <a is="sui-accordion-title">
                  <sui-icon name="dropdown" />
                  <strong>ESG IMPLEMENTATION</strong>
                </a>
                <sui-accordion-content class="ui attached segment">
                  <br />
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Do you exclude any companies involved in controversial
                        activities in this fund?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="fundControversialCompaniesExcludeds"
                        selection
                        v-model="fund.fundControversialCompaniesExcluded"
                      />
                    </div>
                    <transition name="fade">
                      <div
                        class="field"
                        v-if="fund.fundControversialCompaniesExcluded == 'Yes'"
                      >
                        <label>Percentage of universe excluded (%)</label>
                        <input
                          type="number"
                          onkeyup="if(this.value<0)this.value=0"
                          onblur="if(this.value<0)this.value=0"
                          min="0"
                          :disabled="isEditScreen == false"
                          v-model="
                            fund.fundControversialCompaniesExcludedPercentage
                          "
                        />
                      </div>
                    </transition>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label>Does the fund have a dedicated ESG focus?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="fundESGsFocus"
                        selection
                        v-model="fund.fundESGFocus"
                      />
                    </div>
                    <div class="field">
                      <label
                        >Is the fund portfolio manager/team involved in ESG
                        analysis and scoring?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="fundPMIncludedsInESGAnalysis"
                        selection
                        v-model="fund.fundPMIncludedInESGAnalysis"
                      />
                    </div>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Can the portfolio manager invest in companies that do
                        not meet your minimum ESG criteria?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="fundControversialCompaniesCanInvests"
                        selection
                        v-model="fund.fundControversialCompaniesCanInvest"
                      />
                    </div>
                    <transition name="fade">
                      <div
                        class="field"
                        v-if="fund.fundControversialCompaniesCanInvest == 'Yes'"
                      >
                        <label
                          >Maximum percentage exposure to "non-ESG" companies
                          (%)</label
                        >
                        <input
                          type="number"
                          onkeyup="if(this.value<0)this.value=0"
                          onblur="if(this.value<0)this.value=0"
                          min="0"
                          :disabled="isEditScreen == false"
                          v-model="
                            fund.maxFundControversialCompaniesExcludedPercentage
                          "
                        />
                      </div>
                    </transition>
                  </div>
                  <div class="two fields">
                    <div class="field">
                      <label
                        >Do you have a compliance process specifically relating
                        to ESG?</label
                      >
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="fundESGCompliancesProcess"
                        selection
                        v-model="fund.fundESGComplianceProcess"
                      />
                    </div>
                    <div class="field">
                      <label>Does the fund have any independent certifications related to ESG or Sustainability?</label>
                      <sui-dropdown
                        :disabled="isEditScreen == false"
                        fluid
                        single
                        :options="fundESGCertifieds"
                        selection
                        v-model="fund.fundESGCertified"
                      />
                    </div>
                  </div>
                    <div class="field" v-if="fund.fundESGCertified === 'Yes'">
                        <label>Please explain or state the ESG/Sustainability Certifications relating to the fund.</label>
                        <input   
                                type="text"
                                :disabled="isEditScreen == false"                                
                                v-model="fund.fundESGCertifications"
                        />
                    </div>
                </sui-accordion-content>
              </sui-accordion>
              <br />
            </div>

            <div v-if="tabId == 3" class="ui form">
              <div class="ui attached segment">
                <span v-if="isAdminUser(user)">
                  <div class="field">
                    <label
                      >Is Past Performance Upload required? (only admins can see
                      this)</label
                    >
                    <input
                      type="checkbox"
                      :disabled="isEditScreen == false"
                      v-model="fund.isPastPerformanceUploadRequired"
                    />
                  </div>
                </span>
                <table class="ui celled striped table">
                  <thead>
                    <tr>
                      <th>FILE TYPE</th>
                      <th>DOWNLOAD</th>
                      <th v-if="isEditScreen">UPLOAD</th>
                      <th v-if="isEditScreen">DELETE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Investment Philosophy</td>
                      <td>
                        <a
                          href="#"
                          v-if="investmentPhilosophyUrl != ''"
                          @click.prevent="downloadFile('Investment Philosophy')"
                          class="ui teal basic button"
                          >Download</a
                        ><span v-else>n/a</span>
                      </td>
                      <td v-if="isEditScreen">
                        <input type="file" @change="onFileChangedPhilosophy" />
                      </td>
                      <td v-if="isEditScreen">
                        <a
                          href="#"
                          v-if="investmentPhilosophyUrl != ''"
                          @click.prevent="deleteFile('Investment Philosophy')"
                          class="ui red basic button"
                          >Delete</a
                        ><span v-else>n/a</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Investment Process</td>
                      <td>
                        <a
                          href="#"
                          v-if="investmentProcessUrl != ''"
                          @click.prevent="downloadFile('Investment Process')"
                          class="ui teal basic button"
                          >Download</a
                        ><span v-else>n/a</span>
                      </td>
                      <td v-if="isEditScreen">
                        <input type="file" @change="onFileChangedProcess" />
                      </td>
                      <td v-if="isEditScreen">
                        <a
                          href="#"
                          v-if="investmentProcessUrl != ''"
                          @click.prevent="deleteFile('Investment Process')"
                          class="ui red basic button"
                          >Delete</a
                        ><span v-else>n/a</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Risk Management Framework</td>
                      <td>
                        <a
                          href="#"
                          v-if="riskManagementFrameworkUrl != ''"
                          @click.prevent="
                            downloadFile('Risk Management Framework')
                          "
                          class="ui teal basic button"
                          >Download</a
                        ><span v-else>n/a</span>
                      </td>
                      <td v-if="isEditScreen">
                        <input
                          type="file"
                          @change="onFileChangedRiskManagement"
                        />
                      </td>
                      <td v-if="isEditScreen">
                        <a
                          href="#"
                          v-if="riskManagementFrameworkUrl != ''"
                          @click.prevent="
                            deleteFile('Risk Management Framework')
                          "
                          class="ui red basic button"
                          >Delete</a
                        ><span v-else>n/a</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Annual Stewardship Report (if applicable)</td>
                      <td>
                        <a
                          href="#"
                          v-if="annualStewardshipUrl != ''"
                          @click.prevent="downloadFile('Annual Stewardship')"
                          class="ui teal basic button"
                          >Download</a
                        ><span v-else>n/a</span>
                      </td>
                      <td v-if="isEditScreen">
                        <input
                          type="file"
                          @change="onFileChangedAnnualStewardship"
                        />
                      </td>
                      <td v-if="isEditScreen">
                        <a
                          href="#"
                          v-if="annualStewardshipUrl != ''"
                          @click.prevent="deleteFile('Annual Stewardship')"
                          class="ui red basic button"
                          >Delete</a
                        ><span v-else>n/a</span>
                      </td>
                    </tr>
                    <tr v-if="fund.isPastPerformanceUploadRequired">
                      <td>Past Performance Excel</td>
                      <td>
                        <a
                          href="#"
                          v-if="pastPerformanceUrl != ''"
                          @click.prevent="downloadFile('Past Performance')"
                          class="ui teal basic button"
                          >Download</a
                        ><span v-else>n/a</span>
                      </td>
                      <td v-if="isEditScreen">
                        <input
                          type="file"
                          @change="onFileChangedPastPerformance"
                        />
                      </td>
                      <td v-if="isEditScreen">
                        <a
                          href="#"
                          v-if="pastPerformanceUrl != ''"
                          @click.prevent="deleteFile('Past Performance')"
                          class="ui red basic button"
                          >Delete</a
                        >
                      </td>
                    </tr>
                    <tr v-if="fund.isHedgeFund">
                        <td>Gross Exposure Since Inception</td>
                        <td>
                            <a
                                    href="#"
                                    v-if="grossExposureInceptionUrl != ''"
                                    @click.prevent="downloadFile('Gross Exposure Since Inception')"
                                    class="ui teal basic button"
                            >Download</a
                            ><span v-else>n/a</span>
                        </td>
                        <td v-if="isEditScreen">
                            <input type="file" @change="onFileChangedGrossExposureInception" />
                        </td>
                        <td v-if="isEditScreen">
                            <a
                                    href="#"
                                    v-if="grossExposureInceptionUrl != ''"
                                    @click.prevent="deleteFile('Gross Exposure Since Inception')"
                                    class="ui red basic button"
                            >Delete</a
                            ><span v-else>n/a</span>
                        </td>
                    </tr>
                    <tr v-if="fund.isHedgeFund">
                        <td>Net Exposure Since Inception</td>
                        <td>
                            <a
                                    href="#"
                                    v-if="netExposureInceptionUrl != ''"
                                    @click.prevent="downloadFile('Net Exposure Since Inception')"
                                    class="ui teal basic button"
                            >Download</a
                            ><span v-else>n/a</span>
                        </td>
                        <td v-if="isEditScreen">
                            <input type="file" @change="onFileChangedNetExposureInception" />
                        </td>
                        <td v-if="isEditScreen">
                            <a
                                    href="#"
                                    v-if="netExposureInceptionUrl != ''"
                                    @click.prevent="deleteFile('Net Exposure Since Inception')"
                                    class="ui red basic button"
                            >Delete</a
                            ><span v-else>n/a</span>
                        </td>
                    </tr>
                    <tr v-if="fund.isHedgeFund">
                        <td>Beta Since Inception</td>
                        <td>
                            <a
                                    href="#"
                                    v-if="betaInceptionUrl != ''"
                                    @click.prevent="downloadFile('Beta Since Inception')"
                                    class="ui teal basic button"
                            >Download</a
                            ><span v-else>n/a</span>
                        </td>
                        <td v-if="isEditScreen">
                            <input type="file" @change="onFileChangedBetaInception" />
                        </td>
                        <td v-if="isEditScreen">
                            <a
                                    href="#"
                                    v-if="betaInceptionUrl != ''"
                                    @click.prevent="deleteFile('Beta Since Inception')"
                                    class="ui red basic button"
                            >Delete</a
                            ><span v-else>n/a</span>
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div v-if="tabId == 4" class="ui form">
              <div class="ui attached segment">
                <span v-if="isAdminUser(user)">
                  <table class="ui celled striped table">
                    <thead>
                      <tr>
                        <th>FILE TYPE</th>
                        <th>DOWNLOAD</th>
                        <th>UPLOAD</th>
                        <th>DELETE</th>
                        <th>SAVE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Admin Fund Comments</td>
                        <td>
                          <a
                            href="#"
                            v-if="adminFundCommentsUrl != ''"
                            @click.prevent="downloadFile('Admin Fund Comments')"
                            class="ui teal basic button"
                            >Download</a
                          ><span v-else>n/a</span>
                          
                        </td>
                        <td>
                          <input
                            type="file"
                            @change="onFileChangedAdminFundComments"
                          />
                        </td>
                        <td>
                          <a
                            href="#"
                            v-if="adminFundCommentsUrl != ''"
                            @click.prevent="deleteFile('Admin Fund Comments')"
                            class="ui red basic button"
                            >Delete</a
                          ><span v-else>n/a</span>
                        </td>
                        <td>
                          <sui-button
                            basic
                            primary
                            class="ui center floated"
                            @click.prevent="saveFund"
                            >Upload</sui-button
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Additional Due Diligence Document</td>
                        <td>
                          <a
                              href="#"
                              v-if="dDCommentsUrl != ''"
                              @click.prevent="downloadFile('Additional Due Diligence Document')"
                              class="ui teal basic button"
                          >Download</a
                          ><span v-else>n/a</span>
                        </td>
                        <td>
                          <input
                              type="file"
                              @change="onFileChangeddDComments"
                          />
                        </td>
                        <td>
                          <a
                              href="#"
                              v-if="dDCommentsUrl != ''"
                              @click.prevent="deleteFile('Additional Due Diligence Document')"
                              class="ui red basic button"
                          >Delete</a
                          ><span v-else>n/a</span>
                        </td>
                        <td>
                          <sui-button
                              basic
                              primary
                              class="ui center floated"
                              @click.prevent="saveFund"
                          >Upload</sui-button
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />

                  <br />
                  <div class="field">
                    <label>People Commentary </label>
                    <textarea
                      v-model="fund.peopleCommentary"
                      @keyup="peopleCommentaryChanged"
                    ></textarea>
                  </div>
                  <div
                    class="ui blue small button"
                    @click.prevent="savePeopleCommentary"
                    v-if="savePeopleCommentaryButton"
                  >
                    Save
                  </div>
                  <div
                    class="ui attached message"
                    v-else-if="fund.peopleCommentaryUpdatedBy"
                  >
                    Most recently updated by
                    {{ fund.peopleCommentaryUpdatedBy }} on the
                    {{ friendlyDate(fund.peopleCommentaryUpdatedAsAt) }}
                  </div>
                  <br />
                  <div class="field">
                    <label>KID - Strategy Overview</label>
                    <textarea
                      v-model="fund.processRiskCommentary"
                      @keyup="processRiskCommentaryChanged"
                    ></textarea>
                  </div>
                  <div
                    class="ui blue small button"
                    @click.prevent="saveProcessRiskCommentary"
                    v-if="saveProcessRiskCommentaryButton"
                  >
                    Save
                  </div>
                  <div
                    class="ui attached message"
                    v-else-if="fund.processRiskCommentaryUpdatedBy"
                  >
                    Most recently updated by
                    {{ fund.processRiskCommentaryUpdatedBy }} on the
                    {{ friendlyDate(fund.processRiskCommentaryUpdatedAsAt) }}
                  </div>
                  <br />
                  <div class="field">
                    <label>KID - Analytics Consulting Insight</label>
                    <textarea
                      v-model="fund.acInsightCommentary"
                      @keyup="acInsightCommentaryChanged"
                    ></textarea>
                  </div>
                  <div
                    class="ui blue small button"
                    @click.prevent="saveAcInsightCommentary"
                    v-if="saveAcInsightCommentaryButton"
                  >
                    Save
                  </div>
                  <div
                    class="ui attached message"
                    v-else-if="fund.acInsightCommentaryUpdatedBy"
                  >
                    Most recently updated by
                    {{ fund.acInsightCommentaryUpdatedBy }} on the
                    {{ friendlyDate(fund.acInsightCommentaryUpdatedAsAt) }}
                  </div>
                  <br />
                  <div class="field">
                    <label>For our eyes only </label>
                    <textarea
                      v-model="fund.privateCommentary"
                      @keyup="privateCommentaryChanged"
                    ></textarea>
                  </div>
                  <div
                    class="ui blue small button"
                    @click.prevent="savePrivateCommentary"
                    v-if="savePrivateCommentaryButton"
                  >
                    Save
                  </div>
                  <div
                    class="ui attached message"
                    v-else-if="fund.privateCommentaryUpdatedBy"
                  >
                    Most recently updated by
                    {{ fund.privateCommentaryUpdatedBy }} on the
                    {{ friendlyDate(fund.privateCommentaryUpdatedAsAt) }}
                  </div>
                  <br />
                  <sui-button
                    basic
                    positive
                    :loading="loadingExcel"
                    @click.prevent="downloadCommentsExcel"
                    >Download Comments Excel</sui-button
                  >
                  <sui-button
                    basic
                    color="violet"
                    class="ui right floated"
                    :loading="loadingExcelLog"
                    @click.prevent="downloadFundsExcelLog"
                    >Excel Downloads Log</sui-button
                  >
                  <br />
                </span>
              </div>
            </div>
            <br />
              
              <div v-if="tabId == 5" class="ui form">                
                  <sui-accordion class="ui attached message">
                      <a is="sui-accordion-title">
                          <sui-icon name="dropdown" />
                          <strong>OVERVIEW</strong>
                      </a>
                      <sui-accordion-content class="ui attached segment">
                          <br />         
                          <div class="two fields">
                              <div class="field">
                                  <label
                                  >Is the fund a Retail Investor Hedge Fund or Qualified Investor Hedge Fund?</label>
                                  <sui-dropdown
                                          :disabled="isEditScreen == false"
                                          fluid
                                          single
                                          :options="hedgeFundInvestorTypes"
                                          selection
                                          v-model="fund.hedgeFundInvestorType"
                                  />
                              </div>
                          </div>
                          <div class="field">
                              <label>Do the Portfolio Managers also manage any long-only strategies? If yes, please state which strategies.</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.portfolioManagersLongOnlyStrategies"
                              />
                          </div>
                      </sui-accordion-content>
                  </sui-accordion>
                  <br/>
                  <sui-accordion class="ui attached message">
                      <a is="sui-accordion-title">
                          <sui-icon name="dropdown" />
                          <strong>HEDGE FUND AUM AND CLIENT CONCENTRATION</strong>
                      </a>
                      <sui-accordion-content class="ui attached segment">
                          <div class="field">
                              <label>Does the strategy have an AUM cap? If yes, please state.</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.aumCap"
                              />
                          </div>
                          <div class="two fields">
                          <div class="field">
                              <label>What percentage of the strategy’s AUM do the 10 largest clients make up?(%)</label>
                              <input
                                      type="number"
                                      onkeyup="if(this.value<0){this.value=0}"
                                      onblur="if(this.value<0){this.value=0}"
                                      min="0"
                                      max="100"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.percentageAumTop10"
                              />
                          </div>
                              <div class="field">
                                  <label>How many clients (on an individual basis) make up more than 10% of the strategy’s AUM?</label>
                                  <input
                                          type="number"
                                          onkeyup="if(this.value<0){this.value=0}"
                                          onblur="if(this.value<0){this.value=0}" 
                                          min="0"
                                          max="100"
                                          :disabled="isEditScreen == false"
                                          v-model="fund.clientAumPercentage_10plus"
                                  />
                              </div>
                          </div>
                          <div class="two fields">
                              <div class="field">
                                  <label>How many hedge fund strategies do you manage?</label>
                                  <input
                                          type="number"
                                          onkeyup="if(this.value<0){this.value=0}"
                                          onblur="if(this.value%1 !== 0){this.value=0}"
                                          min="0"                                          
                                          :disabled="isEditScreen == false"
                                          v-model="fund.numHedgeFundsManaged"
                                  />
                              </div>
                              <div class="field">
                                  <label>What is the total of all hedge fund strategies AUM as a percentage of company’s total AUM? (%)</label>
                                  <input
                                          type="number"
                                          onkeyup="if(this.value<0){this.value=0}"
                                          onblur="if(this.value<0){this.value=0}"
                                          min="0"
                                          max="100"
                                          :disabled="isEditScreen == false"
                                          v-model="fund.percentageHfStrategiesAumTotalAum"
                                  />
                              </div>
                          </div>
                      </sui-accordion-content>
                  </sui-accordion>
                  <br/>
                  <sui-accordion class="ui attached message">
                      <a is="sui-accordion-title">
                          <sui-icon name="dropdown" />
                          <strong>STRATEGY AND OBJECTIVES</strong>
                      </a>
                      <sui-accordion-content class="ui attached segment">
                          <div class="field">
                              <label>Does the fund target a specific risk or volatility objective? If yes, please elaborate.</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.riskVolatilityObjective"
                              />
                          </div> 
                          <div class="field">
                          <label>What is the expected beta of the fund?</label>
                          <input
                                  type="text"
                                  :disabled="isEditScreen == false"
                                  v-model="fund.expectedFundBeta"
                          />
                          </div>
                          <h3 class="ui dividing header" style="text-align: center">
                              Typical range of number of Long positions in the fund.
                          </h3>
                          <br/>
                          <div class="two fields">
                              <div class="field">
                                  <label>Upper Bound.</label>
                                  <input
                                          type="number"
                                          onkeyup="if(this.value<0){this.value=0}"
                                          onblur="if(this.value<0){this.value=0}"
                                          min="0"                                          
                                          :disabled="isEditScreen == false"
                                          v-model="fund.longPositionsUpper"
                                  />
                              </div>
                              <div class="field">
                                  <label>Lower Bound.</label>
                                  <input
                                          type="number"
                                          onkeyup="if(this.value<0){this.value=0}"
                                          onblur="if(this.value<0){this.value=0}"
                                          min="0"                                         
                                          :disabled="isEditScreen == false"
                                          v-model="fund.longPositionsLower"
                                  />
                              </div>
                          </div>
                          <h3 class="ui dividing header" style="text-align: center">
                              Typical range of number of Short positions in the fund.
                          </h3>
                          <br/>
                          <div class="two fields">
                              <div class="field">
                                  <label>Upper Bound.</label>
                                  <input
                                          type="number"
                                          onkeyup="if(this.value<0){this.value=0}"
                                          onblur="if(this.value<0){this.value=0}"
                                          min="0"
                                          :disabled="isEditScreen == false"
                                          v-model="fund.shortPositionsUpper"
                                  />
                              </div>
                              <div class="field">
                                  <label>Lower Bound.</label>
                                  <input
                                          type="number"
                                          onkeyup="if(this.value<0){this.value=0}"
                                          onblur="if(this.value<0){this.value=0}"
                                          min="0"
                                          :disabled="isEditScreen == false"
                                          v-model="fund.shortPositionsLower"
                                  />
                              </div>
                          </div>                          
                          <h3 class="ui dividing header" >
                              </h3>
                          <br/>
                          
                          <div class="two fields">
                              <div class="field">
                                  <label>What is the typical position size for long positions?</label>
                                  <input
                                          type="text"
                                          :disabled="isEditScreen == false"
                                          v-model="fund.typicalLongPositionsSize"
                                  />
                              </div>
                              <div class="field">
                                  <label>What is the max position size for long positions?</label>
                                  <input
                                          type="number"
                                          onkeyup="if(this.value<0){this.value=0}"
                                          onblur="if(this.value<0){this.value=0}"
                                          min="0"                                         
                                          :disabled="isEditScreen == false"
                                          v-model="fund.maxLongPositionsSize"
                                  />
                              </div>
                          </div>
                          <div class="two fields">
                              <div class="field">
                                  <label>What is the typical position size for short positions?</label>
                                  <input
                                          type="text"
                                          :disabled="isEditScreen == false"
                                          v-model="fund.typicalShortPositionsSize"
                                  />
                              </div>
                              <div class="field">
                                  <label>What is the max position size for short positions?</label>
                                  <input
                                          type="number"
                                          onkeyup="if(this.value<0){this.value=0}"
                                          onblur="if(this.value<0){this.value=0}"
                                          min="0"
                                          :disabled="isEditScreen == false"
                                          v-model="fund.maxShortPositionsSize"
                                  />
                              </div>
                          </div>
                          <div class="field">
                              <label>Do you manage your long and short portfolios separately, or as one? Please elaborate.</label>
                              <textarea                                      
                                      :disabled="isEditScreen == false"
                                      v-model="fund.longShortPortfolioManagement"
                              />
                          </div>
                          <div class="field">
                              <label>Are short positions used for profit seeking or risk mitigation purposes?</label>
                              <sui-dropdown
                                      :disabled="isEditScreen == false"
                                      fluid
                                      single
                                      :options="shortPositionPurposes"
                                      selection
                                      v-model="fund.shortPositionPurpose"
                              />
                          </div>
                          <div class="field">
                              <label>Please explain if short positions are used in pair trades or in absolute positions?</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.shortPositionsStrategy"
                              />
                          </div>
                          <div class="three fields">
                          <div class="field">
                              <label>What is the typical exposure to large caps?</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.exposureLargeCap"
                              />
                          </div>
                              <div class="field">
                                  <label>What is the typical exposure to mid caps?</label>
                                  <input
                                          type="text"
                                          :disabled="isEditScreen == false"
                                          v-model="fund.exposureMidCap"
                                  />
                              </div>
                              <div class="field">
                                  <label>What is the typical exposure to small caps?</label>
                                  <input
                                          type="text"
                                          :disabled="isEditScreen == false"
                                          v-model="fund.exposureSmallCap"
                                  />
                              </div>
                          </div>
                          <div class="field">
                              <label>Does the fund invest offshore? If yes, what is the maximum offshore exposure?</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.offshoreMaxExposure"
                              />
                          </div>
                          <div class="field">
                              <label>If the fund is multi-asset, what is the expected equity exposure/allocation in the fund?</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.multiAssetExpectedEquityExposure"
                              />
                          </div>
                      </sui-accordion-content>
                  </sui-accordion>
                  <br/>
                  <sui-accordion class="ui attached message">
                      <a is="sui-accordion-title">
                          <sui-icon name="dropdown" />
                          <strong>EXPOSURE AND RISK MANAGEMENT</strong>
                      </a>
                      <sui-accordion-content class="ui attached segment">
                          <div class="two fields">
                          <div class="field">
                              <label>What is the fund’s expected average gross exposure?</label>                              
                              <input
                                      type="number"
                                      onkeyup="if(this.value<0){this.value=0}"
                                      onblur="if(this.value<0){this.value=0}"
                                      min="0"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.expectedAverageGrossExposure"
                              />                              
                          </div>
                          <div class="field">
                              <label>What is the fund's expected average net exposure?</label>
                              <input
                                      type="number"
                                      onkeyup="if(this.value<0){this.value=0}"
                                      onblur="if(this.value<0){this.value=0}"
                                      min="0"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.expectedAverageNetExposure"
                              />
                          </div>
                          </div>
                          <div class="field">
                              <label>Do you measure gross exposure using the VaR or Commitment approach?</label>
                              <sui-dropdown
                                      :disabled="isEditScreen == false"
                                      fluid
                                      single
                                      :options="grossExposureMeasureMethods"
                                      selection
                                      v-model="fund.grossExposureMeasurementMethod"
                              />
                          </div>
                          <div class="field">
                              <label>Please elaborate on the chosen method above.</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.grossExposureMeasurementMethodDetails"
                              />
                          </div>
                          <div class="field">
                              <label>Has the method changed since inception? If yes, please elaborate further.</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.grossExposureMeasurementMethodChanges"
                              />
                          </div>
                          <div class="field">
                              <label>If the fund is market neutral, what is the range that the net exposure is managed within?</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.marketNeutralNetExposureRange"
                              />
                          </div>
                          <div class="field">
                              <label>Are there any sector limits in place? Please elaborate, specifically if there are differences between the long and short book.</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.sectorLimitsBookDifferences"
                              />
                          </div>
                          <div class="field">
                              <label>Are there any specific liquidity considerations, especially regarding short positions? </label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.liquidityConsiderations"
                              />
                          </div>
                          <div class="field">
                              <label>What is your acceptable range of borrowing costs for short selling?</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.shortSellingBorrowingCostRange"
                              />
                          </div>
                          <div class="field">
                              <label>Which Prime brokers are used?</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.primeBrokersUsed"
                              />
                          </div>
                          <div class="field">
                              <label>Who checks mandate breaches and how?</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.mandateBreachChecking"
                              />
                          </div>
                          <div class="field">
                              <label>What is the longest the fund has been in breach? Please explain the reasoning of the breach.</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.longestBreachDurationReasoning"
                              />
                          </div>
                      </sui-accordion-content>
                  </sui-accordion>
                  <br/>
                  <sui-accordion class="ui attached message">
                      <a is="sui-accordion-title">
                          <sui-icon name="dropdown" />
                          <strong>FEE STRUCTURE</strong>
                      </a>
                      <sui-accordion-content class="ui attached segment">
                          <div class="field">
                              <label>Please explain the fund’s fee structure, (including management fee; participation rate; hurdle rate)</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.feeStructureDetails"
                              />
                          </div>
                          <div class="two fields">
                          <div class="field">
                              <label>Does a high-water mark apply?</label>
                              <sui-dropdown
                                      :disabled="isEditScreen == false"
                                      fluid
                                      single
                                      :options="yesNoOptionsBoolean"
                                      selection
                                      v-model="fund.highWaterMarkApplied"
                              />
                          </div>
                          </div>
                          <div class="field">
                              <label>When do performance fees crystalize?</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.performanceFeeCrystalizeTiming"
                              />
                          </div>
                          <div class="field">
                              <label>Is there a fee cap? If yes, please specify.</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.feeCapDetails"
                              />
                          </div>
                          <div class="field">
                              <label>Please explain any fee structure nuances, if any.</label>
                              <input
                                      type="text"
                                      :disabled="isEditScreen == false"
                                      v-model="fund.feeStructureNuances"
                              />
                          </div>
                      </sui-accordion-content>
                  </sui-accordion>
                          <br />
              </div>            

            <br />
              <sui-button
                      basic
                      positive
                      class="ui right floated"
                      v-if="isEditScreen == false && success == false && failed == false"
                      @click.prevent="isEditScreen = true"
              >Edit</sui-button
              >
              <sui-button
                      basic
                      primary
                      class="ui right floated"
                      v-if="isEditScreen != false && success == false && failed == false"
                      @click.prevent="saveFund"
              >Save</sui-button
              >              
              <br/>
              <div class="ui positive message" v-if="success">
                  <i class="close icon" @click.prevent="success = false; isEditScreen = false"></i>
                  <div class="header">Success</div>
                  <p>
                      Click <router-link to="../funds">here</router-link> to go
                      back to all funds
                  </p>
              </div>
              <div class="ui negative message" v-if="failed">
                  <i class="close icon" @click.prevent="success = false; isEditScreen = false"></i>
                  <div class="header">Failed</div>
                  <p>
                      Click <router-link to="../../funds">here</router-link> to go
                      back to all funds or try again.
                  </p>
              </div>            
            <br />              
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFund,
  createFund,
  getCompanies,
  fileDownload,
  uploadFile,
  fileDelete,
  saveFundCommentary,
  downloadFundsCommentsExcel,
  downloadFundsExcelLog
} from "@/gateway";
import moment from "moment";
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
        failed : false,
      loadingExcel: false,
      loadingExcelLog: false,
      companies: [],
      fundName: "",
      popiNoticeChecked: false,
      peopleCommentary: "",
      processRiskCommentary: "",
      acInsightCommentary: "",
      privateCommentary: "",
      userFundCommentary: "",
      newDecisionMaker: {
        name: "",
        isEquityOwner: false,
        startDate: "",
        endDate: "",
        position: ""
      },
      //
      newFundShareClass: {
        fundShareClassName: "",
        isin: "",
        jseCode: "",
        platformAvailability: []
      },
      success: false,
      isEditScreen: false,
      id: 0,
      fund: null,
      tabId: 1,
      hasFixedIncome: false,
      fundType: [],
      fundTypes: [
        { value: "Bonds", text: "Bonds" },
        { value: "Equity", text: "Equity" },
        { value: "Income", text: "Income" },
        { value: "Money Market", text: "Money Market" },
        { value: "Multi-Asset", text: "Multi-Asset" },
        { value: "Property", text: "Property" }
      ],
        
        //ALl the new Hedge Fund related variables
        hedgeFundInvestorTypes: [
            { value: "Retail Investor Hedge Fund" , text: "Retail Investor Hedge Fund"  },
            { value: "Qualified  Investor Hedge Fund" , text: "Qualified  Investor Hedge Fund"  }
        ],
        investmentUniversesHedged: [
            { value: "Equity" , text: "Equity"  },
            { value: "Fixed Income" , text: "Fixed Income" },
            { value: "Multi-Asset" , text: "Multi-Asset"  }
        ],
        asisaHedgeFundClassifications: [
            { value: "SA - Long Short Equity Hedge Funds - Long Bias Equity" , text: "SA - Long Short Equity Hedge Funds - Long Bias Equity"  },
            { value: "SA - Long Short Equity Hedge Funds - Market Neutral" , text: "SA - Long Short Equity Hedge Funds - Market Neutral" },
            { value: "SA - Long Short Equity Hedge Funds - Other Equity" , text: "SA - Long Short Equity Hedge Funds - Other Equity"  },
            { value: "SA - Fixed Income" , text: "SA - Fixed Income"  },
            { value: "SA - Multi-Strategy" , text: "SA - Multi-Strategy"  },
            { value: "SA - Other" , text: "SA - Other"  },
            { value: "Worldwide - Long Short Equity Hedge Funds - Long Bias Equity" , text: "Worldwide - Long Short Equity Hedge Funds - Long Bias Equity"  },
            { value: "Worldwide - Long Short Equity Hedge Funds - Market Neutral" , text: "Worldwide - Long Short Equity Hedge Funds - Market Neutral"  },
            { value: "Worldwide - Long Short Equity Hedge Funds - Other Equity" , text: "Worldwide - Long Short Equity Hedge Funds - Other Equity"  },
            { value: "Worldwide - Fixed Income" , text: "Worldwide - Fixed Income"  },
            { value: "Worldwide - Multi-Strategy" , text: "Worldwide - Multi-Strategy"  },
            { value: "Worldwide - Other" , text: "Worldwide - Other"  },
            { value: "Global - Long Short Equity Hedge Funds - Long Bias Equity" , text: "Global - Long Short Equity Hedge Funds - Long Bias Equity"  },
            { value: "Global - Long Short Equity Hedge Funds - Market Neutral" , text: "Global - Long Short Equity Hedge Funds - Market Neutral"  },
            { value: "Global - Long Short Equity Hedge Funds - Other Equity" , text: "Global - Long Short Equity Hedge Funds - Other Equity"  },
            { value: "Global - Fixed Income" , text: "Global - Fixed Income"  },
            { value: "Global - Multi-Strategy" , text: "Global - Multi-Strategy"  },
            { value: "Global - Other" , text: "Global - Other"  },
            { value: "Regional - Long Short Equity Hedge Funds - Long Bias Equity" , text: "Regional - Long Short Equity Hedge Funds - Long Bias Equity"  },
            { value: "Regional - Long Short Equity Hedge Funds - Market Neutral" , text: "Regional - Long Short Equity Hedge Funds - Market Neutral"  },
            { value: "Regional - Long Short Equity Hedge Funds - Other Equity" , text: "Regional - Long Short Equity Hedge Funds - Other Equity"  },
            { value: "Regional - Fixed Income" , text: "Regional - Fixed Income"  },
            { value: "Regional - Multi-Strategy" , text: "Regional - Multi-Strategy"  },
            { value: "Regional - Other" , text: "Regional - Other" },
            {value: "Not Applicable", text: "Not Applicable" }
        ],
        shortPositionPurposes : [
            { value: "Profit seeking" , text: "Profit seeking"  },
            { value: "Risk mitigation" , text: "Risk mitigation" },
            { value: "Both" , text: "Both"  }
        ],
        grossExposureMeasureMethods : [
            { value: "Commitment approach" , text: "Commitment approach"  },
            { value: "VaR approach" , text: "VaR approach" }          
        ],
        //
   
      yesNoOptions: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      yesNoOptionsBoolean: [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
      ],
      includesGlobal: [],
      includesGlobals: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      reg28: [],
      reg28s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      section65: [],
      section65s: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" },
        { value: "Not Applicable", text: "Not Applicable" }
      ],
      asisaCategory: [],
      asisaCategories: [
        {
          value: "(ASISA) Global EQ General",
          text: "(ASISA) Global EQ General"
        },
        {
          value: "(ASISA) Global EQ Unclassified",
          text: "(ASISA) Global EQ Unclassified"
        },
        {
          value: "(ASISA) Global IB Short Term",
          text: "(ASISA) Global IB Short Term"
        },
        {
          value: "(ASISA) Global IB Variable Term",
          text: "(ASISA) Global IB Variable Term"
        },
        {
          value: "(ASISA) Global MA Flexible",
          text: "(ASISA) Global MA Flexible"
        },
        {
          value: "(ASISA) Global MA High Equity",
          text: "(ASISA) Global MA High Equity"
        },
        { value: "(ASISA) Global MA Income", text: "(ASISA) Global MA Income" },
        {
          value: "(ASISA) Global MA Low Equity",
          text: "(ASISA) Global MA Low Equity"
        },
        {
          value: "(ASISA) Global MA Medium Equity",
          text: "(ASISA) Global MA Medium Equity"
        },
        {
          value: "(ASISA) Global RE General",
          text: "(ASISA) Global RE General"
        },
        {
          value: "(ASISA) Regional EQ General",
          text: "(ASISA) Regional EQ General"
        },
        {
          value: "(ASISA) Regional IB Short Term",
          text: "(ASISA) Regional IB Short Term"
        },
        {
          value: "(ASISA) Regional IB Variable Term",
          text: "(ASISA) Regional IB Variable Term"
        },
        {
          value: "(ASISA) Regional MA Flexible",
          text: "(ASISA) Regional MA Flexible"
        },
        {
          value: "(ASISA) Regional RE General",
          text: "(ASISA) Regional RE General"
        },
        {
          value: "(ASISA) South African EQ Financial",
          text: "(ASISA) South African EQ Financial"
        },
        {
          value: "(ASISA) South African EQ General",
          text: "(ASISA) South African EQ General"
        },
        {
          value: "(ASISA) South African EQ Industrial",
          text: "(ASISA) South African EQ Industrial"
        },
        {
          value: "(ASISA) South African EQ Large Cap",
          text: "(ASISA) South African EQ Large Cap"
        },
        {
          value: "(ASISA) South African EQ Mid/Small Cap",
          text: "(ASISA) South African EQ Mid/Small Cap"
        },
        {
          value: "(ASISA) South African EQ Resources",
          text: "(ASISA) South African EQ Resources"
        },
        {
          value: "(ASISA) South African EQ Unclassified",
          text: "(ASISA) South African EQ Unclassified"
        },
        {
          value: "(ASISA) South African IB Money Market",
          text: "(ASISA) South African IB Money Market"
        },
        {
          value: "(ASISA) South African IB Short Term",
          text: "(ASISA) South African IB Short Term"
        },
        {
          value: "(ASISA) South African IB Variable Term",
          text: "(ASISA) South African IB Variable Term"
        },
        {
          value: "(ASISA) South African MA Flexible",
          text: "(ASISA) South African MA Flexible"
        },
        {
          value: "(ASISA) South African MA High Equity",
          text: "(ASISA) South African MA High Equity"
        },
        {
          value: "(ASISA) South African MA Income",
          text: "(ASISA) South African MA Income"
        },
        {
          value: "(ASISA) South African MA Low Equity",
          text: "(ASISA) South African MA Low Equity"
        },
        {
          value: "(ASISA) South African MA Medium Equity",
          text: "(ASISA) South African MA Medium Equity"
        },
        {
          value: "(ASISA) South African MA Target Date",
          text: "(ASISA) South African MA Target Date"
        },
        {
          value: "(ASISA) South African RE General",
          text: "(ASISA) South African RE General"
        },
        { value: "(ASISA) Wwide EQ General", text: "(ASISA) Wwide EQ General" },
        {
          value: "(ASISA) Wwide MA Flexible",
          text: "(ASISA) Wwide MA Flexible"
        },
        {
            value: "Not Applicable", text: "Not Applicable"
        }
      ],
      investmentApproach: [],
      investmentApproaches: [
        { value: "Passive", text: "Passive" },
        { value: "Smart Beta", text: "Smart Beta" },
        { value: "Quantitative", text: "Quantitative" },
        { value: "Fundamental Bottom-up", text: "Fundamental Bottom-up" },
        { value: "Macro Top-down", text: "Macro Top-down" },
        {
          value: "Bottom-up/top-down Combination",
          text: "Bottom-up/top-down Combination"
        }
      ],
      investmentStyle: [],
      investmentStyles: [
        { value: "Absolute", text: "Absolute" },
        { value: "Credit", text: "Credit" },
        { value: "Factor Investing ", text: "Factor Investing" },
        { value: "GARP", text: "GARP" },
        { value: "Growth", text: "Growth" },
        { value: "Momentum", text: "Momentum" },
        { value: "Multi-factor", text: "Multi-factor" },
        { value: "Passive", text: "Passive" },
        { value: "Quality", text: "Quality" },
        { value: "Style Agnostic", text: "Style Agnostic" },
        { value: "Thematic", text: "Thematic" },
        { value: "Value", text: "Value" },
        { value: "Volatility Targeting", text: "Volatility Targeting" }
      ],
      fundAumCurrency: [],
      fundAumCurrencies: [
        { value: "ZAR", text: "ZAR" },
        { value: "USD", text: "USD" },
        { value: "EUR", text: "EUR" },
        { value: "GBP", text: "GBP" },
        { value: "JPY", text: "JPY" },
        { value: "RMB", text: "RMB" }
      ],
      fundBenchmark: [],
      fundBenchmarks: [
        { value: "STeFi Call", text: "STeFi Call" },
        { value: "STeFi Composite", text: "STeFi Composite" },
        { value: "3m JIBAR", text: "3m JIBAR" },
        { value: "ALBI 1-3 years", text: "ALBI 1-3 years" },
        { value: "ALBI", text: "ALBI" },
        { value: "STeFi + 1%", text: "STeFi + 1%" },
        { value: "STeFi + 2%", text: "STeFi + 2%" },
        { value: "STeFi + 3%", text: "STeFi + 3%" },
        { value: "STeFi Call", text: "STeFi Call" },
        { value: "ALSI (J203)", text: "ALSI (J203)" },
        { value: "SWIX", text: "SWIX" },
        { value: "Capped SWIX", text: "Capped SWIX" },
        { value: "MSCI World", text: "MSCI World" },
        { value: "MSCI ACWI", text: "MSCI ACWI" },
        { value: "ALPI", text: "ALPI" },
        { value: "SA REIT", text: "SA REIT" },
        { value: "FTSE EPRA NAREIT", text: "FTSE EPRA NAREIT" },
        { value: "SAA", text: "SAA" },
        { value: "SA CPI + 1%", text: "SA CPI + 1%" },
        { value: "SA CPI + 2%", text: "SA CPI + 2%" },
        { value: "SA CPI + 3%", text: "SA CPI + 3%" },
        { value: "SA CPI + 4%", text: "SA CPI + 4%" },
        { value: "SA CPI + 5%", text: "SA CPI + 5%" },
        { value: "SA CPI + 6%", text: "SA CPI + 6%" },
        { value: "SA CPI + 7%", text: "SA CPI + 7%" },
        { value: "US CPI + 1%", text: "US CPI + 1%" },
        { value: "US CPI + 2%", text: "US CPI + 2%" },
        { value: "US CPI + 3%", text: "US CPI + 3%" },
        { value: "US CPI + 4%", text: "US CPI + 4%" },
        { value: "US CPI + 5%", text: "US CPI + 5%" },
        { value: "US CPI + 6%", text: "US CPI + 6%" },
        { value: "US CPI + 7%", text: "US CPI + 7%" },
        { value: "ASISA Peer Group Average", text: "ASISA Peer Group Average" },
        { value: "ASISA Peer Group Median", text: "ASISA Peer Group Median" },
        { value: "Other", text: "Other" }
      ],
      trackingError: [],
      trackingErrors: [
          {value: "Not Applicable", text: "Not Applicable" },
        { value: "0% - 0.5%", text: "0% - 0.5%" },
        { value: "0.5% - 1%", text: "0.5% - 1%" },
        { value: "1% - 3%", text: "1% - 3%" },
        { value: "3% - 6%", text: "3% - 6%" },
        { value: "6% - 10%", text: "6% - 10%" },
        { value: "> 10%", text: "> 10%" }
      ],
      globalExposureSource: [],
      globalExposureSources: [
        { value: "Passive", text: "Passive" },
        { value: "In-House", text: "In-House" },
        { value: "Outsourced", text: "Outsourced" },
        {
          value: "Combination of In-House and Outsourced",
          text: "Combination of In-House and Outsourced"
        },
        { value: "Not Applicable", text: "Not Applicable" }
      ],
      globalExposureAccess: [],
      globalExposureAccesses: [
        { value: "Fund", text: "Fund" },
        { value: "Direct", text: "Direct" },
        { value: "ETF's", text: "ETF's" },
        { value: "Combination", text: "Combination" }
      ],
      globalZeroFeeClass: [],
      globalZeroFeeClasses: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" },
        { value: "Not Applicable", text: "Not Applicable" }
      ],
      multiAssetAllocation: [],
      multiAssetAllocations: [
        { value: "Own stock selection", text: "Own stock selection" },
        { value: "Houseview carveout", text: "Houseview carveout" },
        { value: "Not Applicable", text: "Not Applicable" }
      ],
      internalCreditRatings: [],
      internalCreditsRatings: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      internalOrExternalCreditRatings: [],
      internalOrExternalCreditsRatings: [
        { value: "Internal Rating", text: "Internal Rating" },
        { value: "S&P", text: "S&P" },
        { value: "Moody's", text: "Moody's" },
        { value: "Fitch", text: "Fitch" },
        {
          value: "Combination of external rating agencies",
          text: "Combination of external rating agencies"
        }
      ],
      creditLinkedNotes: [],
      creditsLinkedNotes: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      ownInvestmentStructures: [],
      ownInvestmentsStructures: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" },
        { value: "Not Applicable", text: "Not Applicable" }
      ],
      repriceIlliquidInternally: [],
      repriceIlliquidsInternally: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      aaCommittee: [],
      aACommittees: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      saaUsed: [],
      sAAsUsed: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" },
        { value: "NA", text: "Not applicable" }
      ],
      doesFundUseTaa: [],
      doesFundUseTaas: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" },
        { value: "NA", text: "Not applicable" }
      ],
      taaBands: [],
      taasBands: [
        { value: "+/- 5%", text: "+/- 5%" },
        { value: "+/- 10%", text: "+/- 10%" },
        { value: "+/- 20%", text: "+/- 20%" },
        { value: "Unlimited", text: "Unlimited" }
      ],
      tradeExecution: [],
      tradeExecutions: [
        { value: "Internal", text: "Internal" },
        { value: "Outsourced to brokers", text: "Outsourced to brokers" },
        {
          value: "Combination of Internal & External brokers",
          text: "Combination of Internal & External brokers"
        }
      ],
      derivativesUsed: [],
      derivativesUseds: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      currencyHedging: [],
      currencyHedgings: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      derivativesUsedFor: [],
      derivativesUsedForsSimple: ["Efficient portfolio management",
        "Protection","Yield Enhancement "],
      derivativesUsedFors: [
        {
          value: "Efficient portfolio management",
          text: "Efficient portfolio management"
        },
        { value: "Protection", text: "Protection" },
        { value: "Yield Enhancement ", text: "Yield Enhancement " }
      ],
      fundControversialCompaniesExcluded: [],
      fundControversialCompaniesExcludeds: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      fundESGFocus: [],
      fundESGsFocus: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      fundPMIncludedInESGAnalysis: [],
      fundPMIncludedsInESGAnalysis: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      fundControversialCompaniesCanInvest: [],
      fundControversialCompaniesCanInvests: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      fundESGComplianceProcess: [],
      fundESGCompliancesProcess: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" }
      ],
      fundESGCertified: [],
      fundESGCertifieds: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No"},
          {value: "Not Applicable", text: "Not Applicable" }
      ],
      platforms: [
        { value: "AIMS", text: "AIMS" },
        { value: "Allan Gray", text: "Allan Gray" },
        { value: "Glacier", text: "Glacier" },
        { value: "Glacier International", text: "Glacier International" },
        { value: "Hollard", text: "Hollard" },
        { value: "INN8", text: "INN8" },
        { value: "Momentum", text: "Momentum" },
        { value: "Ninety One", text: "Ninety One" },
        { value: "Old Mutual", text: "Old Mutual" },
        { value: "PPS", text: "PPS" },
        { value: "STANLIB", text: "STANLIB" },
        { value: "Sygnia", text: "Sygnia" },
        { value: "Wealth Foundry", text: "Wealth Foundry" },
        { value: "Wealthport", text: "Wealthport" }
      ],
      fundPortfolioManagers: 0,
      fundMacroStrategists: 0,    
      fundGraduates: 0,
      fundTraders: 0,
      saEquity: 0,
      saProperty: 0,
      saBonds: 0,
      saILBs: 0,
      saMoneyMarket: 0,
      globalEquity: 0,
      globalProperty: 0,
      globalBonds: 0,
      globalMoneyMarket: 0,
      africa: 0,
      alternatives: 0,
      selectedFilePastPerformance: null,
      selectedFileProcess: null,
      selectedFilePhilosophy: null,
      selectedFileRiskManagement: null,
      selectedFileAnnualStewardship: null,
      selectedFileAdminFundComments: null,
      selectedFiledDComments: null,
        // new hedge fund specific document vars
        selectedFileGrossExposureInception: null,
        selectedFileNetExposureInception: null,
        selectedFileBetaInception: null,
      //n
      savePeopleCommentaryButton: false,
      saveProcessRiskCommentaryButton: false,
      saveAcInsightCommentaryButton: false,
      savePrivateCommentaryButton: false

      //  savePeopleCommentaryUpdatedBy: "",
      //  savePeopleCommentaryUpdatedAsAt: null
    };
  },

  methods: {  
    friendlyDate(val) {
      return moment(val).format("Do [of] MMMM yyyy");
    },
    peopleCommentaryChanged() {
      this.savePeopleCommentaryButton = true;
    },
    async savePeopleCommentary() {
      const response = await saveFundCommentary({
        type: "peopleCommentary",
        value: this.fund.peopleCommentary,
        id: this.fund.id
      });
      if (response) {
        if (response.result == true) {
          this.savePeopleCommentaryButton = false;
          this.fund.peopleCommentary = response.fund.peopleCommentary;
        }
      }
    },
    processRiskCommentaryChanged() {
      this.saveProcessRiskCommentaryButton = true;
    },
    async saveProcessRiskCommentary() {
      const response = await saveFundCommentary({
        type: "processRiskCommentary",
        value: this.fund.processRiskCommentary,
        id: this.fund.id
      });
      if (response) {
        if (response.result == true) {
          this.saveProcessRiskCommentaryButton = false;
          this.fund.processRiskCommentary = response.fund.processRiskCommentary;
        }
      }
    },
    acInsightCommentaryChanged() {
      this.saveAcInsightCommentaryButton = true;
    },
    async saveAcInsightCommentary() {
      const response = await saveFundCommentary({
        type: "acInsightCommentary",
        value: this.fund.acInsightCommentary,
        id: this.fund.id
      });
      if (response) {
        if (response.result == true) {
          this.saveAcInsightCommentaryButton = false;
          this.fund.acInsightCommentary = response.fund.acInsightCommentary;
        }
      }
    },
    privateCommentaryChanged() {
      this.savePrivateCommentaryButton = true;
    },
    async savePrivateCommentary() {
      const response = await saveFundCommentary({
        type: "privateCommentary",
        value: this.fund.privateCommentary,
        id: this.fund.id
      });
      if (response) {
        if (response.result == true) {
          this.savePrivateCommentaryButton = false;
          this.fund.privateCommentary = response.fund.privateCommentary;
        }
      }
    },
    isAdminUser(user) {
      if (user.roles) {
        if (user.roles.includes("Admin")) {
          return true;
        } else return false;
      } else {
        return false;
      }
    },
    async downloadCommentsExcel() {
      this.loadingExcel = true;
      const response = await downloadFundsCommentsExcel();
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data);
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        this.loadingExcel = false;
        //
        link.click();
      }
    },
    async downloadFundsExcelLog() {
      this.loadingExcelLog = true;
      const response = await downloadFundsExcelLog();
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data);
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        this.loadingExcelLog = false;
        //
        link.click();
      }
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    async downloadFile(fileType) {
      const response = await fileDownload({
        fileType: fileType,
        entityType: "Fund",
        entityId: this.fund.id
      });
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data);
        console.log("response", new Blob([byteArray]));
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: "application/octet-stream" })
        );
        console.log("url", url);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    async deleteFile(fileType) {
      const response = await fileDelete({
        fileType: fileType,
        entityType: "Fund",
        entityId: this.fund.id
      });
      if (response) this.init();
    },
    onFileChanged(event) {
      this.selectedFile = event.target.files[0];
    },
    onFileChangedPastPerformance(event) {
      this.selectedFilePastPerformance = event.target.files[0];
    },
    onFileChangedPhilosophy(event) {
      this.selectedFilePhilosophy = event.target.files[0];
    },
    onFileChangedProcess(event) {
      this.selectedFileProcess = event.target.files[0];
    },
    onFileChangedRiskManagement(event) {
      this.selectedFileRiskManagement = event.target.files[0];
    },
    onFileChangedAnnualStewardship(event) {
      this.selectedFileAnnualStewardship = event.target.files[0];
    },
    onFileChangedAdminFundComments(event) {
      this.selectedFileAdminFundComments = event.target.files[0];
    }, 
    onFileChangeddDComments(event) {
      this.selectedFiledDComments = event.target.files[0];
    },
      //New hedge fund specific upload documents
      onFileChangedGrossExposureInception(event) {
          this.selectedFileGrossExposureInception= event.target.files[0];
      },
      onFileChangedNetExposureInception(event) {
          this.selectedFileNetExposureInception = event.target.files[0];
      },
      onFileChangedBetaInception(event) {
          this.selectedFileBetaInception = event.target.files[0];
      },
    async onUpload() {
      if (this.selectedFilePastPerformance) {
        const r = await uploadFile({
          selectedFile: this.selectedFilePastPerformance,
          entityType: "Fund",
          entityId: this.fund.id,
          fileType: "Past Performance"
        });
      }

      if (this.selectedFileProcess) {
        const r = await uploadFile({
          selectedFile: this.selectedFileProcess,
          entityType: "Fund",
          entityId: this.fund.id,
          fileType: "Investment Process"
        });
      }
      if (this.selectedFilePhilosophy) {
        const r = await uploadFile({
          selectedFile: this.selectedFilePhilosophy,
          entityType: "Fund",
          entityId: this.fund.id,
          fileType: "Investment Philosophy"
        });
      }
      if (this.selectedFileRiskManagement) {
        const r = await uploadFile({
          selectedFile: this.selectedFileRiskManagement,
          entityType: "Fund",
          entityId: this.fund.id,
          fileType: "Risk Management Framework"
        });
      }
      if (this.selectedFileAnnualStewardship) {
        const r = await uploadFile({
          selectedFile: this.selectedFileAnnualStewardship,
          entityType: "Fund",
          entityId: this.fund.id,
          fileType: "Annual Stewardship"
        });
      }
      if (this.selectedFileAdminFundComments) {
        const r = await uploadFile({
          selectedFile: this.selectedFileAdminFundComments,
          entityType: "Fund",
          entityId: this.fund.id,
          fileType: "Admin Fund Comments"
        });
      }
      if (this.selectedFileAdminFundComments) {
        const r = await uploadFile({
          selectedFile: this.selectedFiledDComments,
          entityType: "Fund",
          entityId: this.fund.id,
          fileType: "Additional Due Diligence Document"
        });
      }
      // New Hedge Fund specific Uploads
        if (this.selectedFileGrossExposureInception) {
            const r = await uploadFile({
                selectedFile: this.selectedFileGrossExposureInception,
                entityType: "Fund",
                entityId: this.fund.id,
                fileType: "Gross Exposure Since Inception"
            });
        }
        if (this.selectedFileNetExposureInception) {
            const r = await uploadFile({
                selectedFile: this.selectedFileNetExposureInception,
                entityType: "Fund",
                entityId: this.fund.id,
                fileType: "Net Exposure Since Inception"
            });
        }
        if (this.selectedFileBetaInception) {
            const r = await uploadFile({
                selectedFile: this.selectedFileBetaInception,
                entityType: "Fund",
                entityId: this.fund.id,
                fileType: "Beta Since Inception"
            });
        }
    },
    addDecisionMaker(newPerson) {
      this.fund.keyDecisionMakers.push(newPerson);
      this.newDecisionMaker = {
        name: "",
        isEquityOwner: false,
        startDate: "",
        endDate: "",
        position: ""
      };
    },
    removeKeyDecisionMaker(index) {
      this.fund.keyDecisionMakers.splice(index, 1);
    },
    addFundShareClass(newClass) {
      this.fund.fundShareClasses.push(newClass);
      this.newFundShareClass = {
        fundShareClassName: "",
        isin: "",
        jseCode: "",
        platformAvailability: []
      };
    },
    removeFundShareClass(index) {
      this.fund.fundShareClasses.splice(index, 1);
    },
    async init() {
      const cr = await getCompanies({ pageSize: 10000 });
      this.companies = [];
      if (cr) {
        cr.result.forEach(item => {
          this.companies.push({
            key: item.id,
            text: item.name,
            value: item.id
          });
        });
      }
      const response = await getFund({ id: this.id });
      this.fund = null;
      if (response) this.fund = response.fund;
      console.log("initial fund", this.fund);
    },
    async saveFund() {
      console.log("saving fund", this.fund);
      const response = await createFund({ fund: this.fund });
      if (response) {
        this.success = true;
        this.isEditScreen = false;
        await this.onUpload();
      }
    }
  },

  computed: {
    percent() {
      var total = 0;
      if (this.fund.investmentStyle) {
        total += 10;
      }
      if (this.fund.fundShareClasses) {
        total += 10;
      }
      if (this.fund.fundAumMinusThreeYears) {
        total += 10;
      }
      if (this.fund.largestFiveClientsInFund) {
        total += 10;
      }
      if (this.fund.averagePortfolioTurnover) {
        total += 10;
      }
      if (this.fund.keyDecisionMakers) {
        total += 10;
      }
      if (this.fund.aaProcess) {
        total += 10;
      }
      if (this.fund.derivativesUsed) {
        total += 10;
      }
      if (this.fund.fundESGCertified) {
        total += 10;
      }
      if (this.fund.documentUploads.length > 0) {
        total += 10;
      }

      return total;
    },
    adminFundCommentsUrl() {
      var url = "";
      console.log(" url 1", url);
      if (this.fund.documentUploads) {
        console.log(" url 2", this.fund.documentUploads);
        for (var i = 0; i < this.fund.documentUploads.length; i++) {
          if (this.fund.documentUploads[i].fileType == "Admin Fund Comments") {
            return this.fund.documentUploads[i].fileName;
          }
        }
      }
      console.log(" url 3", url);
      return url;
    },
    dDCommentsUrl() {
      var url = "";
      console.log(" url 1", url);
      if (this.fund.documentUploads) {
        console.log(" url 2", this.fund.documentUploads);
        for (var i = 0; i < this.fund.documentUploads.length; i++) {
          if (this.fund.documentUploads[i].fileType == "Additional Due Diligence Document") {
            return this.fund.documentUploads[i].fileName;
          }
        }
      }
      console.log(" url 3", url);
      return url;
    },
    pastPerformanceUrl() {
      var url = "";
      console.log(" url 1", url);
      if (this.fund.documentUploads) {
        console.log(" url 2", this.fund.documentUploads);
        for (var i = 0; i < this.fund.documentUploads.length; i++) {
          if (this.fund.documentUploads[i].fileType == "Past Performance") {
            return this.fund.documentUploads[i].fileName;
          }
        }
      }
      console.log(" url 3", url);
      return url;
    },

    investmentPhilosophyUrl() {
      var url = "";
      if (this.fund.documentUploads) {
        for (var i = 0; i < this.fund.documentUploads.length; i++) {
          if (
            this.fund.documentUploads[i].fileType == "Investment Philosophy"
          ) {
            return this.fund.documentUploads[i].fileName;
          }
        }
      }
      return url;
    },
    investmentProcessUrl() {
      var url = "";
      if (this.fund.documentUploads) {
        for (var i = 0; i < this.fund.documentUploads.length; i++) {
          if (this.fund.documentUploads[i].fileType == "Investment Process") {
            return this.fund.documentUploads[i].fileName;
          }
        }
      }
      return url;
    },
    riskManagementFrameworkUrl() {
      var url = "";
      if (this.fund.documentUploads) {
        for (var i = 0; i < this.fund.documentUploads.length; i++) {
          if (
            this.fund.documentUploads[i].fileType == "Risk Management Framework"
          ) {
            return this.fund.documentUploads[i].fileName;
          }
        }
      }
      return url;
    },

    annualStewardshipUrl() {
      var url = "";
      if (this.fund.documentUploads) {
        for (var i = 0; i < this.fund.documentUploads.length; i++) {
          if (this.fund.documentUploads[i].fileType == "Annual Stewardship") {
            return this.fund.documentUploads[i].fileName;
          }
        }
      }
      return url;
    },
      //New Hedge Fund specific URl 
      grossExposureInceptionUrl() {
          var url = "";
          if (this.fund.documentUploads) {
              for (var i = 0; i < this.fund.documentUploads.length; i++) {
                  if (this.fund.documentUploads[i].fileType == "Gross Exposure Since Inception") {
                      return this.fund.documentUploads[i].fileName;
                  }
              }
          }
          return url;
      },
      netExposureInceptionUrl() {
          var url = "";
          if (this.fund.documentUploads) {
              for (var i = 0; i < this.fund.documentUploads.length; i++) {
                  if (this.fund.documentUploads[i].fileType == "Net Exposure Since Inception") {
                      return this.fund.documentUploads[i].fileName;
                  }
              }
          }
          return url;
      },
      betaInceptionUrl() {
          var url = "";
          if (this.fund.documentUploads) {
              for (var i = 0; i < this.fund.documentUploads.length; i++) {
                  if (this.fund.documentUploads[i].fileType == "Beta Since Inception") {
                      return this.fund.documentUploads[i].fileName;
                  }
              }
          }
          return url;
      },
    user() {
      if (this.$store.state.user) return this.$store.state.user;
      else return "";
    },
    ...mapState,
    ...mapGetters(["isAuthenticated"])
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.id == 0) this.isEditScreen = true;
    this.init();
  }
};
</script>
