/** @format */

import Vue from "vue";
import Vuex from "vuex";
import {
  getSessionInfo
  // getSessionInfo
} from "@/gateway";
Vue.use(Vuex);

const actions = {
  async initialLoad(context) {
    if (localStorage.getItem("ss-tok")) {
      //Vue.axios.defaults.headers.common.Authorization = `Bearer ${localStorage.plinktrackerjwt}`;

      const response = await getSessionInfo();

      context.commit("sessionInfo", response);
      context.commit("companyId", response.companyId);
    }
  },

  async loadSettings({ commit }) {
    const session = await getSessionInfo();

    commit("sessionInfo", session);
    commit("companyId", session.companyId);
  }
};

const mutations = {
  sessionInfo(state, user) {
    state.user.id = user.userAuthId; // user.id;
    state.user.companyId = user.companyId;
    state.user.company = user.company;
    state.user.isAuthenticated = user.isAuthenticated;
    state.user.username = user.userName;
    state.user.email = user.email;
    state.user.displayName = user.displayName;
    state.user.roles = user.roles;
    state.user.permissions = user.permissions;
  },
  companyId(state, config) {
    state.companyId = config;
  }
};

export const getters = {
  isAuthenticated: state => state.user.isAuthenticated == true
};
export default new Vuex.Store({
  state: {
    user: {
      id: null,
      email: "",
      displayName: "",
      userName: "",
      isAuthenticated: false,
      companyId: 0,
      company: "",
      roles: [],
      permissions: []
    },
    companyId: 0,

    loading: false
  },
  mutations: mutations,
  actions: actions,
  getters: getters
});
