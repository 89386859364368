<!-- @format -->

<template>
  <div class="home">
    <div class="client-container">
      <div
        class="cbg"
        :style="
          'background: url(https://assets.website-files.com/5c6eb35188c954830ec0365d/5c817444f2a0f7e7f938ae36_yacht-dtp.jpg) center center; background-size: cover;'
        "
      ></div>
      <div class="cbgmenu"></div>
      <div class="ui sixteen column padded grid centered">
        <div class="row">
          <div class="sixteen wide column">
            <div>
              <h2>Users</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui column padded grid centered">
      <div class="row">
        <div class="sixteen wide column">
          <div class="ui raised padded text segment">
            <div class="ui segment">
              <div v-show="updatingUser" class="ui active inverted dimmer">
                <div class="ui text loader">Loading</div>
              </div>
              <div class="ui segment">
                <sui-input
                  fluid
                  placeholder="Search..."
                  type="text"
                  v-model="searchText"
                  v-on:keyup="getResults(1)"
                />
              </div>
              <div v-if="!showNewUserScreen">
                <div v-if="message != ''" class="ui message">{{ message }}</div>
                <div>
                  <div class="ui modal">
                    <i class="close icon"></i>
                    <div class="description">
                      <div class="ui header">
                        Please confirm this user's details.
                      </div>
                      <p>
                        Create user {{ firstName }} {{ lastName }} with Email
                        Address {{ email }}, with access to view
                        {{ companyId }}?
                      </p>
                    </div>
                    <div class="actions">
                      <div class="ui red button">
                        No
                      </div>
                      <div class="ui positive button">
                        Yes
                      </div>
                    </div>
                  </div>
                </div>

                <table class="ui  compact table">
                  <thead v-if="!loading">
                    <tr>
                      <th>EMAIL ADDRESS</th>
                      <th>COMPANY ACCESS</th>
                      <th>ROLES</th>
                      <th>MANAGE</th>
                    </tr>
                  </thead>
                  <tbody v-if="!loading">
                    <tr v-for="c in finalResults" :key="c.id">
                      <td>
                        {{ c.displayName }}
                      </td>
                      <td>
                        {{ c.companyName }}
                      </td>
                      <td>
                        <span v-for="p in c.roles" :key="p">
                          <span class="ui grey label">{{ p }}</span> |
                        </span>
                      </td>
                      <td>
                        <div v-if="c.permissionChanged">Save</div>
                        <div
                          v-if="user.displayName != c.displayName"
                          @click.prevent="deleteUser(c.displayName)"
                          class="ui tiny red basic button"
                        >
                          Delete
                        </div>
                        |
                        <div
                          v-if="
                            user.displayName != c.displayName &&
                              c.companyId != -1
                          "
                          @click.prevent="showUpdate(c)"
                          class="ui tiny green basic button"
                        >
                          Edit
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="loading">
                    <tr>
                      <td>
                        <div
                          class="hollow-dots-spinner"
                          v-if="loading"
                          :style="spinnerStyle"
                        >
                          <div class="dot"></div>
                          <div class="dot"></div>
                          <div class="dot"></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <sui-menu v-if="totalPages > 1" style="float: right" pagination>
                  <a is="sui-menu-item" @click.prevent="getResults(1)" icon>
                    <sui-icon name="left chevron" />
                  </a>
                  <a
                    is="sui-menu-item"
                    v-if="
                      currentPageNr - 4 > 0 && currentPageNr - 4 <= totalPages
                    "
                    @click.prevent="getResults(currentPageNr - 4)"
                    >{{ currentPageNr - 4 }}</a
                  >
                  <a
                    is="sui-menu-item"
                    v-if="
                      currentPageNr - 3 > 0 && currentPageNr - 3 <= totalPages
                    "
                    @click.prevent="getResults(currentPageNr - 3)"
                    >{{ currentPageNr - 3 }}</a
                  >
                  <a
                    is="sui-menu-item"
                    v-if="
                      currentPageNr - 2 > 0 && currentPageNr - 2 <= totalPages
                    "
                    @click.prevent="getResults(currentPageNr - 2)"
                    >{{ currentPageNr - 2 }}</a
                  >
                  <a
                    is="sui-menu-item"
                    v-if="
                      currentPageNr - 1 > 0 && currentPageNr - 1 <= totalPages
                    "
                    @click.prevent="getResults(currentPageNr - 1)"
                    >{{ currentPageNr - 1 }}</a
                  >
                  <div is="sui-menu-item">
                    <strong>{{ currentPageNr }}</strong>
                  </div>

                  <a
                    is="sui-menu-item"
                    v-if="
                      currentPageNr + 1 > 0 && currentPageNr + 1 <= totalPages
                    "
                    @click.prevent="getResults(currentPageNr + 1)"
                    >{{ currentPageNr + 1 }}</a
                  >
                  <a
                    is="sui-menu-item"
                    v-if="
                      currentPageNr + 2 > 0 && currentPageNr + 2 <= totalPages
                    "
                    @click.prevent="getResults(currentPageNr + 2)"
                    >{{ currentPageNr + 2 }}</a
                  >
                  <a
                    is="sui-menu-item"
                    v-if="
                      currentPageNr + 3 > 0 && currentPageNr + 3 <= totalPages
                    "
                    @click.prevent="getResults(currentPageNr + 3)"
                    >{{ currentPageNr + 3 }}</a
                  >
                  <a
                    is="sui-menu-item"
                    v-if="
                      currentPageNr + 4 > 0 && currentPageNr + 4 <= totalPages
                    "
                    @click.prevent="getResults(currentPageNr + 4)"
                    >{{ currentPageNr + 4 }}</a
                  >

                  <a
                    is="sui-menu-item"
                    icon
                    @click.prevent="getResults(totalPages)"
                  >
                    <sui-icon name="right chevron" />
                  </a>
                </sui-menu>

                <div
                  :class="
                    updatingUser
                      ? 'ui basic primary loading large button'
                      : 'ui basic primary large  button'
                  "
                  v-if="!showNewUserScreen"
                  @click="showNewUserScreen = true"
                >
                  Create New User
                </div>

                <div v-if="false">
                  <div
                    :class="
                      updatingUser
                        ? 'ui primary loading large button'
                        : 'ui primary large  button'
                    "
                    v-if="!showNewUserScreen && showSaveButton"
                    @click="savePermissions"
                  >
                    Save
                  </div>
                  <div
                    :class="
                      updatingUser
                        ? 'ui  loading large button'
                        : 'ui  large  button'
                    "
                    v-if="!showNewUserScreen && showSaveButton"
                    @click="showNewUserScreen = true"
                  >
                    Cancel
                  </div>
                </div>
              </div>
              <div class="ui segment" v-if="showUpdateUserScreen">
                <div class="ui attached message">
                  <div class="content">
                    <div class="header">
                      Updating User Access For : &nbsp;<u>{{
                        userToBeUpdated.displayName
                      }}</u>
                    </div>
                  </div>
                  <br />
                  <div class="ui form attached fluid segment">
                    <div v-if="!isInternalUser" class="ui field">
                      <label>Access to Companies</label>
                      <sui-dropdown
                        fluid
                        multiple
                        search
                        selection
                        :options="companies"
                        v-model="userToBeUpdated.companyIds"
                      />
                    </div>
                    <div class="ui button blue basic" @click="updateUser">
                      Save
                    </div>
                    &nbsp;&nbsp;
                    <div
                      class="ui button red basic"
                      @click="showUpdateUserScreen = false"
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui segment" v-show="showNewUserScreen">
                <div>
                  <div class="ui attached message">
                    <div class="content">
                      <div class="header">New User</div>
                    </div>
                  </div>
                  <div class="ui form attached fluid segment">
                    <div class="ui field">
                      <label>First Name</label>
                      <input v-model="firstName" />
                    </div>
                    <div class="ui field">
                      <label>Last Name</label>
                      <input v-model="lastName" />
                    </div>
                    <div class="ui field">
                      <label>E-Mail</label>
                      <input v-model="email" />
                    </div>
                    <div class="ui field">
                      <div class="ui checkbox">
                        <input
                          type="checkbox"
                          name="example"
                          v-model="isInternalUser"
                        />
                        <label>Is Internal user?</label>
                      </div>
                    </div>
                    <div v-if="isInternalUser == false">
                      <sui-modal v-model="open">
                        <sui-modal-content>
                          <sui-modal-description>
                            <sui-header>Confirm User Details</sui-header>
                            <p>
                              Create user
                              <b>{{ firstName }} {{ lastName }}</b> with Email
                              Address <b>{{ email }}</b
                              >, with access to view <b>{{ companyName }}</b> ?
                            </p>
                            <p>
                              Please ensure that the user is allocated to the
                              correct company as ManagerHub contains personal
                              information, and an incorrect allocation could be
                              viewed as a data breach under the POPI Act.
                            </p></sui-modal-description
                          >
                        </sui-modal-content>
                        <sui-modal-actions>
                          <sui-button color="red" @click.native="toggle">
                            NO
                          </sui-button>
                          <sui-button positive @click.prevent="saveUser">
                            YES
                          </sui-button>
                        </sui-modal-actions>
                      </sui-modal>
                    </div>
                    <div v-if="isInternalUser" class="ui field">
                      <div class="ui checkbox">
                        <input
                          type="checkbox"
                          name="example"
                          v-model="isAdmin"
                        />
                        <label>Is Administrator?</label>
                      </div>
                    </div>
                    <div v-if="!isInternalUser" class="ui field">
                      <label>Access to Companies</label>
                      <sui-dropdown
                        fluid
                        multiple
                        search
                        selection
                        :options="companies"
                        v-model="companyIds"
                      />
                    </div>
                  </div>
                  <br />
                  <div v-if="isInternalUser == true">
                    <sui-button
                      basic
                      :loading="updatingUser"
                      :disabled="!canSave"
                      primary
                      @click.prevent="saveUser"
                      >Create</sui-button
                    >
                  </div>
                  <br />
                  <div v-if="isInternalUser == false">
                    <sui-button
                      basic
                      :loading="updatingUser"
                      :disabled="!canSave"
                      primary
                      @click.native="toggle"
                      >Create</sui-button
                    >
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import {
  createUser,
  updateUser,
  getUsers,
  deleteUser,
  assignRole,
  unassignAllPermissions,
  getCompanies
} from "@/gateway";
export default {
  name: "ProposalList",
  props: {
    msg: String
  },
  data() {
    return {
      updatingUser: false,
      success: false,
      companies: [],
      showSaveButton: false,
      showUpdateUserScreen: false,
      userToBeUpdated: null,
      availablePermissions: [
        { key: "createClients", text: "createClients", value: "createClients" },
        { key: "deleteClients", text: "deleteClients", value: "deleteClients" },
        { key: "manageUsers", text: "manageUsers", value: "manageUsers" }
      ],
      // new client
      isInternalUser: false,
      message: "",
      firstName: "",
      lastName: "",
      email: "",
      companyId: -1,
      companyIds: [],
      name: "",
      open: false,
      isAdmin: false,
      update: false,
      showNewUserScreen: false,
      //
      results: [],
      filterStatus: "Saved",
      searchText: "",
      orderBy: "updated_as_at",
      order: "desc",
      loading: false,
      currentPageNr: 1,
      totalRecords: null,
      totalPages: null,
      allRecords: {
        saved: 0,

        archived: 0
      }
    };
  },
  watch: {
    results: {
      handler: function(oldValue, newValue) {
        if (newValue) {
          for (var i = 0; i < newValue.length; i++) {
            this.showSaveButton = true;
          }
        }
      },
      deep: true
    }
  },
  computed: {
    companyName() {
      var companyNames = "";
      for (var i = 0; i < this.companies.length; i++) {
        var com = this.companies[i];
        if (this.companyIds.includes(com.value)) {
          companyNames += com.text + ", ";
        }
      }
      return companyNames;
    },
    user() {
      if (this.$store.state.user) return this.$store.state.user;
      else return "";
    },
    canSave() {
      if (this.firstName != "" && this.lastName != "" && this.email != "") {
        return true;
      } else return false;
    },
    finalResults() {
      var final = [];
      var rr = this.update;
      this.update != rr;
      for (var i = 0; i < this.results.length; i++) {
        var r = this.results[i];
        r.editpermissions = false;
        final.push(r);
      }
      return final;
    }
  },
  methods: {
    showUpdate(userToBeUpdated) {
      this.showUpdateUserScreen = true;
      this.userToBeUpdated = userToBeUpdated;
    },
    editpermission(c) {
      var r = _.filter(this.finalResults, { id: c.id });
      this.update != this.update;
      r.editpermissions = true;
      c.editpermissions = true;
    },
    filter: function(arr, filtr) {
      return _.filter(arr, filtr);
    },
    permissionChanged(event) {
      event.permissionChanged = true;
      // this.showSaveButton = true;
    },
    toggle() {
      this.open = !this.open;
    },
    async savePermissions() {
      for (var i = 0; i <= this.results.length - 1; i++) {
        var u = this.results[i];
        if (u.displayName != this.user.displayName) {
          await unassignAllPermissions({
            userName: u.userName
          });

          for (var p = 0; p <= u.permissions.length - 1; p++) {
            await assignRole({
              permission: u.permissions[p],
              userName: u.userName
            });
          }
        }
      }
    },
    async deleteUser(email) {
      this.message = "";
      this.updatingUser = true;
      const response = await deleteUser({ email: email });
      if (response) {
        this.message = "";
        this.updatingUser = false;
        this.getResults(1);
      }
    },
    async saveUser() {
      this.message = "";
      this.updatingUser = true;
      var response = await createUser({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        companyId: this.isInternalUser == true ? -1 : 0, // this.companyId,
        companyIds: this.companyIds,
        isInternalUser: this.isInternalUser,
        isAdmin: this.isAdmin
      });
      if (response) {
        this.showNewUserScreen = false;
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.companyId = -1;
        this.companyIds = [];
        this.isInternalUser = false;
        this.isAdmin = false;
        this.updatingUser = false;
        this.init();
      }
    },
    async updateUser() {
      this.message = "";
      this.updatingUser = true;
      var response = await updateUser({
        email: this.userToBeUpdated.displayName,
        companyIds: this.userToBeUpdated.companyIds
      });
      if (response) {
        this.updatingUser = false;
        this.showUpdateUserScreen = false;
        this.init();
      }
    },
    async refreshResults(orderBy, ord) {
      this.pageNr = 1;
      this.orderBy = orderBy;
      this.order = ord;
      this.getResults(1);
    },
    async getResults(pageNr, filterStatus) {
      //  console.log("getting results");
      if (filterStatus) this.filterStatus = filterStatus;
      this.loading = true;
      //  await new Promise(r => setTimeout(r, 2000));
      // console.log("getting results 1..");
      this.currentPageNr = pageNr;
      var response = await getUsers({
        searchText: this.searchText,
        pageNr: pageNr
      });
      if (response) {
        this.funds = response.result;
        this.totalRecords = response.totalRecords;
        this.totalPages = response.totalPages;
      }
      console.log(response);
      this.results = response.result;
      this.loading = false;
    },

    async init() {
      // populate companies array
      const cr = await getCompanies({ pageSize: 10000 });
      this.companies = [];
      if (cr) {
        cr.result.forEach(item => {
          this.companies.push({
            key: item.id,
            text: item.name,
            value: item.id
          });
        });
      }
      await this.getResults(1);
    }
  },
  async mounted() {
    this.init();
  }
};
</script>
