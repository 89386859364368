<!-- @format -->

<template>
  <div id="my-container">
    <div
      v-if="!isAuthenticated"
      id="my-login-container"
      class="ui middle aligned center aligned grid"
    >
      <div class="row">
        <div
          class="sixteen wide mobile eight wide tablet five wide computer column"
        >
          <div class="ui">
            <div class="ui black card fluid">
              <div class="ui image">
                <img
                  src="https://acportfoliolink.blob.core.windows.net/assets/8962%20Analytics%20Consulting_Insights%20Portal_Images.jpg"
                />
              </div>
              <div class="content">
                <div class="header">AC ManagerHub</div>
                <small>version 1.0</small>
              </div>
              <div class="extra content">
                <a
                  @click.prevent="submitLogin"
                  :class="
                    'ui secondary large basic fluid submit button ' +
                      (loading ? 'loading' : '')
                  "
                  ><i class="ui icon lock"></i>Login</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isAuthenticated"
      class="ui large top pointing attached left menu no-print"
    >
      <router-link class="item" to="/"
        ><strong>AC MANAGERHUB</strong></router-link
      >
      <router-link class="item" to="/companies"
        ><strong>COMPANIES</strong>
      </router-link>
      <router-link class="item" to="/funds"><strong>FUNDS</strong></router-link>
      <router-link class="item" to="/users" v-if="canViewUsers(user)"
        ><strong>USERS</strong></router-link
      >

      <div class="ui right menu">
        <a href="#" class="ui item red" @click="submitLogout">
          <i class="sign out alternate icon"></i>
          <strong>SIGN OUT</strong>
        </a>
      </div>
    </div>

    <!--div v-if="isAuthenticated" class="ui main container fluid centered">
      <router-view></router-view>
    </div-->
	  <div v-if="isAuthenticated" class="container  w-full">
		  <router-view></router-view>
	  </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { login, logout, BaseUrl } from "@/gateway";
export default {
  data() {
    return {};
  },
  methods: {
    canViewUsers(user) {
      if (user.roles) {
        if (user.roles.includes("Admin")) {
          return true;
        } else return false;
      } else {
        return false;
      }
    },
    async submitLogin() {
      //    const redirectUrl = await login("auth0", this.userName, this.password);
      this.loading = true;
      location.href = BaseUrl + "/auth/auth0";
    },
    async submitLoginAuth() {
      const redirectUrl = await login("auth0", this.userName, this.password);

      location.href = redirectUrl;
    },
    async submitLogout() {
      console.log("logging out ");
      const redirectUrl = await logout();
      console.log("redirec ", redirectUrl);
      location.href = redirectUrl;
    }
  },
  computed: {
    partnerBackgroundUrl() {
      if (this.$store.state.partnerBackgroundUrl)
        return this.$store.state.partnerBackgroundUrl;
      else return "";
    },
    user() {
      if (this.$store.state.user) return this.$store.state.user;
      else return "";
    },
    ...mapState,
    ...mapGetters(["isAuthenticated"])
  },
  async mounted() {
    console.log("isAuthenticated", this.$store.state.isAuthenticated);
    this.$store.dispatch("loadSettings");
  }
};
</script>

<style lang="scss">

@import url("https://fonts.googleapis.com/css?family=Poppins");

@media print
{
	.no-print, .no-print *
	{
		display: none !important;
	}
}

a {
  color: green;
  opacity: 1;

  &.active--exact {
    color: darkgrey;
  }

  &:hover {
    color: lightgray;
    cursor: pointer;
  }
}

.ui {
  font-family: "Poppins", sans-serif;
}

.button {
  font-family: "Poppins", sans-serif;
}

html,
body {
  font-family: "Poppins", sans-serif;
  background: #f0f0f0;
}

.home {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-family: "Poppins", sans-serif;
}

h2 {
  font-family: "Poppins", sans-serif;
}

h3 {
  font-family: "Poppins", sans-serif;
}

h4 {
  font-family: "Poppins", sans-serif;
}
h5 {
  font-family: "Poppins", sans-serif;
}

h6 {
  font-family: "Poppins Condensed", sans-serif;
}

header {
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

section {
  font-family: "Poppins", sans-serif;
  background: rgb(233, 233, 233);
  border-radius: 5px;
  padding: 1em 2em 1em 1em;
}

section .content {
  align-items: center;
}

SectionSelectable {
  text-align: center;
  font-family: "Poppins", sans-serif;
  background: rgb(233, 233, 233);
  border-radius: 5px;
  padding: 1em 2em 1em 1em;
  cursor: pointer;
}

SectionSelectable .active {
  cursor: text;
  font-weight: bold;
}

.ui.footer.form-page {
  /* Increased specificity for SO snippet priority */
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
}
#my-container {
  background: #f0f0f0;
  height: 100vh;
  width: 100%;
  margin: 0em;
}
#my-login-container {
  background: grey;
  height: 100vh;
  width: 100%;
  margin: 0em;
}
#content {
  margin-top: 0em;
}
#content:after {
  margin-top: 0em;
}

.main.container {
  margin-top: 0em;
}
.main.menu {
  margin-top: 0em;
  border-radius: 0;
  border: none;
  box-shadow: none;
  transition: box-shadow 0.5s ease, padding 0.5s ease;
}
.main.menu .item img.logo {
  margin-right: 1.5em;
}
.overlay {
  float: left;
  margin: 0em 3em 1em 0em;
}
.overlay .menu {
  position: relative;
  left: 0;
  transition: left 0.5s ease;
}
.main.menu.fixed {
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}
.overlay.fixed .menu {
  left: 800px;
}
.text.container .left.floated.image {
  margin: 2em 2em 2em -4em;
}
.text.container .right.floated.image {
  margin: 2em -4em 2em 2em;
}

img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.6;
}

@media screen and (max-width: 1024px) {
  img.bg {
    left: 50%;
    margin-left: -512px;
  }
}

.client-container {
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
  padding: 5em 0 0 0;
  margin: 0;
}

.client-container .cbg {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("https://assets.website-files.com/5c6eb35188c954830ec0365d/5c817444f2a0f7e7f938ae36_yacht-dtp.jpg")
    center center;
  background-size: cover;
  opacity: 0.6;
  width: 100%;
  height: 100%;
}

.client-container .cbgmenu {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  background-size: cover;
  opacity: 0.25;
  width: 100%;
  height: 50px;
}

.client-container .cbgheader {
  font-family: "Poppins", sans-serif;
  color: rgba(46, 44, 44, 0.719);
  font-size: 2em;
  opacity: 1;
}

.fingerprint-spinner,
.fingerprint-spinner * {
  box-sizing: border-box;
}

.fingerprint-spinner {
  height: 64px;
  width: 64px;
  padding: 2px;
  overflow: hidden;
  position: relative;
}

.fingerprint-spinner .spinner-ring {
  position: absolute;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #4d4d4d;
  animation: fingerprint-spinner-animation 1500ms
    cubic-bezier(0.68, -0.75, 0.265, 1.75) infinite forwards;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.fingerprint-spinner .spinner-ring:nth-child(1) {
  height: calc(60px / 9 + 0 * 60px / 9);
  width: calc(60px / 9 + 0 * 60px / 9);
  animation-delay: calc(50ms * 1);
}

.fingerprint-spinner .spinner-ring:nth-child(2) {
  height: calc(60px / 9 + 1 * 60px / 9);
  width: calc(60px / 9 + 1 * 60px / 9);
  animation-delay: calc(50ms * 2);
}

.fingerprint-spinner .spinner-ring:nth-child(3) {
  height: calc(60px / 9 + 2 * 60px / 9);
  width: calc(60px / 9 + 2 * 60px / 9);
  animation-delay: calc(50ms * 3);
}

.fingerprint-spinner .spinner-ring:nth-child(4) {
  height: calc(60px / 9 + 3 * 60px / 9);
  width: calc(60px / 9 + 3 * 60px / 9);
  animation-delay: calc(50ms * 4);
}

.fingerprint-spinner .spinner-ring:nth-child(5) {
  height: calc(60px / 9 + 4 * 60px / 9);
  width: calc(60px / 9 + 4 * 60px / 9);
  animation-delay: calc(50ms * 5);
}

.fingerprint-spinner .spinner-ring:nth-child(6) {
  height: calc(60px / 9 + 5 * 60px / 9);
  width: calc(60px / 9 + 5 * 60px / 9);
  animation-delay: calc(50ms * 6);
}

.fingerprint-spinner .spinner-ring:nth-child(7) {
  height: calc(60px / 9 + 6 * 60px / 9);
  width: calc(60px / 9 + 6 * 60px / 9);
  animation-delay: calc(50ms * 7);
}

.fingerprint-spinner .spinner-ring:nth-child(8) {
  height: calc(60px / 9 + 7 * 60px / 9);
  width: calc(60px / 9 + 7 * 60px / 9);
  animation-delay: calc(50ms * 8);
}

.fingerprint-spinner .spinner-ring:nth-child(9) {
  height: calc(60px / 9 + 8 * 60px / 9);
  width: calc(60px / 9 + 8 * 60px / 9);
  animation-delay: calc(50ms * 9);
}

@keyframes fingerprint-spinner-animation {
  100% {
    transform: rotate(360deg);
  }
}

.hollow-dots-spinner,
.hollow-dots-spinner * {
  box-sizing: border-box;
}

.hollow-dots-spinner {
  height: 15px;
  width: calc(30px * 3);
}

.hollow-dots-spinner .dot {
  width: 15px;
  height: 15px;
  margin: 0 calc(15px / 2);
  border: calc(15px / 5) solid #4d4d4d;
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
  animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
  animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
  animation-delay: calc(300ms * 3);
}

@keyframes hollow-dots-spinner-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-enter-active {
  transition: opacity 0.75s;
}
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modelFade-enter-active {
  transition: opacity 0.5s;
}
.modelFade-leave-active {
  transition: opacity 0.8s ease-out;
}
.modelFade-enter,
.modelFade-leave-to {
  opacity: 0;
}
.ui.blue.small.button {
  margin-bottom: 1rem;
}
</style>
